import React from 'react';
import IconBase, { SVGComponent } from './base';

const FullscreenIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 18 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00009 5.49994L5.00009 5.49994L7.00009 5.49994L7.00009 3.49994L7.00009 1.49994L5.00009 1.49994L5.00009 3.49994L2.00009 3.49994L2.00009 5.49994Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0001 5.49999L13.0001 5.49999L11.0001 5.49998L11.0001 3.49998L11.0001 1.49998L13.0001 1.49999L13.0001 3.49999L16.0001 3.49999L16.0001 5.49999Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0001 10.4999H13.0001H11.0001L11.0001 12.4999L11.0001 14.4999H13.0001L13.0001 12.4999H16.0001V10.4999Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10.4999L5 10.4999L7 10.4999L7 12.4999L7 14.4999L5 14.4999L5 12.4999L2 12.4999L2 10.4999Z"
      fill="white"
    />
  </IconBase>
);

export default FullscreenIcon;
