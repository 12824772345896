export function activateAR(url: string, title: string) {
  const IS_IOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const IS_ANDROID = /android/i.test(navigator.userAgent);
  const anchor = document.createElement('a');
  if (IS_IOS) {
    anchor.setAttribute('rel', 'ar');
    // IOS needs a element to have an image
    anchor.appendChild(document.createElement('img'));
    anchor.setAttribute('href', url);
    anchor.click();
    return;
  } else if (IS_ANDROID) {
    const noArViewerSigil = '#no-ar-fallback';
    let fallbackInvoked = false;
    return (() => {
      if (fallbackInvoked) {
        return;
      }
      const location = self.location.toString();
      const locationUrl = new URL(location);
      title = encodeURIComponent(title);
      locationUrl.hash = noArViewerSigil;
      const intent = `intent://arvr.google.com/scene-viewer/1.0?title=${title}&file=${url}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(
        locationUrl.toString()
      )};end;`;
      const undoHashChange = () => {
        if (self.location.hash === noArViewerSigil && !fallbackInvoked) {
          fallbackInvoked = true;
          self.history.back();
        }
      };
      self.addEventListener('hashchange', undoHashChange, { once: true });
      anchor.setAttribute('href', intent);
      anchor.click();
    })();
  } else {
    console.log('AR not supported on current device ');
  }
}

export function getARFormat() {
  const IS_IOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const IS_ANDROID = /android/i.test(navigator.userAgent);
  if (IS_IOS) return 'usdz';
  if (IS_ANDROID) return 'glb';
  return null;
}
