export default function(op: any) {
  const ui: any[] = [
    {
      label: 'Subdivisions',
      keys: [
        'preserveMapBorders',
        'renderSubdivSurf',
        'subdivUVs',
        'edgeLength',
        'maxSubdiv',
      ],
    },
    {
      label: 'Displacement',
      keys: [
        'displacementType',
        'displacement',
        'displacementUV',
        'displacementAmount',
        'displacementShift',
      ],
    },
  ];
  return ui;
}
