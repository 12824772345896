import React from 'react';
import IconBase, { SVGComponent } from './base';

const FullscreenIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 22 22" {...props}>
    <path d="M9 4.5H6H4V6.5V8.5H6L6 6.5H9V4.5Z" />
    <path d="M13 4.5H16H18V6.5V8.5H16L16 6.5H13V4.5Z" />
    <path d="M13 17.5L16 17.5L18 17.5L18 15.5L18 13.5L16 13.5L16 15.5L13 15.5L13 17.5Z" />
    <path d="M9 17.5L6 17.5L4 17.5L4 15.5L4 13.5L6 13.5L6 15.5L9 15.5L9 17.5Z" />
  </IconBase>
);

export default FullscreenIcon;
