import AreaLight from './AreaLight';
import DirectionalLight from './DirectionalLight';
import HemisphereLight from './HemisphereLight';
import PointLight from './PointLight';
import SpotLight from './SpotLight';

export default {
  AreaLight,
  DirectionalLight,
  HemisphereLight,
  PointLight,
  SpotLight,
};
