import React, { Component, ComponentType, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { uvars } from 'utils/styling';
import styles from './styling.less';

const openModals: HTMLDivElement[] = [];

interface ModalPortalProps {
  initialPosition: { x: number; y: number };
  onToggleModal: Function;
  el: HTMLElement;
}

class ModalPortal extends Component<ModalPortalProps> {
  private el = document.createElement('div');
  private wrapperRef: HTMLDivElement | null = null;

  public componentDidMount() {
    const { el } = this.props;
    document.addEventListener('mousedown', this.handleMouseDown);
    el.appendChild(this.el);
    if (!this.wrapperRef) {
      return;
    }
    openModals.push(this.wrapperRef);
  }

  public componentWillUnmount() {
    const { el } = this.props;
    document.removeEventListener('mousedown', this.handleMouseDown);
    el.removeChild(this.el);
    if (!this.wrapperRef) {
      return;
    }
    openModals.splice(openModals.indexOf(this.wrapperRef), 1);
  }

  public render() {
    const { children } = this.props;
    const { x, y } = this.getPosition();

    return ReactDOM.createPortal(
      <div
        style={uvars({ x, y })}
        className={styles.modal}
        ref={this.setWrapperRef}
        onClick={this.onClick}
      >
        {children}
      </div>,
      this.el
    );
  }

  private setWrapperRef = (instance: HTMLDivElement | null) => {
    this.wrapperRef = instance;
  };

  private handleMouseDown = (ev: MouseEvent) => {
    const { onToggleModal } = this.props;
    const { target } = ev;
    for (const wrapperRef of openModals) {
      if (wrapperRef.contains(target as Node)) {
        return;
      }
    }

    onToggleModal(ev, true);
  };

  private getPosition = () => {
    const { initialPosition } = this.props;
    let { x, y } = initialPosition;

    if (this.wrapperRef === null) {
      return { x, y };
    }

    const { bottom } = this.wrapperRef.getBoundingClientRect();

    const overscan = bottom - window.innerHeight;
    if (overscan > 0) {
      y -= overscan;
    }
    return { x, y };
  };

  private onClick = (ev: any) => {
    ev.stopPropagation();
  };
}

export interface TriggerProps {
  onToggleModal: (ev: any, fromModal?: boolean) => void;
  setRef: (instance: HTMLElement | null) => void;
}

interface ChildrenProps {
  onClose: () => void;
}

interface ContextModalProps {
  Trigger: ComponentType<TriggerProps>;
  children: (props: ChildrenProps) => ReactElement;
  withoutModal?: boolean;
  el: HTMLElement;
}

interface ContextModalState {
  open: boolean;
}

class ContextModal extends Component<ContextModalProps, ContextModalState> {
  private trigger: HTMLElement | null = null;

  constructor(props: ContextModalProps) {
    super(props);
    this.state = { open: false };
  }

  public componentDidMount() {
    window.addEventListener('resize', this.onClose);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.onClose);
  }

  public render() {
    const { Trigger } = this.props;
    const { open } = this.state;

    return (
      <>
        <Trigger
          onToggleModal={this.onToggleModal}
          setRef={this.setTriggerRef}
        />
        {open && this.renderContent()}
      </>
    );
  }

  private renderContent = () => {
    const { withoutModal, children, el } = this.props;
    const initialPosition = this.getPosition();
    const content = children({ onClose: this.onClose });

    return withoutModal ? (
      content
    ) : (
      <ModalPortal
        el={el}
        onToggleModal={this.onToggleModal}
        initialPosition={initialPosition}
      >
        {content}
      </ModalPortal>
    );
  };

  private setTriggerRef = (instance: HTMLElement | null) =>
    (this.trigger = instance);

  private onToggleModal = (ev: any, fromModal = false) => {
    ev.stopPropagation();
    const { target } = ev;
    if (fromModal && (!this.trigger || this.trigger.contains(target))) {
      return;
    }
    this.setState(({ open }) => ({ open: !open }));
  };

  private getPosition = () => {
    if (this.trigger === null) {
      return { x: 0, y: 0 };
    }

    const { top, left, width } = this.trigger.getBoundingClientRect();

    const x = left + width;
    const y = top + window.pageYOffset;

    return { x, y };
  };

  private onClose = () => {
    this.setState({ open: false });
  };
}

export default ContextModal;
