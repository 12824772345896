import Labelled from 'components/Labelled';
import React, { Component } from 'react';
import { SharedPropertyProps } from '../';
import NumberRenderer from '../Number';
import { NumberProps } from '../Number';
import styles from './styling.less';

export interface Point3 {
  x: number;
  y: number;
  z: number;
}

type Limit3 = Point3 | number[] | number | null | undefined;

function initLimit(lim: Limit3): Partial<Point3> {
  if (typeof lim === 'number') return { x: lim, y: lim, z: lim };
  if (!lim) return {};
  if (Array.isArray(lim)) return { x: lim[0], y: lim[1], z: lim[2] };
  return lim;
}

export interface Vec3Props extends SharedPropertyProps {
  min?: Limit3;
  max?: Limit3;
  step?: Limit3;
  value: Point3;
  onChange: (value: Point3) => void;
}

class Vec3 extends Component<Vec3Props> {
  public render() {
    const { value, min, max, step = 1, label, inline } = this.props;
    const { x, y, z } = value;
    const { x: minX, y: minY, z: minZ } = initLimit(min);
    const { x: maxX, y: maxY, z: maxZ } = initLimit(max);
    const { x: stepX, y: stepY, z: stepZ } = initLimit(step);

    const axes: NumberProps[] = [
      {
        innerLabel: 'X',
        min: minX,
        max: maxX,
        step: stepX,
        value: x,
        onChange: this.createOnChange('x'),
      },
      {
        innerLabel: 'Y',
        min: minY,
        max: maxY,
        step: stepY,
        value: y,
        onChange: this.createOnChange('y'),
      },
      {
        innerLabel: 'Z',
        min: minZ,
        max: maxZ,
        step: stepZ,
        value: z,
        onChange: this.createOnChange('z'),
      },
    ];

    return (
      <Labelled value={label} inline={inline}>
        <section className={styles.numbers}>
          {axes.map(props => (
            <NumberRenderer key={props.innerLabel} {...props} />
          ))}
        </section>
      </Labelled>
    );
  }

  private createOnChange = (axis: 'x' | 'y' | 'z') => (value: string) =>
    this.onChange({ [axis]: value });

  private onChange = (point: Partial<Point3>) => {
    const { onChange, value } = this.props;
    onChange({ ...value, ...point });
  };
}

export default Vec3;
