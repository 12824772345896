import { AnnotationType, Player } from '@threekit/hub-player';
import { connect } from '@threekit/react-redux';
import { ThreekitStore, ThunkDispatch } from '@threekit/redux-store';
import { sceneGraph } from '@threekit/scene-graph';
import cx from 'classnames';
import ClosedIcon from 'icons/closed';
import InfoIcon from 'icons/info';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styles from './styles.less';

const size = 24;
const half = size / 2;
const pad = 6;
const panel = 200;

const Annotation: FunctionComponent<AnnotationType> = ({
  text,
  visible,
  width,
  height,
  top,
  alpha,
  left,
}) => {
  const panelEl = useRef<HTMLElement | null>(null);
  const buttonEl = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  if (!visible) {
    return null;
  }

  const outOfBounds =
    left - half - pad < 0 ||
    left + half + pad > width ||
    top + half + pad > height ||
    top - half - pad < 0;

  if (outOfBounds) {
    return null;
  }

  const IconRenderer = open ? ClosedIcon : InfoIcon;

  let textLeft = left + size;
  let textTop = top;
  if (textLeft + panel + pad > width) {
    textLeft = left - panel - size;
  }

  if (panelEl.current) {
    const rect = panelEl.current.getBoundingClientRect();
    const diff = height - (top + rect.height + pad);
    if (diff < 0) {
      textTop = top + diff;
    }
  }

  return (
    <>
      <button
        style={{
          top: `${top}px`,
          left: `${left}px`,
          width: `${size}px`,
          height: `${size}px`,
          opacity: alpha,
        }}
        className={styles.annotation}
        onClick={() => setOpen(!open)}
        ref={buttonEl}
      >
        <IconRenderer />
      </button>
      {open && (
        <section
          ref={panelEl}
          style={{
            top: `${textTop}px`,
            left: `${textLeft}px`,
            width: `${panel}px`,
            opacity: alpha,
          }}
          className={cx(styles.overlay)}
        >
          {text}
        </section>
      )}
    </>
  );
};

interface StateProps {
  v: number;
}

interface OwnProps {
  player: Player;
}

interface DispatchProps {}

type AnnotationsProps = StateProps & OwnProps & DispatchProps;

const AnnotationsRenderer: FunctionComponent<AnnotationsProps> = ({
  player,
}) => {
  // #region hooks ------------------------------------------------------------
  const [visible, setVisible] = React.useState(false);
  // #endregion ------------------------------------------------------------

  const annotations = player.getAnnotations();

  useEffect(() => {
    player.on(sceneGraph.PHASE_EVENTS_NAMES.RENDERED, () => setVisible(true));
  }, []);

  if (!visible) {
    return null;
  }

  if (player.onAnnotationChange) {
    player.onAnnotationChange(annotations, player.playerEl);
    return null;
  }

  return (
    <>
      {annotations.map(props => (
        <Annotation key={props.id} {...props} />
      ))}
    </>
  );
};

const mapStateToProps = (
  store: ThreekitStore,
  ownProps: OwnProps
): StateProps => {
  const { player } = ownProps;
  return {
    v: player.sceneV(),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: OwnProps
): DispatchProps => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnotationsRenderer);
