import Labelled from 'components/Labelled';
import React, { Component } from 'react';
import { SharedPropertyProps } from '../';
import NumberRenderer from '../Number';
import { NumberProps } from '../Number';
import styles from './styling.less';

export interface Point2 {
  x: number;
  y: number;
}

type Limit2 = Point2 | number[] | number | null | undefined;

function initLimit(lim: Limit2): Partial<Point2> {
  if (typeof lim === 'number') return { x: lim, y: lim };
  if (!lim) return {};
  if (Array.isArray(lim)) return { x: lim[0], y: lim[1] };
  return lim;
}

export interface Vec2Props extends SharedPropertyProps {
  min?: Limit2;
  max?: Limit2;
  step?: Limit2;
  value: Point2;
  onChange: (value: Point2) => void;
}

class Vec2 extends Component<Vec2Props> {
  public render() {
    const { value, min, max, step = 1, label, inline } = this.props;
    const { x, y } = value;
    const { x: minX, y: minY } = initLimit(min);
    const { x: maxX, y: maxY } = initLimit(max);
    const { x: stepX, y: stepY } = initLimit(step);

    const axes: NumberProps[] = [
      {
        innerLabel: 'X',
        min: minX,
        max: maxX,
        step: stepX,
        value: x,
        onChange: this.createOnChange('x'),
      },
      {
        innerLabel: 'Y',
        min: minY,
        max: maxY,
        step: stepY,
        value: y,
        onChange: this.createOnChange('y'),
      },
    ];

    return (
      <Labelled value={label} inline={inline}>
        <section className={styles.numbers}>
          {axes.map(props => (
            <NumberRenderer key={props.innerLabel} {...props} />
          ))}
        </section>
      </Labelled>
    );
  }

  private createOnChange = (axis: 'x' | 'y') => (value: string) =>
    this.onChange({ [axis]: value });

  private onChange = (point: Partial<Point2>) => {
    const { onChange, value } = this.props;
    onChange({ ...value, ...point });
  };
}

export default Vec2;
