export default function(op: any) {
  const ui: any[] = [];

  {
    const keys = ['generateGIActive'];
    if (op.generateGIActive) keys.push('generateGI');
    keys.push('receiveGIActive');
    if (op.receiveGIActive) keys.push('receiveGI');
    ui.push({
      label: 'Surface',
      keys,
    });
  }

  ui.push({
    label: 'Visibility',
    keys: [
      'visibleToGI',
      'primaryVisibility',
      'visibleReflections',
      'visibleRefractions',
      'castShadow',
      'receiveShadow',
    ],
  });

  {
    const keys = [
      'alphaContribution',
      'matteSurface',
      'genRenderElems',
      'shadowOn',
    ];
    if (op.shadowOn) {
      keys.push('affectAlpha', 'shadowTintColor', 'shadowBrightness');
    }
    keys.push('reflectionAmount', 'refractionAmount', 'giAmount');
    ui.push({
      label: 'Matte',
      keys,
    });
  }

  return ui;
}
