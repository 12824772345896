exports = module.exports = require("../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .select___1J17e {\n  flex: 1;\n  width: 100%;\n  color: rgba(0, 0, 0, 0.65);\n}\n.threekit .disabled___3yNdw > div {\n  background-color: transparent !important;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/Select/styles.less"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,YAAY;EACZ,2BAA2B;CAC5B;AACD;EACE,yCAAyC;CAC1C","file":"styles.less","sourcesContent":[":global(.threekit) .select {\n  flex: 1;\n  width: 100%;\n  color: rgba(0, 0, 0, 0.65);\n}\n:global(.threekit) .disabled > div {\n  background-color: transparent !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"select": "select___1J17e",
	"disabled": "disabled___3yNdw"
};