export default function(op) {
  return [
    'radius',
    'tube',
    'radialSegments',
    'tubularSegments',
    'phiStart',
    'phiLength',
    'thetaStart',
    'thetaLength',
  ];
}
