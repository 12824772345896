import { defer, DeferredPromise } from '@threekit/cas';
import { Icon, Progress } from 'antd';
import FileDropzone, {
  FileRules,
  OverlayComponent,
  UploadError,
} from 'components/FileDropzone';
import Labelled from 'components/Labelled';
import InfoIcon from 'icons/info';
import React, { Component, FunctionComponent } from 'react';
import { SharedPropertyProps } from '../';
import styles from './styles.less';

const OverlayRenderer: OverlayComponent = () => (
  <div className={styles.overlay} />
);

const Failed: FunctionComponent = ({ children }) => (
  <span>
    <b className={styles.filename}>{children}</b> failed to upload
  </span>
);

interface ErrorTypeRendererProps {
  file: File;
  rules: FileRules;
}

const size: FunctionComponent<ErrorTypeRendererProps> = ({
  file: { name },
  rules: { size = 0 },
}) => (
  <>
    <Failed>{name}</Failed>
    <span>Your file exceeded the {size}mb size limit</span>
  </>
);

const count: FunctionComponent<ErrorTypeRendererProps> = () => (
  <span>Please upload a single file</span>
);

const type: FunctionComponent<ErrorTypeRendererProps> = ({
  file: { name },
}) => (
  <>
    <Failed>{name}</Failed>
    <span>File type not supported</span>
  </>
);

const errorTypeRenderers: {
  [x in UploadError]: FunctionComponent<ErrorTypeRendererProps>;
} = {
  count,
  size,
  type,
};

interface FileError {
  type: UploadError;
  file: File;
}

interface ErrorRendererProps extends FileError {
  rules: FileRules;
}

const ErrorRenderer: FunctionComponent<ErrorRendererProps> = ({
  type,
  file,
  rules,
}) => {
  const ContentRenderer = errorTypeRenderers[type];

  return (
    <div className={styles.error}>
      <InfoIcon className={styles.info} />
      <ContentRenderer file={file} rules={rules} />
    </div>
  );
};

interface FakeProgressBarProps {}

interface FakeProgressBarState {
  progress: number;
}

const step = 0.05;

class FakeProgressBar extends Component<
  FakeProgressBarProps,
  FakeProgressBarState
> {
  private ticking: NodeJS.Timeout | null = null;

  private currentProgress = 0;

  constructor(props: FakeProgressBarProps) {
    super(props);

    this.state = {
      progress: 0,
    };
  }

  public componentDidMount() {
    this.ticking = setInterval(this.tick, 200);
  }

  public componentWillUnmount() {
    if (!this.ticking) {
      return;
    }

    clearInterval(this.ticking);
  }

  public render() {
    const { progress } = this.state;

    return (
      <Progress
        className={styles.progress}
        showInfo={false}
        strokeColor="#77C4A3"
        percent={progress}
      />
    );
  }

  private tick = () => {
    this.currentProgress = this.currentProgress + step;

    const progress =
      Math.round(
        (Math.atan(this.currentProgress) / (Math.PI / 2)) * 100 * 1000
      ) / 1000;

    if (progress >= 100 && this.ticking) {
      clearInterval(this.ticking);
      return;
    }

    this.setState({ progress });
  };
}

interface ClearButtonProps {
  onClick: () => void;
}

const ClearButton: FunctionComponent<ClearButtonProps> = ({
  children,
  onClick,
}) => (
  <button
    className={styles.remove}
    onClick={ev => {
      ev.stopPropagation();
      ev.preventDefault();
      onClick();
    }}
  >
    <Icon type="close" />
    <span> {children}</span>
  </button>
);

interface LoadingProps {
  fileName?: string;
  onCancel?: () => void;
}

const LoadingRenderer: FunctionComponent<LoadingProps> = ({
  fileName,
  onCancel,
}) => {
  return (
    <>
      <FakeProgressBar />
      {fileName && <span className={styles.filename}>{fileName}</span>}
      {onCancel && <ClearButton onClick={onCancel}>Cancel</ClearButton>}
    </>
  );
};

interface LoadedProps {
  file: FileValue;
  onRemove: () => void;
}

const LoadedRenderer: FunctionComponent<LoadedProps> = ({
  file: { src, name },
  onRemove,
}) => (
  <>
    <div className={styles.image}>
      <img crossOrigin="anonymous" src={src} />
    </div>
    <section className={styles.loaded}>
      <span className={styles.filename}>{name}</span>
      <ClearButton onClick={onRemove}>Remove</ClearButton>
    </section>
  </>
);

export type FileResolver = (value: string) => Promise<FileValue | null>;

export interface FileProps extends SharedPropertyProps {
  acceptedTypes?: string[];
  uploadFiles: (files: File[]) => Promise<any>;
  onChange: (value: string | null) => Promise<any>;
  file: FileValue | null;
}

interface FileState {
  error: FileError | null;
  uploading: string;
}

export interface FileValue {
  src: string;
  name: string;
}

class FileRenderer extends Component<FileProps, FileState> {
  private uploadInProgress: DeferredPromise = defer();

  constructor(props: FileProps) {
    super(props);
    this.state = {
      error: null,
      uploading: '',
    };
  }

  public render() {
    const { label, acceptedTypes, inline } = this.props;
    const { error } = this.state;

    const rules = {
      types: acceptedTypes,
      count: 1,
      size: 52,
    };

    return (
      <Labelled value={label} inline={inline}>
        <div className={styles.root}>
          {error && <ErrorRenderer {...error} rules={rules} />}
          <div className={styles.drop}>
            <FileDropzone
              onError={this.onUploadError}
              processFiles={this.processFiles}
              OverlayRenderer={OverlayRenderer}
              onOpenFileSelector={this.reset}
              rules={rules}
            >
              {({ eventHandlers }) => (
                <div {...eventHandlers} className={styles.holder}>
                  {this.renderContent()}
                </div>
              )}
            </FileDropzone>
          </div>
        </div>
      </Labelled>
    );
  }

  private renderContent = () => {
    const { children, file } = this.props;
    const { uploading } = this.state;

    if (file) {
      return <LoadedRenderer file={file} onRemove={this.reset} />;
    }

    if (uploading) {
      return <LoadingRenderer fileName={uploading} onCancel={this.reset} />;
    }

    return children;
  };

  private reset = (uploading = '') => {
    const { onChange } = this.props;
    this.uploadInProgress.resolve!();
    this.setState({ error: null, uploading }, () => onChange(null));
  };

  private onUploadError = (type: UploadError, file: File) =>
    this.setState({ error: { type, file } });

  private processFiles = async (files: File[]) => {
    const { uploadFiles, onChange } = this.props;

    this.reset(files[0].name);
    this.uploadInProgress = defer();

    const result = await Promise.race([
      this.uploadInProgress,
      uploadFiles(files),
    ]);

    if (!result) {
      return;
    }

    onChange(result);
  };
}

export default FileRenderer;
