import React from 'react';
import IconBase, { SVGComponent } from './base';

const ClosedIcon: SVGComponent = props => {
  return (
    <IconBase viewBox="0 0 32 32" {...props}>
      <circle cx="16" cy="16" r="12" fill="currentColor" fillOpacity="0.85" />
      <circle cx="16" cy="16" r="11.5" stroke="white" />
      <rect
        x="9.33334"
        y="14.6667"
        width="13.3333"
        height="2.66667"
        fill="white"
        fillOpacity="0.85"
      />
    </IconBase>
  );
};

export default ClosedIcon;
