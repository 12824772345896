export function getColorPropertyArray(op) {
  return ['color', 'gndColor', 'intensity'];
}

//

export function getShadowPropertyObject(op, frustum) {
  return {
    label: 'Shadow',
    keys: [
      'shadow',
      op.shadow && [
        'shadowBias',
        'shadowSize',
        // 'autoShadow',
        // !op.autoShadow &&
        ['shadowCameraSize', 'shadowNearClip', 'shadowFarClip'],
        frustum && getFrustumPropertyArray(op),
      ],
      // 'shadowSide',
    ],
  };
}

//

function getFrustumPropertyArray(op) {
  return ['showShadowCamera', op.showShadowCamera && ['shadowFrustumColor']];
}
