import { Canvas } from './Canvas';
import { CanvasComposite } from './CanvasComposite';
import { CanvasFill } from './CanvasFill';
import { CanvasText } from './CanvasText';
import { Image } from './Image';

export default {
  Canvas,
  CanvasComposite,
  CanvasFill,
  CanvasText,
  Image,
};
