import { connect, Omit } from '@threekit/react-redux';
import Select, { SelectProps } from 'components/Select';
import produce from 'immer';
import React, { FunctionComponent, useReducer } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import styles from './styles.less';

export interface QuerySelectOwnProps
  extends Omit<SelectProps, 'onChange' | 'options'> {
  fetchOptions: (query?: any) => any;
  dirt?: any;
  onChange: (value: string | string[], data?: any) => void;
  options?: Options;
  id?: string;
}

interface DispatchProps {
  performFetch: (query?: any) => Promise<Options>;
}

interface StateProps {}

type QuerySelectProps = QuerySelectOwnProps & StateProps & DispatchProps;

export interface Options {
  [x: string]: {
    label: string;
    data?: any;
    group?: string;
    className?: string;
    children?: string[];
  };
}

interface State {
  searchTimeout: null | number;
  searchCount: number;
  options: Options;
}

const initialState: State = {
  searchCount: 0,
  searchTimeout: null,
  options: {},
};

interface Action {
  type: 'UP' | 'DOWN' | 'SET_SEARCH_TIMEOUT' | 'SET_OPTIONS';
  payload?: any;
}

const reducer = (state = initialState, { type, payload }: Action) =>
  produce(state, draft => {
    switch (type) {
      case 'UP': {
        draft.searchCount++;
        break;
      }
      case 'DOWN': {
        draft.searchCount--;
        break;
      }
      case 'SET_SEARCH_TIMEOUT': {
        draft.searchTimeout = payload;
        break;
      }

      case 'SET_OPTIONS': {
        draft.options = payload;
        break;
      }
    }
  });

const QuerySelect: FunctionComponent<QuerySelectProps> = ({
  performFetch,
  value,
  dirt = [],
  onChange,
  id,
  ...props
}) => {
  const [{ options, searchCount, searchTimeout }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const doFetch = async (query: any) => {
    const options = await performFetch(query);
    dispatch({ type: 'SET_OPTIONS', payload: options });
  };

  const selectOnChange = (value: string) => {
    const option = options[value];

    if (!option) {
      onChange(value);
      return;
    }

    const { data } = options[value];
    onChange(value, data);
  };

  useDeepCompareEffect(() => {
    doFetch({ perPage: 30 });
    return () => {};
  }, [dirt]);

  const onSearch = (value: string) => {
    if (searchTimeout !== null) {
      clearTimeout(searchTimeout);
    }

    const timeout = setTimeout(async () => {
      dispatch({ type: 'UP' });

      const query: any = { perPage: 30 };
      if (!!value) {
        query.nameLike = value;
      }

      await doFetch(query);
      dispatch({ type: 'DOWN' });
    }, 500);

    dispatch({ type: 'SET_SEARCH_TIMEOUT', payload: timeout });
  };

  const selectOptions = Object.entries(props.options || options)
    .filter(([id, { children }]) => {
      if (!!children && children.length === 0) {
        return false;
      }
      return true;
    })
    .map(([id, { group, className, label, children }]) => ({
      value: id,
      label: !!children ? `${label} (${children.length})` : label,
      group,
      className,
    }));

  return (
    <div className={styles.root}>
      <Select
        {...props}
        values={[]}
        onChange={selectOnChange}
        options={selectOptions}
        onSearch={onSearch}
        value={value}
        id={id}
      />
      {searchCount > 0 && <div className={styles.loader} />}
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: any,
  { fetchOptions }: QuerySelectOwnProps
): DispatchProps => ({
  performFetch: query => dispatch(fetchOptions(query)),
});

export default connect(
  null,
  mapDispatchToProps
)(QuerySelect);
