import { InputNumber, Slider } from 'antd';
import Labelled from 'components/Labelled';
import React, { Component } from 'react';
import { SharedPropertyProps } from '../';
import styles from './styles.less';

export interface NumberProps extends SharedPropertyProps {
  min?: number;
  max?: number;
  step?: number;
  value: number;
  innerLabel?: string;
  showSlider?: boolean;
  onChange: (value: string) => void;
}

class NumberProperty extends Component<NumberProps> {
  public render() {
    const {
      label,
      min,
      max,
      step,
      value,
      innerLabel,
      showSlider,
      inline,
    } = this.props;

    let sliderMarkup = null as any;
    if (showSlider) {
      sliderMarkup = (
        <Slider
          className={styles.slider}
          value={Number(value)}
          min={min}
          max={max}
          onChange={this.onSliderChange}
          step={step}
        />
      );
    }

    let innerLabelMarkup = null as any;
    if (innerLabel) {
      innerLabelMarkup = <div className={styles.inputLabel}>{innerLabel}</div>;
    }

    return (
      <Labelled value={label} inline={inline}>
        <div className={styles.numberWrapper}>
          <InputNumber
            step={step}
            value={(value && Number(value)) || 0}
            className={styles.number}
            min={min}
            max={max}
            onChange={this.onChange}
          />
          {innerLabelMarkup}
        </div>
        {sliderMarkup}
      </Labelled>
    );
  }

  private onChange = (value: number) => {
    const { onChange } = this.props;
    onChange(String(value));
  };

  private onSliderChange = (value: number | [number, number]) => {
    const { onChange } = this.props;
    if (typeof value !== 'number') {
      return;
    }
    onChange(String(value));
  };
}

export default NumberProperty;
