import React, { FunctionComponent, SVGProps } from 'react';

export interface MySVGProps extends SVGProps<SVGSVGElement> {
  innerRef?: (node: SVGSVGElement | null) => void;
}

export type SVGComponent = FunctionComponent<MySVGProps>;

const IconBase: SVGComponent = ({ innerRef, ...props }) => (
  <svg
    fill="currentColor"
    preserveAspectRatio="xMidYMid meet"
    ref={innerRef}
    {...(props as any)}
  />
);

export default IconBase;
