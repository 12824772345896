import { getUvTransform } from 'operators/Image/uvTransform';

export function TilingOverride(op: any) {
  const mapRegExp = /.*Map/;

  const maps = Object.keys(op).filter(key => mapRegExp.test(key));

  return [
    getUvTransform(op),
    {
      label: 'Maps',
      keys: maps,
    },
  ];
}
