import { Configurator } from '@threekit/cas';
import { Player } from '@threekit/hub-player';
import { connect } from '@threekit/react-redux';
import { Drawer } from 'antd';
import cx from 'classnames';
import produce from 'immer';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Attributes from '../Attributes';
import styles from './styling.less';

export interface ConfiguratorInspectorProps {
  inline?: boolean;
  className?: string;
  showAssetConfiguration?: boolean;
  editing?: boolean;
  player: Player;
}

interface NestedConfiguratorProps {
  visible: boolean;
  nested: Configurator;
}

const ConfiguratorInspector: FunctionComponent<ConfiguratorInspectorProps> = ({
  inline,
  className,
  editing,
  showAssetConfiguration,
  player,
}) => {
  const configurator = player.getConfigurator();
  useEffect(() => {
    if (player && configurator && editing) {
      player.when('loaded').then(() => {
        configurator.whenConfigured().then(() => {
          configurator.enableRules();
        });
      });
    }
    return () => {
      if (configurator && editing) {
        configurator.disableRules();
      }
    };
  }, [configurator, player]);

  const [nestedConfigurators, setNestedConfigurators] = useState<
    NestedConfiguratorProps[]
  >([]);

  if (!configurator) {
    return null;
  }

  const { assetId } = configurator;
  const configuration = configurator.getConfiguration();
  const attributes = configurator.getAttributes();

  const removeNested = (index: number) => {
    setNestedConfigurators(
      produce<NestedConfiguratorProps[]>(nestedConfigurators, draft => {
        draft[index].visible = false;
      })
    );
  };
  const addNested = (nested: Configurator) => {
    setNestedConfigurators([...nestedConfigurators, { visible: true, nested }]);
  };

  if (attributes.length === 0) {
    return null;
  }

  return (
    <div className={cx(className, styles.root)}>
      <Attributes
        assetId={assetId}
        configuration={configuration}
        configurator={configurator}
        setValue={(id, configuration) =>
          configurator.setConfiguration(configuration)
        }
        addNested={addNested}
        inline={inline}
        showAssetConfiguration={showAssetConfiguration}
      />

      {nestedConfigurators.map(({ nested, visible }, index) => {
        const config = nested;
        return (
          <Drawer
            key={index}
            title={nested.name}
            placement="right"
            closable={true}
            width={'90%'}
            visible={visible}
            getContainer={false}
            onClose={() => removeNested(index)}
            style={{ position: 'absolute' }}
            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }}
          >
            <Attributes
              key={config.assetId}
              assetId={config.assetId}
              configuration={config.getConfiguration()}
              configurator={config}
              setValue={(id, configuration) =>
                config.setConfiguration(configuration)
              }
              addNested={addNested}
              inline={false}
            />
          </Drawer>
        );
      })}
    </div>
  );
};

export default connect(() => ({ forceRerender: [] }))(ConfiguratorInspector);
