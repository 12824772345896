import Mesh from './Mesh';
import Torus from './Torus';
import Plane from './Plane';
import Cylinder from './Cylinder';
import Cone from './Cone';
import Capsule from './Capsule';
import { FromShapeWithExtrude } from './FromShapeWithExtrude';
import { MergeVertices } from './MergeVertices';
import Normals from './Normals';
import { UVMap } from './UVMap';
import { UVTransform } from './UVTransform';

export default {
  Mesh,
  Torus,
  Plane,
  Cylinder,
  Cone,
  Capsule,
  FromShapeWithExtrude,
  MergeVertices,
  Normals,
  UVMap,
  UVTransform,
};
