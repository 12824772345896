import { Player } from '@threekit/hub-player';
import { connect } from '@threekit/react-redux';
import { ThreekitStore, ThunkDispatch } from '@threekit/redux-store';
import icon2D from 'assets/2D.svg';
import icon3D from 'assets/3D.svg';
import threekitLogo from 'assets/threekit_icon.svg';
import wordmark from 'assets/threekit_wordmark.svg';

import cx from 'classnames';
import {
  CloseIcon,
  FullscreenIcon,
  HelpIcon,
  MouseLeftIcon,
  MouseMiddleIcon,
  MouseRightIcon,
  ShareIcon,
  TouchDragIcon,
  TouchPanIcon,
  TouchZoomIcon,
} from 'icons';
import ExitFullscreenIcon from 'icons/exit_fullscreen';
import PlaceholderIcon from 'icons/placeholder';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { exportAsset, ExportType } from 'sections/assets/assets';
import {
  CreateConfiguration,
  createConfiguration,
} from 'sections/configurations/configurations';
import { getActiveOrg, OrgPlayerSettings } from 'sections/orgs/orgs';
import ARButton, {
  ExportAssetFunction,
} from 'sections/player/containers/ARButton';
import { ExportFormat, exportProductAsset } from 'sections/products/products';
import shortid from 'shortid';
import useWindowSize from 'utils/useWindowSize';
import styles from './styling.less';

const helpItems: any = {
  Mouse: [
    {
      icon: <MouseLeftIcon />,
      text: (
        <>
          Left-click and drag to <strong>rotate</strong> the model
        </>
      ),
    },
    {
      icon: <MouseMiddleIcon />,
      text: (
        <>
          Scroll the mouse wheel to <strong>zoom</strong> in and out
        </>
      ),
    },
    {
      icon: <MouseRightIcon />,
      text: (
        <>
          Right-click and drag to <strong>pan</strong>
        </>
      ),
    },
  ],
  Touch: [
    {
      icon: <TouchDragIcon />,
      text: (
        <>
          Drag to <strong>rotate</strong> the model
        </>
      ),
    },
    {
      icon: <TouchZoomIcon />,
      text: (
        <>
          Pinch to <strong>zoom</strong> in and out
        </>
      ),
    },
    {
      icon: <TouchPanIcon />,
      text: (
        <>
          Drag with two fingers to <strong>pan</strong>
        </>
      ),
    },
  ],
};

interface OwnProps {
  player: Player;
  showShare?: boolean;
  showAR?: boolean;
  productId?: string;
  show3DPlaceholder?: boolean;
  isLoading?: boolean;
  useCatalog?: boolean;
  switchDisplayMode?: Function;
  display?: 'image' | 'webgl';
  orgId?: string;
  settings: OrgPlayerSettings;
}

interface StateProps {
  orgId?: string;
  v: number;
}

interface DispatchProps {
  createConfiguration: Function; // TODO Update type def
  exportAsset: ExportAssetFunction;
}

export type PlayerLayerProps = OwnProps & StateProps & DispatchProps;

const PlayerLayer: FunctionComponent<PlayerLayerProps> = ({
  player,
  showShare = false,
  showAR = false,
  productId,
  show3DPlaceholder = false,
  isLoading = false,
  orgId,
  settings,
  createConfiguration,
  exportAsset,
  switchDisplayMode,
  display,
}) => {
  const [isShareVisible, setIsShareVisible] = useState(false);
  const shareInputRef = useRef<HTMLInputElement>(null);
  const [copyLink, setCopyLink] = useState('copy link');
  const [shareLink, setShareLink] = useState(window.location.href);
  const [isHelpVisible, setIsHelpVisible] = useState(false);
  const [showFullLogo, setShowFullLogo] = useState(true);
  const [helpMode, setHelpMode] = useState('Mouse');

  // Show full logo initially, then hide. Better here as CSS animations for this get messy
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFullLogo(false);
    }, 2100);
    document.addEventListener('mousedown', handleShareClick, false);
    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousedown', handleShareClick, false);
    };
  }, []);

  const windowSize = useWindowSize();
  if (windowSize.width && windowSize.width < 623 && helpMode !== 'Touch')
    setHelpMode('Touch');

  const copyShareLink = (e: any) => {
    if (shareInputRef && shareInputRef.current) {
      shareInputRef.current.select();
      document.execCommand('copy');
      e.target.focus();
      setCopyLink('copied!');
    }
  };

  const { stageId } = player;
  const shareEl = useRef<HTMLDivElement>(null);

  const handleShareClick = useCallback(
    (e: any) => {
      if (shareEl.current && !shareEl.current.contains(e.target)) {
        setIsShareVisible(false);
      }
    },
    [shareEl]
  );

  const configurator = player.getConfigurator();
  const FullscreenIconRenderer = player.isFullscreen
    ? ExitFullscreenIcon
    : FullscreenIcon;
  return (
    <>
      {stageId && !isLoading && (
        <>
          {!!settings.logo && (
            <a
              target="_blank"
              href="https://www.threekit.com/"
              className={cx(styles.logo, { [styles.full]: showFullLogo })}
            >
              <span className={styles.powered}>Powered&nbsp;by</span>
              <img className={styles.icon} src={threekitLogo} />
              <img className={styles.wordmark} src={wordmark} />
            </a>
          )}
          {switchDisplayMode && (
            <a
              className={styles.switch2d}
              onClick={() =>
                switchDisplayMode(display === 'image' ? 'webgl' : 'image')
              }
            >
              <img src={display === 'image' ? icon3D : icon2D} />
            </a>
          )}
          <ARButton
            showAR={showAR && !!settings.arButton}
            orgId={orgId}
            exportAsset={exportAsset}
            player={player}
            configurator={configurator}
            productId={productId}
            assetId={player.assetId}
          />
          <div className={styles.buttons}>
            {display === 'webgl' && (
              <div
                className={styles.button}
                onClick={() => setIsHelpVisible(true)}
              >
                <HelpIcon />
              </div>
            )}
            {!!settings.shareButton && productId && showShare && (
              <div
                className={cx(styles.button, styles.shareIcon)}
                onClick={() => {
                  const url: URL = new URL(window.location.href);

                  // generate client side shortId
                  const shortId = shortid.generate();
                  url.searchParams.set('tkcsid', shortId);

                  const showShare = async () => {
                    if (configurator) {
                      try {
                        await createConfiguration({
                          productId,
                          productVersion: 'v1',
                          shortId,
                          variant: JSON.stringify(
                            configurator.getFullConfiguration()
                          ),
                          ...(orgId && { orgId }),
                        } as any);
                      } catch (error) {}

                      setShareLink(url.toString());
                      setCopyLink('copy link');
                      setIsShareVisible(true);
                    }
                  };

                  if (window.navigator.share) {
                    window.navigator
                      .share({
                        url: url.toString(),
                      })
                      .catch(err => {
                        showShare();
                      });
                  } else {
                    showShare();
                  }
                }}
              >
                <ShareIcon />
                {isShareVisible && <div className={styles.arrowDown} />}
              </div>
            )}
            {!!settings.fullScreenButton && player.isFullscreenSupported() && (
              <div
                className={styles.button}
                onClick={() => player.toggleFullscreen()}
              >
                <FullscreenIconRenderer />
              </div>
            )}
          </div>
          {isShareVisible && (
            <div ref={shareEl} className={styles.share}>
              <span>Share link</span>
              <div className={styles.shareLink}>
                <input
                  readOnly={true}
                  ref={shareInputRef}
                  value={shareLink}
                  className={styles.shareInput}
                  onClick={copyShareLink}
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck={false}
                />
                <div onClick={copyShareLink} className={styles.shareCopy}>
                  {copyLink}
                </div>
              </div>
              <CloseIcon
                onClick={() => setIsShareVisible(false)}
                className={styles.close}
              />
            </div>
          )}
          {isHelpVisible && (
            <div className={styles.help}>
              <div className={styles.helpNav}>
                {['Mouse', 'Touch'].map((mode: string) => (
                  <div
                    className={cx(styles.helpButton, {
                      [styles.active]: helpMode === mode,
                    })}
                    onClick={() => setHelpMode(mode)}
                    key={mode + Math.random()}
                  >
                    <span>{mode}</span>
                  </div>
                ))}
              </div>
              <div className={styles.helpContent}>
                {helpItems[helpMode].map((item: any) => (
                  <div
                    className={styles.helpItem}
                    key={item.text + Math.random()}
                  >
                    <div className={styles.helpIcon}>{item.icon}</div>
                    <span>{item.text}</span>
                  </div>
                ))}
              </div>
              <CloseIcon
                onClick={() => setIsHelpVisible(false)}
                className={styles.close}
              />
            </div>
          )}
        </>
      )}

      {!!show3DPlaceholder && (
        <div className={styles.placeholder}>
          <PlaceholderIcon />
          <span>No 3D asset assigned.</span>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (
  store: ThreekitStore,
  ownProps: OwnProps
): StateProps => {
  const { orgId } = getActiveOrg(store);
  return {
    orgId: ownProps.orgId || orgId,
    v: ownProps.player.v,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { useCatalog }: OwnProps
): DispatchProps => ({
  createConfiguration: async (props: CreateConfiguration) =>
    dispatch(createConfiguration(props)),
  exportAsset: async (
    id: string,
    type: string,
    format: ExportFormat,
    orgId: string,
    options: {
      cache?: string;
      wait?: string;
      configuration?: any;
      sync?: boolean;
    }
  ) => {
    const res = await dispatch(
      exportAsset(id, format as ExportType, orgId, {
        ...options,
        useCatalog,
      })
    );
    return res;
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerLayer);
