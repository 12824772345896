export function FromShapeWithExtrude(op: any) {
  return [
    'shape',
    'addBackFaces',
    'segments',
    'extrudeLength',
    'useBevel',
    op.useBevel && ['offset', 'style', 'cornerRadius'],
  ];
}
