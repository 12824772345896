import React from 'react';
import IconBase, { SVGComponent } from './base';

const MouseMiddleIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 30 48" fill="none" {...props}>
    <path
      d="M17.2463 11.8289V6.16778C17.2463 4.93919 16.2444 3.93945 15.0133 3.93945C13.7822 3.93945 12.7803 4.93919 12.7803 6.16778V11.8289C12.7803 13.0575 13.7822 14.0573 15.0133 14.0573C16.2444 14.0573 17.2463 13.0575 17.2463 11.8289Z"
      fill="#49D093"
    />
    <path
      d="M15.6168 2.79507C17.2221 3.08415 18.4533 4.48137 18.4533 6.17972V11.8409C18.4533 13.5272 17.2221 14.9364 15.6168 15.2255V19.9954C19.8896 19.9713 23.8486 19.6942 26.8299 19.2124C27.6748 19.0799 28.3749 18.9354 28.9543 18.7909C28.8818 17.2491 28.8215 15.7194 28.7611 14.1776C28.6042 7.44444 23.052 0.530604 16.0151 0.446289C15.8703 0.446289 15.7375 0.446289 15.6168 0.446289V2.79507V2.79507Z"
      fill="#707070"
    />
    <path
      d="M29.0146 20.0078C28.4232 20.1524 27.7593 20.2728 27.0231 20.3933C23.8486 20.9112 19.5879 21.1882 15.0133 21.1882C10.4388 21.1882 6.17803 20.9112 3.00359 20.3933C2.36388 20.2848 1.77245 20.1764 1.24136 20.056C1.03617 24.6813 0.81891 29.3066 0.637859 33.9439C0.432668 41.3516 6.58841 47.7716 14.096 47.6632C14.9288 47.6632 15.3392 47.6632 16.16 47.6632C23.5107 47.5909 30.0164 41.4841 29.6181 33.9439C29.4371 29.2945 29.2198 24.6451 29.0146 20.0078Z"
      fill="#707070"
    />
    <path
      d="M3.20877 19.2006C6.17801 19.6824 10.137 19.9594 14.4219 19.9835V15.2137C12.8166 14.9246 11.5854 13.5274 11.5854 11.8291V6.1679C11.5854 4.48159 12.8166 3.07233 14.4219 2.78324V0.446511C14.3736 0.446511 14.3132 0.446511 14.2529 0.446511C7.10741 0.338106 1.80864 7.60125 1.49482 14.1658C1.43447 15.7196 1.37412 17.2734 1.3017 18.8272C1.83278 18.9597 2.47249 19.0802 3.20877 19.2006Z"
      fill="#707070"
    />
  </IconBase>
);

export default MouseMiddleIcon;
