exports = module.exports = require("../../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .root___CpIsf {\n  background-color: darkslategrey;\n  width: 225px;\n  position: absolute;\n  top: 0;\n  right: 0;\n  pointer-events: none;\n  padding: 1em;\n}\n.threekit .root___CpIsf > li {\n  color: white;\n  font: 1.3rem Inconsolata, monospace;\n  text-shadow: 0 0 5px #c8c8c8;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n", "", {"version":3,"sources":["/threekit/app/src/sections/editor/components/Statistics/styling.less"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,OAAO;EACP,SAAS;EACT,qBAAqB;EACrB,aAAa;CACd;AACD;EACE,aAAa;EACb,oCAAoC;EACpC,6BAA6B;EAC7B,cAAc;EACd,oBAAoB;EACpB,+BAA+B;CAChC","file":"styling.less","sourcesContent":[":global(.threekit) .root {\n  background-color: darkslategrey;\n  width: 225px;\n  position: absolute;\n  top: 0;\n  right: 0;\n  pointer-events: none;\n  padding: 1em;\n}\n:global(.threekit) .root > li {\n  color: white;\n  font: 1.3rem Inconsolata, monospace;\n  text-shadow: 0 0 5px #c8c8c8;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "root___CpIsf"
};