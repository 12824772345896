import { getConfigurator } from '@threekit/cas';
import { connect } from '@threekit/react-redux';
import { ThreekitStore } from '@threekit/redux-store';
import { scene } from '@threekit/scene-graph';
import Select, { Mode } from 'components/Select';
import React, { FunctionComponent } from 'react';
import { SharedPropertyProps } from '../';

const { getAll } = scene;

interface Option {
  value: string;
  label: string;
}

export interface StateProps {
  options: Option[];
}

export interface ArrayProps extends SharedPropertyProps {
  assetId: string;
  plug: string;
  of?: { type: string };
  options?: Option[];
  onChange: (value: string) => void;
}

type ArrayRendererProps = ArrayProps & StateProps;

const ArrayRenderer: FunctionComponent<ArrayRendererProps> = props => {
  let mode: Mode = 'multiple';
  const { of } = props;

  if (of && of.type === 'String') {
    mode = 'tags';
  }

  return <Select mode={mode} {...props} />;
};

const mapStateToProps = (
  store: ThreekitStore,
  { assetId, plug, of, options }: ArrayProps
) => {
  const configurator = getConfigurator(store, assetId);

  if (!of) {
    return options;
  }

  if (of.type === 'Node') {
    const query: { [key: string]: any } = { from: assetId, property: 'name' };
    if (plug) query.hasPlug = plug;

    const references = getAll(store, query);

    options = Object.keys(references).map((id: string) => ({
      label: references[id],
      value: id,
    }));
  } else if (configurator && of.type === 'Attribute') {
    const attributes = configurator.getAttributes();
    options = attributes.map(({ id, name }) => ({ label: name, value: id }));
  }

  return {
    options,
  };
};

export default connect(mapStateToProps)(ArrayRenderer);
