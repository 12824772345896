import { constants } from '@threekit/scene-graph';
import { getUvTransform } from './uvTransform';

export function ImageBase(op: any) {
  return [
    'hdrEncoding',
    {
      label: 'Size',
      keys: ['width', 'height'],
    },
    {
      label: 'Color Transform',
      keys: ['invert', 'brightness', 'gain', 'gainPivot'],
    },
    getUvTransform(op),
  ];
}
