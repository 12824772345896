exports = module.exports = require("../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .target___2pPE4 * {\n  transition-duration: 0s !important;\n}\n.threekit .target___2pPE4 {\n  position: relative;\n}\n.threekit .popout___29oD- {\n  position: absolute;\n  pointer-events: none;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/DataDropzone/styling.less"],"names":[],"mappings":"AAAA;EACE,mCAAmC;CACpC;AACD;EACE,mBAAmB;CACpB;AACD;EACE,mBAAmB;EACnB,qBAAqB;CACtB","file":"styling.less","sourcesContent":[":global(.threekit) .target * {\n  transition-duration: 0s !important;\n}\n:global(.threekit) .target {\n  position: relative;\n}\n:global(.threekit) .popout {\n  position: absolute;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"target": "target___2pPE4",
	"popout": "popout___29oD-"
};