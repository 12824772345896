import { getColorPropertyArray, getShadowPropertyObject } from './lightLib';

export default function(op) {
  return [
    getColorPropertyArray(op),
    'distance',
    'decayExponent',
    getShadowPropertyObject(op),
  ];
}
