export default function Triplanar(op: any) {
  return [
    'space',
    'blending',
    'translation',
    'rotation',
    'scale',
    ...Object.keys(op).filter((key: string) => key.indexOf('Map') > 0),
  ];
}
console.log('HERE');
