exports = module.exports = require("../../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .toolbar___2c-kQ {\n  position: absolute;\n  top: 15px;\n  left: 15px;\n  height: 28px;\n  display: flex;\n  flex-direction: row;\n}\n.threekit .toggles___3GGar {\n  width: 200px;\n}\n.threekit .framing___1QEl5 {\n  width: 132px;\n}\n.threekit .cameras___8iZZA {\n  width: 234px;\n}\n.threekit .divider___m0OfU {\n  transform: scaleX(1.15);\n  border: none;\n  margin: 8px 0 8px 0;\n  border-top: 1px solid rgba(0, 0, 0, 0.12);\n}\n.threekit .cameraTrigger___3EOf5 {\n  flex: 0 0 155px;\n}\n", "", {"version":3,"sources":["/threekit/app/src/sections/player/containers/Toolbar/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,WAAW;EACX,aAAa;EACb,cAAc;EACd,oBAAoB;CACrB;AACD;EACE,aAAa;CACd;AACD;EACE,aAAa;CACd;AACD;EACE,aAAa;CACd;AACD;EACE,wBAAwB;EACxB,aAAa;EACb,oBAAoB;EACpB,0CAA0C;CAC3C;AACD;EACE,gBAAgB;CACjB","file":"styles.less","sourcesContent":[":global(.threekit) .toolbar {\n  position: absolute;\n  top: 15px;\n  left: 15px;\n  height: 28px;\n  display: flex;\n  flex-direction: row;\n}\n:global(.threekit) .toggles {\n  width: 200px;\n}\n:global(.threekit) .framing {\n  width: 132px;\n}\n:global(.threekit) .cameras {\n  width: 234px;\n}\n:global(.threekit) .divider {\n  transform: scaleX(1.15);\n  border: none;\n  margin: 8px 0 8px 0;\n  border-top: 1px solid rgba(0, 0, 0, 0.12);\n}\n:global(.threekit) .cameraTrigger {\n  flex: 0 0 155px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"toolbar": "toolbar___2c-kQ",
	"toggles": "toggles___3GGar",
	"framing": "framing___1QEl5",
	"cameras": "cameras___8iZZA",
	"divider": "divider___m0OfU",
	"cameraTrigger": "cameraTrigger___3EOf5"
};