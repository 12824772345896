export default function(op) {
  return [
    'translation',
    'rotation',
    'scale',
    'rotateOrder',
    'preRotation',
    'rotateAxis',
  ];
}
