import React from 'react';
import IconBase, { SVGComponent } from './base';

const HelpIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 22 22" {...props}>
    <path d="M10.2235 13.9686C10.2306 12.9231 10.2521 12.6966 10.4168 12.3027C10.5887 11.9017 11.0399 11.3717 11.7703 10.7129C12.4793 9.875 12.9842 9.23047 13.285 8.7793C13.5858 8.32812 13.7362 7.78385 13.7362 7.14648C13.7362 6.38737 13.5321 5.80371 13.1239 5.39551C12.7228 4.9873 12.1463 4.7832 11.3944 4.7832C10.7427 4.7832 10.1877 4.96224 9.72933 5.32031C9.27816 5.67122 9.05257 6.19043 9.05257 6.87793H7.03304L7.00081 6.81348C6.97933 5.66048 7.38395 4.73665 8.21468 4.04199C9.04541 3.34733 10.1053 3 11.3944 3C12.8052 3 13.9009 3.36165 14.6815 4.08496C15.4621 4.80111 15.8524 5.80371 15.8524 7.09277C15.8524 8.0166 15.5981 8.86165 15.0897 9.62793C14.5884 10.3942 13.9224 11.1784 13.0916 11.9805C12.7121 12.3672 12.4793 12.7145 12.3934 13.0225C12.3146 13.3304 12.3397 13.3169 12.3397 13.9686H10.2235ZM12.3397 18.8662H10.1375V16.6318H12.3397V18.8662Z" />
  </IconBase>
);

export default HelpIcon;
