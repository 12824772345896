import { Attribute } from '@threekit/cas';
import Select from 'components/Select';
import React, { FunctionComponent } from 'react';
import { SharedPropertyProps } from './';

export interface AttributeProps extends SharedPropertyProps {
  attributes: Attribute[];
  filterBy?: string;
  onChange: (value: string) => void;
}

const AttributeRenderer: FunctionComponent<AttributeProps> = props => {
  let { attributes } = props;
  const { filterBy } = props;

  if (!attributes) {
    return null;
  }

  if (filterBy) {
    attributes = attributes.filter(attribute => {
      const filtered = attribute[filterBy];
      if (!filtered || filtered!.length === 0) {
        return false;
      }
      return true;
    });
  }

  const options = attributes.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return <Select {...props} options={options} />;
};

export default AttributeRenderer;
