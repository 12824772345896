import cx from 'classnames';
import _ from 'lodash';
import React, {
  Component,
  ComponentType,
  createContext,
  CSSProperties,
  MouseEventHandler,
  ReactElement,
} from 'react';
import ReactDOM from 'react-dom';
import shortid from 'shortid';
import styles from './styling.less';

type Mode = 'reorder' | 'drop';

interface DraggableContextValue {
  groupId: string;
  onPanStart: (ev: MouseEvent, id: string) => void;
  parentElement: HTMLElement | null;
  sourceElement: HTMLElement | null;
  destinationElement: HTMLElement | null;
  enabled: boolean;
  hideChildren?: boolean;
  mode: Mode;
  placement: Placement;
}

const DraggableContext = createContext<DraggableContextValue | null>(null);

/**
 * TODO: there is is this same typing problem we have seem many times, when we
 * pass props to children implicitly (Group), and have to make them optional because
 * the user doesn't need to pass them in at all. this forces us to do checks within
 * the component (Target) when it is technically unnecessary (assuming the user
 * nests Target(s) directly under a Group).
 */

interface DraggableOptions {
  centered?: boolean;
  Renderer?: ComponentType<{}>;
  className?: string;
}

interface TargetProps {
  className?: string;
  style?: { [x: string]: string };
  activeClassName?: string;
  acceptClassName?: string;
  topClassName?: string;
  centerClassName?: string;
  bottomClassName?: string;
  draggableOptions?: DraggableOptions;
  type: string;
  children?: (params: Payload) => ReactElement;
  el: HTMLElement;
  id: string;
}

interface Handlers {
  onPanStart: (event: any) => void;
}

interface Payload {
  eventHandlers: Handlers;
}

interface TargetState {
  position: Point;
}

const refs: { [key: string]: { [key: string]: HTMLElement } } = {};

const isAbove = (a: DOMRect | ClientRect, b: DOMRect | ClientRect) =>
  a.bottom <= b.top;

const verticalElementComparator = (a: HTMLElement, b: HTMLElement) => {
  const aBox = a.getBoundingClientRect();
  const bBox = b.getBoundingClientRect();

  if (isAbove(aBox, bBox)) {
    return -1;
  }

  if (isAbove(bBox, aBox)) {
    return 1;
  }

  return 0;
};

function getRefsInOrder(key: string) {
  const refBucket = refs[key] || {};
  const elements = Object.values(refBucket);
  return elements.sort(verticalElementComparator);
}

class Target extends Component<TargetProps, TargetState> {
  private element: HTMLDivElement | null = null;

  constructor(props: TargetProps) {
    super(props);
    this.state = { position: { x: 0, y: 0 } };
  }

  public componentDidMount() {
    document.addEventListener('mousemove', this.onMousePositionChange);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousemove', this.onMousePositionChange);
  }

  public render() {
    const {
      className,
      type = 'li',
      children,
      el,
      id,
      style,
      draggableOptions = {},
      topClassName = '',
      centerClassName = '',
      bottomClassName = '',
      activeClassName = '',
    } = this.props;

    const active = this.isActive();

    const ElementType = type as any; // TODO: duh

    let draggableContent: JSX.Element | null = null;
    let hideChildren = active;

    const content = !!children
      ? children({
          eventHandlers: { onPanStart: this.onPanStart },
        })
      : null;

    const { sourceElement, enabled, mode, placement } = this.context;

    if (mode === 'drop') {
      hideChildren = false;
    } else if (mode === 'reorder') {
      hideChildren = hideChildren && this.context.hideChildren;
    }

    if (enabled && !!this.element) {
      const content = !!children
        ? children({
            eventHandlers: { onPanStart: () => {} },
          })
        : null;

      if (sourceElement === this.element) {
        const {
          position: { x, y },
        } = this.state;
        const { documentElement } = document;
        const parentWidth = this.getParentWidth();

        const draggableStyle: CSSProperties = {
          top: `${y + (documentElement ? documentElement.scrollTop : 0)}px`,
          left: `${x}px`,
        };

        if (!draggableOptions.Renderer) {
          draggableStyle.width = `${parentWidth}px`;
        } else {
          hideChildren = false;
        }

        if (draggableOptions.centered) {
          draggableStyle.transform = 'translate(-50%, -50%)';
        }

        draggableContent = ReactDOM.createPortal(
          <ElementType
            className={cx(
              styles.target,
              styles.popout,
              draggableOptions.className,
              className,
              {}
            )}
            style={{ ...style, ...draggableStyle }}
          >
            {draggableOptions.Renderer ? (
              <draggableOptions.Renderer />
            ) : (
              content
            )}
          </ElementType>,
          el
        );
      }
    }

    const element = this.getTargetElement();
    return (
      <>
        <ElementType
          id={id}
          data-id={id}
          ref={this.setRef}
          className={cx(styles.target, className, {
            [activeClassName]: active,
            [styles.hideChildren]: hideChildren,
            [topClassName]: active && placement === 'top',
            [centerClassName]: active && placement === 'center',
            [bottomClassName]: active && placement === 'bottom',
          })}
          style={style}
        >
          {!element && content}
        </ElementType>
        {draggableContent}
        {element && ReactDOM.createPortal(content, element)}
      </>
    );
  }

  private getTargetElement = () => {
    const {
      groupId,
      sourceElement,
      destinationElement,
      enabled,
      mode,
    } = this.context;

    if (!this.element || mode !== 'reorder') {
      return null;
    }

    if (!enabled) {
      return this.element;
    }

    const refs = getRefsInOrder(groupId);
    const sourceIndex = refs.indexOf(sourceElement);
    const destinationIndex = refs.indexOf(destinationElement);
    const index = refs.indexOf(this.element);

    if (this.element === sourceElement) {
      return destinationElement;
    } else if (sourceIndex < index && index <= destinationIndex) {
      return refs[index - 1];
    } else if (sourceIndex > index && index >= destinationIndex) {
      return refs[index + 1];
    }

    return this.element;
  };

  private isActive = () => {
    const { enabled, destinationElement } = this.context;
    return enabled && destinationElement === this.element;
  };

  private setRef = (element: HTMLDivElement | null) => {
    if (!!this.element || !element) {
      return;
    }

    this.element = element;

    const { id } = this.props;
    const { groupId } = this.context;
    const refBucket = refs[groupId] || {};
    refBucket[id] = element;
    refs[groupId] = refBucket;

    this.forceUpdate();
  };

  private onMousePositionChange = (ev: MouseEvent) => {
    const { sourceElement, enabled } = this.context;
    if (!enabled || this.element !== sourceElement) {
      return;
    }

    this.setState({ position: { x: ev.clientX, y: ev.clientY } });
  };

  private getParentWidth = () => {
    const { parentElement } = this.context;

    if (!parentElement) {
      return 0;
    }

    const { width } = parentElement.getBoundingClientRect();
    return width;
  };

  private onPanStart: MouseEventHandler = ev => {
    ev.preventDefault();
    const { id } = this.props;

    if (!this.element) {
      return;
    }

    const { onPanStart } = this.context;

    onPanStart(ev, id);
    this.setState({ position: { x: ev.clientX, y: ev.clientY } });
  };
}

Target.contextType = DraggableContext;

export interface Restrictions {
  [x: string]: {
    reparent?: boolean;
    accept?: boolean;
  };
}

interface GroupProps {
  type: string;
  className?: string;
  onComplete?: (path: [string, string], placement: Placement) => void;
  style?: CSSProperties;
  staticElements?: boolean;
  onPanStart?: (source: string) => void;
  mode: 'reorder' | 'drop';
  restrictions?: Restrictions;
  disabled?: boolean;
  id?: string;
}

type Placement = 'top' | 'bottom' | 'center';

interface GroupState {
  enabled: boolean;
  source: string | null;
  destination: string | null;
  placement: Placement;
}

interface Point {
  x: number;
  y: number;
}

class Group extends Component<GroupProps, GroupState> {
  private parentElement: HTMLElement | null = null;
  private _id = shortid.generate();

  constructor(props: GroupProps) {
    super(props);

    this.state = {
      source: null,
      destination: null,
      enabled: false,
      placement: 'center',
    };
  }

  public render() {
    const {
      className,
      type,
      style,
      children,
      staticElements,
      mode,
      id,
    } = this.props;
    const { source, destination, enabled, placement } = this.state;
    const ElementType = type as any;

    return (
      <ElementType
        ref={this.setParentRef}
        className={className}
        style={style}
        data-id={id}
        id={id}
      >
        <DraggableContext.Provider
          value={{
            placement,
            enabled,
            mode,
            destinationElement: this.getRef(destination),
            sourceElement: this.getRef(source),
            parentElement: this.parentElement,
            groupId: this._id,
            onPanStart: this.onPanStart,
            hideChildren: !staticElements,
          }}
        >
          {children}
        </DraggableContext.Provider>
      </ElementType>
    );
  }

  public componentDidMount() {
    document.addEventListener('mouseup', this.onPanEnd, false);
    document.addEventListener('mousemove', this.onMouseMove);
  }

  public componentWillUnmount() {
    document.removeEventListener('mouseup', this.onPanEnd, false);
    document.removeEventListener('mousemove', this.onMouseMove);
  }

  private setParentRef = (instance: HTMLElement | null) => {
    this.parentElement = instance;
  };

  private onPanStart = (ev: MouseEvent, id: string) => {
    const { onPanStart, disabled, restrictions = {} } = this.props;
    const source = id;

    if (disabled || (restrictions[source] && restrictions[source].reparent)) {
      return;
    }

    this.setState(
      {
        source,
        destination: source,
        enabled: true,
      },
      () => onPanStart && onPanStart(source)
    );
  };

  private onPanEnd = (ev: MouseEvent) => {
    const { source, destination, placement } = this.state;
    if (!source || !destination) {
      return;
    }

    const { onComplete } = this.props;
    this.setState(
      { enabled: false, source: null, destination: null, placement: 'center' },
      () => onComplete && onComplete([source, destination], placement)
    );
  };

  private onMouseMove = (ev: MouseEvent) => {
    const { staticElements, mode, restrictions = {} } = this.props;
    const { enabled, destination, source, placement } = this.state;
    if (!source || !destination || !enabled || staticElements) {
      return;
    }

    const { clientY } = ev;
    const refs = this.getRefs();
    Object.entries(refs).forEach(([id, child]) => {
      if (restrictions[id] && restrictions[id].accept) {
        return;
      }
      const el = ReactDOM.findDOMNode(child) as Element | null;

      if (!el) {
        return;
      }

      const { top, bottom } = el.getBoundingClientRect();

      const buffer = 10;

      if (mode === 'drop' && clientY > top && clientY < bottom) {
        let nextPlacement: Placement;
        if (clientY < top + buffer && clientY > top) {
          nextPlacement = 'top';
        } else if (clientY > bottom - buffer && clientY < bottom) {
          nextPlacement = 'bottom';
        } else {
          nextPlacement = 'center';
        }

        if (destination !== id || nextPlacement !== placement) {
          this.setState({ placement: nextPlacement, destination: id });
        }
      }

      const destinationNode = refs[destination];
      if (!destinationNode) {
        return;
      }

      const destinationBoundingBox = destinationNode.getBoundingClientRect();

      if (
        mode === 'reorder' &&
        destination !== id &&
        ((destinationBoundingBox.bottom >= top && clientY < top) || // upwards
          (destinationBoundingBox.top <= bottom && clientY > bottom)) // downwards
      ) {
        this.setState({ destination: id });
      }
    });
  };

  private getRef = (id: string | null, key = this._id) => {
    if (!id) {
      return null;
    }

    const refs = this.getRefs(key);
    if (!refs) {
      return null;
    }
    return refs[id];
  };

  private getRefs = (key = this._id) => refs[key];
}

export default { Target, Group };
