exports = module.exports = require("../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .label___NFZWu {\n  flex: 1;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  text-align: right;\n  margin-right: 0.5em;\n}\n.threekit .numbers___3RK4i {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 0.5em;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/Primitives/Vec2/styling.less"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,oBAAoB;EACpB,wBAAwB;EACxB,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;CACrB;AACD;EACE,cAAc;EACd,sCAAsC;EACtC,gBAAgB;CACjB","file":"styling.less","sourcesContent":[":global(.threekit) .label {\n  flex: 1;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  text-align: right;\n  margin-right: 0.5em;\n}\n:global(.threekit) .numbers {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 0.5em;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"label": "label___NFZWu",
	"numbers": "numbers___3RK4i"
};