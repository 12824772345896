exports = module.exports = require("../../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .loading___3jFpL {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #1ba17b;\n  opacity: 0.75;\n  display: flex;\n}\n.threekit .loading___3jFpL > label {\n  color: white;\n  font-size: 20px;\n  margin: auto;\n}\n.threekit .loading___3jFpL > button {\n  width: 18px;\n  height: 18px;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  color: white;\n}\n.threekit .root___2Mb1y {\n  cursor: pointer;\n  position: absolute;\n  bottom: 10px;\n  left: 5px;\n}\n.threekit .root___2Mb1y > img:hover {\n  opacity: 0.7;\n}\n", "", {"version":3,"sources":["/threekit/app/src/sections/player/containers/ARButton/styling.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,OAAO;EACP,QAAQ;EACR,SAAS;EACT,UAAU;EACV,0BAA0B;EAC1B,cAAc;EACd,cAAc;CACf;AACD;EACE,aAAa;EACb,gBAAgB;EAChB,aAAa;CACd;AACD;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,aAAa;CACd;AACD;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,UAAU;CACX;AACD;EACE,aAAa;CACd","file":"styling.less","sourcesContent":[":global(.threekit) .loading {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #1ba17b;\n  opacity: 0.75;\n  display: flex;\n}\n:global(.threekit) .loading > label {\n  color: white;\n  font-size: 20px;\n  margin: auto;\n}\n:global(.threekit) .loading > button {\n  width: 18px;\n  height: 18px;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  color: white;\n}\n:global(.threekit) .root {\n  cursor: pointer;\n  position: absolute;\n  bottom: 10px;\n  left: 5px;\n}\n:global(.threekit) .root > img:hover {\n  opacity: 0.7;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loading": "loading___3jFpL",
	"root": "root___2Mb1y"
};