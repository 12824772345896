import React from 'react';
import IconBase, { SVGComponent } from './base';

const ShareIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 22 22" {...props}>
    <path d="M15.3433 13.3303C14.5559 13.3303 13.8476 13.6615 13.3448 14.1909L7.46929 11.3136C7.48949 11.1791 7.50341 11.0419 7.50341 10.9008C7.50341 10.7637 7.49016 10.6299 7.47094 10.4983L13.3378 7.65317C13.841 8.18617 14.5522 8.52074 15.3433 8.52074C16.8681 8.52074 18.1036 7.28481 18.1036 5.76037C18.1036 4.23559 16.8681 3 15.3433 3C13.8185 3 12.5829 4.23559 12.5829 5.76037C12.5829 5.89751 12.5962 6.03101 12.6154 6.16285L6.74913 9.00802C6.24595 8.47502 5.53474 8.14045 4.7437 8.14045C3.21859 8.14045 1.983 9.37637 1.983 10.9008C1.983 12.4256 3.21859 13.6612 4.74337 13.6612C5.53077 13.6612 6.239 13.3299 6.74185 12.8012L12.617 15.6782C12.5965 15.8127 12.5826 15.9498 12.5826 16.0906C12.5826 17.6157 13.8182 18.851 15.3429 18.851C16.8677 18.851 18.1033 17.6157 18.1033 16.0906C18.1036 14.5659 16.8681 13.3303 15.3433 13.3303Z" />
  </IconBase>
);

export default ShareIcon;
