export default function(op) {
  const ui = [
    'zoomFactor',
    'distortionType',
    'distortion',
    'fNumber',
    'lensShift',
    'latency',
    'latency',
    'ISO',
    'specifyFocus',
    'focusDistance',
    'dofDisplayThreshold',
    'exposure',
    'whiteBalance',
    'vignetting',
    'opticalVignetting',
    'centerBias',
    'anisotropy',
    'useDof',
    'useMoblur',
    'dontAffectSettings',
    'horizontalShift',
    'horizontalOffset',
    'verticalOffset',
    'enableThinLensEquation',
    'shutterType',
  ];
  switch (op.shutterType) {
    case 0:
      ui.push('shutterSpeed');
      break;
    case 1:
      ui.push('shutterAngle', 'shutterOffset');
      break;
    case 2:
      break;
  }
  ui.push('bladesEnable');
  if (op.bladesEnable) {
    ui.push('bladesNum', 'bladesRotation', 'anisotropy');
  }
  ui.push('bmpApertureEnable');
  if (op.bmpApertureEnable) {
    ui.push('bmpApertureResolution', 'bmpApertureAffectsExposure');
  }
  ui.push('rollingShutterMode');
  if (op.rollingShutterMode) {
    ui.push('rollingShutterDuration');
  }
  return ui;
}
