import classnames from 'classnames';
import { MySVGProps, SVGComponent } from 'icons/base';
import CheckMarkIcon from 'icons/checkmark';
import CaretRight from 'icons/chevron_right';
import TargetIcon from 'icons/target';
import React, { FunctionComponent } from 'react';
import styles from './styling.less';

/**
 * TODO: the child cloning is a bit of "magic" and should be properly documented and typed properly.
 * note that typing of the child component is tricky because the prop passing is implicit (see ./index.tsx),
 * so right now all props have to be optionals.
 *
 * TODO: the Tool component should be the only possible child.
 */
export interface ToolProps {
  onClick?: (ev: React.MouseEvent, keepOpen?: boolean) => void;
  onMouseEnter?: (ev: React.MouseEvent) => void;
  onMouseLeave?: (ev: React.MouseEvent) => void;
  measureRef?: (node: HTMLElement | null) => void;
  active?: boolean;
  open?: boolean;
  id?: string;
  isTrigger?: boolean;
  text: string;
  subtext?: string;
  className?: string;
  selectable?: boolean;
  iconClassName?: string;
  triggerShowText?: boolean;
  horizontal?: boolean;
  title?: string;
}

export interface BooleanToolProps extends ToolProps {
  toggle: boolean;
  outerOnClick: (visible: boolean) => void;
  keepOpen?: boolean;
}

const createConditionalIconRenderer = (
  Renderer: SVGComponent,
  active: boolean
) => (props: MySVGProps) =>
  active ? <Renderer {...props} /> : <div className={props.className} />;

export const BooleanTool: FunctionComponent<BooleanToolProps> = ({
  toggle,
  outerOnClick,
  ...props
}) => (
  <BaseTool
    {...props}
    IconRenderer={createConditionalIconRenderer(CheckMarkIcon, toggle)}
    outerOnClick={(event: React.MouseEvent<Element>) => outerOnClick(!toggle)}
    keepOpen={true}
  />
);

export interface BaseToolProps extends ToolProps {
  IconRenderer?: SVGComponent;
  outerOnClick?: (event: React.MouseEvent<Element>, id?: string) => void;
  keepOpen?: boolean;
  disabled?: boolean;
  nested?: boolean;
}

export const BaseTool: FunctionComponent<BaseToolProps> = ({
  outerOnClick,
  onClick,
  onMouseEnter,
  onMouseLeave,
  measureRef,
  IconRenderer,
  nested = false,
  open,
  active,
  id,
  isTrigger,
  selectable,
  text,
  subtext,
  keepOpen,
  className,
  title = !!subtext ? `${text} (${subtext})` : text,
  horizontal,
  triggerShowText,
  disabled,
  iconClassName,
}) => {
  if (selectable && !IconRenderer) {
    IconRenderer = createConditionalIconRenderer(TargetIcon, !!active);
  }

  const textMarkup = text && !horizontal && (
    <span className={styles.text}>{text}</span>
  );
  const subtextMarkup = subtext && !horizontal && (
    <span className={styles.subtext}>{subtext}</span>
  );

  return (
    <button
      title={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={event => {
        if (!isTrigger && outerOnClick) {
          outerOnClick(event, id);
        }

        if (onClick) {
          onClick(event, keepOpen);
        }
      }}
      ref={measureRef}
      className={classnames(className, styles.tool, {
        [styles.open]: open && !horizontal,
        [styles.closed]: !open,
        [styles.active]: active,
        [styles.trigger]: isTrigger || horizontal,
        [styles.inline]: !isTrigger && !horizontal,
        [styles.contentfullTrigger]: isTrigger && triggerShowText && text,
        [styles.horizontal]: horizontal,
        [styles.disabled]: disabled,
      })}
    >
      {IconRenderer && (
        <IconRenderer className={classnames(styles.icon, iconClassName)} />
      )}
      {isTrigger ? triggerShowText && textMarkup : textMarkup}
      {isTrigger ? triggerShowText && subtextMarkup : subtextMarkup}
      {nested && <CaretRight className={styles.caret} />}
    </button>
  );
};

export const bindToolRenderer = (props: Partial<BaseToolProps>) => (
  innerProps: BaseToolProps
) => <BaseTool {...props} {...innerProps} />;
