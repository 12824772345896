import React from 'react';
import IconBase, { SVGComponent } from './base';

const DragIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 14 14" {...props}>
    <circle cx="4.27772" cy="1.55556" r="1.55556" fill="currentcolor" />
    <circle cx="4.27772" cy="7.00001" r="1.55556" fill="currentcolor" />
    <circle cx="4.27772" cy="12.4445" r="1.55556" fill="currentcolor" />
    <circle cx="9.72218" cy="1.55556" r="1.55556" fill="currentcolor" />
    <circle cx="9.72218" cy="7.00001" r="1.55556" fill="currentcolor" />
    <circle cx="9.72218" cy="12.4445" r="1.55556" fill="currentcolor" />
  </IconBase>
);

export default DragIcon;
