export default function(op) {
  return [
    'translation',
    'rotation',
    'scale',
    'shear',
    {
      label: 'Rotation Options',
      keys: ['rotateOrder', 'preRotation'],
    },
    {
      label: 'Pivot',
      keys: [
        'rotatePivotOffset',
        'scalePivotOffset',
        '---',
        'localRotatePivot',
        'localScalePivot',
        '---',
        //'centerPivots',
        //'resetPivots',
      ],
    },
  ];
}
