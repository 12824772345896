exports = module.exports = require("../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .placeholder___1yCtM {\n  background-color: lightgray;\n  height: 30px;\n  background-color: darkgray;\n  animation: colors___IEkq0 500ms alternate infinite;\n}\n@keyframes colors___IEkq0 {\n  from {\n    background-color: darkgray;\n  }\n  to {\n    background-color: lightgray;\n  }\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/Markup/styling.less"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,aAAa;EACb,2BAA2B;EAC3B,mDAA2C;CAC5C;AACD;EACE;IACE,2BAA2B;GAC5B;EACD;IACE,4BAA4B;GAC7B;CACF","file":"styling.less","sourcesContent":[":global(.threekit) .placeholder {\n  background-color: lightgray;\n  height: 30px;\n  background-color: darkgray;\n  animation: colors 500ms alternate infinite;\n}\n@keyframes colors {\n  from {\n    background-color: darkgray;\n  }\n  to {\n    background-color: lightgray;\n  }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"placeholder": "placeholder___1yCtM",
	"colors": "colors___IEkq0"
};