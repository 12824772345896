import React, { Component, FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import styles from './styling.less';

const validateForFile = (items: DataTransferItemList) =>
  !Array.from(items).find(item => item.kind !== 'file');

interface EventHandlers {
  onDragEnter: (ev: any) => void;
  onDragLeave: (ev: any) => void;
  onDrop: (ev: any) => void;
  onDragOver: (ev: any) => void;
}

export interface ChildrenProps {
  eventHandlers: Partial<EventHandlers>;
  onOpenFileSelector: () => void;
  overlayRef: (element: HTMLDivElement | null) => void;
}

export type DropzoneChildren = (props: ChildrenProps) => JSX.Element;

interface OverlayProps {
  fileCount: number;
}

export type OverlayComponent = FunctionComponent<OverlayProps>;

export type UploadError = 'type' | 'size' | 'count';

export interface FileRules {
  types?: string[];
  count?: number;
  size?: number; // in mb
}

interface DropzoneProps {
  disabled?: boolean;
  children: DropzoneChildren;
  processFiles: (files: File[]) => void;
  OverlayRenderer: OverlayComponent;
  rules?: FileRules;
  onError: (error: UploadError, file: File) => void;
  onOpenFileSelector?: () => void;
  id?: string;
}

type DragEvents = 'onDragOver' | 'onDragEnter' | 'onDragLeave' | 'onDrop';

const dragEvents: DragEvents[] = [
  'onDragOver',
  'onDragEnter',
  'onDragLeave',
  'onDrop',
];

interface DropzoneState {
  dragAccumulator: number;
  fileCount: number;
}

class Dropzone extends Component<DropzoneProps, DropzoneState> {
  private uploadElement: HTMLInputElement | null = null;
  private overlayElement: HTMLDivElement | null = null;

  constructor(props: DropzoneProps) {
    super(props);

    this.state = {
      dragAccumulator: 0,
      fileCount: 0,
    };
  }

  public render() {
    const { children, id } = this.props;

    const eventHandlers = dragEvents.reduce<Partial<EventHandlers>>(
      (acc, event) => {
        acc[event] = this.createDragHandler(event);
        return acc;
      },
      {}
    );

    return (
      <>
        {children({
          eventHandlers,
          overlayRef: this.setOverlayRef,
          onOpenFileSelector: this.onOpenFileSelector,
        })}
        {this.renderOverlay()}
        <input
          type="file"
          ref={this.setUploadElement}
          className={styles.upload}
          onChange={this.onFileInputChange}
          multiple={true}
          value=""
          data-id={id}
        />
      </>
    );
  }

  private onOpenFileSelector = () => {
    if (!this.uploadElement) {
      return;
    }

    const { onOpenFileSelector } = this.props;
    onOpenFileSelector && onOpenFileSelector();
    this.uploadElement.click();
  };

  private renderOverlay = () => {
    const { dragAccumulator, fileCount } = this.state;
    if (dragAccumulator === 0) {
      return null;
    }

    const { OverlayRenderer } = this.props;

    const content = <OverlayRenderer fileCount={fileCount} />;

    if (!this.overlayElement) {
      return content;
    }

    return ReactDOM.createPortal(content, this.overlayElement);
  };

  private setOverlayRef = (element: HTMLDivElement | null) => {
    this.overlayElement = element;
  };

  private setUploadElement = (node: HTMLInputElement | null) => {
    this.uploadElement = node;
  };

  private onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);

    if (!this.validateFiles(files)) {
      return;
    }

    const { processFiles } = this.props;
    processFiles(Array.from(files));
  };

  private createDragHandler = (event: DragEvents) => (ev: React.DragEvent) => {
    const { disabled } = this.props;
    if (disabled) {
      return;
    }

    const callback = this[event];
    callback(ev);
  };

  private onDragOver = (ev: React.DragEvent) => {
    ev.preventDefault();
  };

  private onDragEnter = (ev: React.DragEvent) => {
    const { dataTransfer } = ev;
    if (!dataTransfer) {
      return;
    }

    const { items } = dataTransfer;

    if (!validateForFile(items)) {
      return;
    }

    this.setState(({ dragAccumulator }) => ({
      dragAccumulator: dragAccumulator + 1,
      fileCount: items.length,
    }));
  };

  private onDragLeave = (ev: React.DragEvent) => {
    if (!validateForFile(ev.dataTransfer.items)) {
      return;
    }

    this.setState(({ dragAccumulator }) => ({
      dragAccumulator: dragAccumulator - 1,
    }));
  };

  private validateFiles = (files: File[]) => {
    const { rules = {}, onError } = this.props;
    const { count, types, size } = rules;

    if (count !== undefined && files.length > count) {
      onError('count', files[0]);
      return false;
    }

    if (!!types) {
      const invalidFile = files.find(({ type }) => !types.includes(type));

      if (!!invalidFile) {
        onError('type', invalidFile);
        return false;
      }
    }

    if (size !== undefined) {
      const fatFile = files.find(file => file.size / Math.pow(1024, 2) > size);

      if (!!fatFile) {
        onError('size', fatFile);
        return false;
      }
    }

    return true;
  };

  private onDrop = (ev: React.DragEvent) => {
    ev.preventDefault();

    const files = Array.from(ev.dataTransfer.files);

    this.setState({
      dragAccumulator: 0,
      fileCount: 0,
    });

    if (!this.validateFiles(files)) {
      return;
    }

    this.props.processFiles(files);
  };
}

export default Dropzone;
