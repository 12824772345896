import { makeOperator, OperatorDefinition } from '@threekit/cas';
import { connect } from '@threekit/react-redux';
import { ThreekitStore } from '@threekit/redux-store';
import { sceneGraph } from '@threekit/scene-graph';
import {
  mapOperatorDispatchToProps,
  OperatorRenderer,
} from 'sections/editor/containers/Editor/Inspector';
import { SharedPropertyProps } from './';

export interface CustomProps extends SharedPropertyProps {
  value: any;
  path: [string, string, string, number, string]; // see: OperatorRenderer
  op: OperatorDefinition;
}

// see OperatorProps + OperatorStateProps
interface StateProps {
  op: sceneGraph.SceneGraphOperator;
  nodeId: string;
  index: number;
  plugName: string;
  // player: Player;
  // playerV: number;
  operator: any;
}

const mapStateToProps = (
  state: ThreekitStore,
  { path: [nodeId, ignore, plugName, index, property], op }: CustomProps
): StateProps => {
  const { [property]: schema } = op as any;
  const operator = makeOperator({ schema }, property);
  return { operator, op, plugName, nodeId, index };
};

export default connect(
  mapStateToProps,
  mapOperatorDispatchToProps
)(OperatorRenderer);
