import Transform from './Transform';
import Player from './Player';
import Properties from './Properties';
import Material from './Material';
import Environment from './Environment';
import Light from './Light';
import Image from './Image';
import PolyMesh from './PolyMesh';
import Timeline from './Timeline';
import Pass from './Pass';
import Camera from './Camera';
import Null from './Null';
import Vrscene from './Vrscene';

export default {
  Player,
  Transform,
  Properties,
  Material,
  Environment,
  Light,
  Image,
  PolyMesh,
  Timeline,
  Pass,
  Camera,
  Null,
  Vrscene,
};
