import { constants } from '@threekit/scene-graph';

export function getUvTransform(op: any) {
  const scaling = op.scaleMode === constants.ScaleMode.Scale;

  return {
    label: 'UV transform',
    keys: [
      'uOffset',
      'vOffset',
      'wrapU',
      'wrapV',
      'scaleMode',
      scaling ? ['uScale', 'vScale'] : ['uTile', 'vTile'],
    ],
  };
}
