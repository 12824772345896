import { Player } from '@threekit/hub-player';
import threekitAR from 'assets/threekit_ar.svg';
import CloseIcon from 'icons/close';
import { activateAR, getARFormat } from 'lib/ar';
import React, { Component, FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { ExportFormat } from 'sections/products/products';
import styles from './styling.less';

interface LoadingOverlayProps {
  onCancelClick: () => void;
  element?: HTMLElement;
}

const LoadingOverlay: FunctionComponent<LoadingOverlayProps> = ({
  onCancelClick,
  element,
}) => {
  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.loading}>
      <label>Preparing your experience...</label>
      <button onClick={onCancelClick}>
        <CloseIcon />
      </button>
    </div>,
    element
  );
};

interface ExportOptions {
  configuration?: any;
  cache?: string;
  wait?: string;
  sync?: boolean;
  settings?: any;
}

export type ExportAssetFunction = (
  id: string,
  assetType: 'product' | 'asset',
  arFormat: ExportFormat,
  orgId: string,
  exportOptions: ExportOptions
) => Promise<string>;

interface ARButtonProps {
  player: Player;
  showAR: boolean;
  configurator: any;
  productId?: string;
  assetId?: string;
  orgId?: string;
  exportAsset: ExportAssetFunction;
}

type Status = 'started' | 'loading' | 'finished';

interface ARButtonState {
  status: Status;
}

class ARButton extends Component<ARButtonProps, ARButtonState> {
  constructor(props: ARButtonProps) {
    super(props);
    this.state = {
      status: 'finished',
    };
  }

  public render() {
    const { player, showAR } = this.props;
    const { status } = this.state;
    const arFormat = getARFormat();

    if (!showAR || !arFormat) {
      return null;
    }

    return (
      <>
        <button className={styles.root}>
          <img onClick={this.handleARFunction} src={threekitAR} />
        </button>
        {status === 'loading' && (
          <LoadingOverlay
            onCancelClick={this.onClose}
            element={player.playerEl}
          />
        )}
      </>
    );
  }

  private onClose = () => this.setState({ status: 'finished' });

  private enterAR = (url: string) => {
    const { status } = this.state;
    const arFormat = getARFormat();
    if (status === 'finished') {
      return;
    }

    this.setState({ status: 'finished' });
    activateAR(arFormat === 'glb' ? `${url}/model.glb` : url, 'AR');
  };

  private handleARFunction = async () => {
    const { assetId, exportAsset, configurator, productId, orgId } = this.props;
    const { status } = this.state;
    const arFormat = getARFormat();

    const exportOptions: ExportOptions = {
      cache: 'true',
      wait: 'true',
      sync: true,
      configuration: configurator ? configurator.getConfiguration() : {},
      settings: { arExport: true },
    };

    const id = productId || assetId;

    if (status !== 'finished' || !arFormat || !id) {
      return;
    }

    this.setState({ status: 'started' });

    const assetType = productId ? 'product' : 'asset';
    const exportingAsset = exportAsset(
      id,
      assetType,
      arFormat,
      orgId as string,
      exportOptions
    );

    /**
     * The idea here is that we do not want to show the loading overlay if
     * the fetch of the exported asset takes less than 500ms, because it
     * would just be a jarring flash.
     */
    const delay = new Promise<string>((resolve) =>
      setTimeout(() => resolve(''), 500)
    );
    const result = await Promise.race([exportingAsset, delay]);

    if (!result) {
      this.setState({ status: 'loading' });
      exportingAsset.then(this.enterAR);
    } else {
      this.enterAR(result);
    }
  };
}

export default ARButton;
