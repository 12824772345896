import { Operator } from '@threekit/cas';
import { connect } from '@threekit/react-redux';
import { ThreekitStore } from '@threekit/redux-store';
import { scene, sceneGraph } from '@threekit/scene-graph';
import { placeholder } from 'components/Markup';
import React, { FunctionComponent } from 'react';
import { getRenderer, SharedPropertyProps } from './';

export interface PathPropertyProps extends SharedPropertyProps {
  value: any;
  onChange: (value: any) => void;
}

interface StateProps {
  property?: string;
  operator?: Operator;
}

type PathPropertyRendererProps = PathPropertyProps & StateProps;

const PathProperty: FunctionComponent<PathPropertyRendererProps> = props => {
  const { property, value, onChange, operator, label } = props;

  if (!property || !operator) {
    return placeholder;
  }

  const toVal = (v: any) => {
    const d: { [key: string]: any } = {};
    operator.set(d, property, v);
    return d[property];
  };
  const data = (operator as any).data;
  const schema = operator.getSchema(data || {}, property);
  if (!schema) {
    return placeholder;
  }

  const Prop = getRenderer(schema.type);
  return (
    <Prop
      {...schema}
      name={'value'}
      value={toVal(value)}
      label={label}
      onChange={(value: any) => onChange(toVal(value))}
    />
  );
};

const mapStateToProps = (
  store: ThreekitStore,
  { from, data }: PathPropertyProps
) => {
  if (!data || !from) {
    return {};
  }

  const [nodeId, plug, operatorIndex, property] = data[from];

  if (!nodeId || !plug || !operatorIndex || !property) {
    return {};
  }

  const node = scene.find(store, nodeId);

  if (!node) {
    return {};
  }

  const operators = sceneGraph.get(store, [nodeId, 'plugs', plug]);
  const { type } = operators[operatorIndex];
  const operator = sceneGraph.lookups.Operator(plug, type);
  return { property, operator };
};

export default connect(mapStateToProps)(PathProperty);
