exports = module.exports = require("../../../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .root___NTXYq {\n  overflow-y: auto;\n  position: relative;\n}\n.threekit .root___NTXYq .ant-drawer {\n  z-index: 2;\n}\n.threekit .root___NTXYq .ant-drawer-content-wrapper,\n.threekit .root___NTXYq .ant-drawer-header,\n.threekit .root___NTXYq .ant-drawer-content {\n  background: #f5f5f5;\n}\n.threekit .root___NTXYq > :not(:first-child) {\n  margin-top: 0.5em;\n}\n", "", {"version":3,"sources":["/threekit/app/src/sections/editor/containers/Configurator/Inspector/styling.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,mBAAmB;CACpB;AACD;EACE,WAAW;CACZ;AACD;;;EAGE,oBAAoB;CACrB;AACD;EACE,kBAAkB;CACnB","file":"styling.less","sourcesContent":[":global(.threekit) .root {\n  overflow-y: auto;\n  position: relative;\n}\n:global(.threekit) .root :global(.ant-drawer) {\n  z-index: 2;\n}\n:global(.threekit) .root :global(.ant-drawer-content-wrapper),\n:global(.threekit) .root :global(.ant-drawer-header),\n:global(.threekit) .root :global(.ant-drawer-content) {\n  background: #f5f5f5;\n}\n:global(.threekit) .root > :not(:first-child) {\n  margin-top: 0.5em;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "root___NTXYq"
};