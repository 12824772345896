import { constants } from '@threekit/scene-graph';

export default function(op) {
  return [
    '---  Ambient Lignt',
    'ambientLight',
    'ambientLightFactor',
    'backgroundOpacity',
    '---  Image-Based Lighting',
    'environmentMap',
    'environmentMapRotation',
    'environmentMapFlip',
    'environmentMapFactor',
    {
      label: 'Background',
      keys: ['backgroundStyle', getBackgroundStyle[op.backgroundStyle]],
    },
  ];
}

const getBackgroundStyle = {
  [constants.BackgroundStyles.Transparent]: [],
  [constants.BackgroundStyles.Color]: ['backgroundColor'],
  [constants.BackgroundStyles.Image]: [
    'backgroundColor',
    'backgroundImage',
    'backgroundImagePosition',
  ],
  [constants.BackgroundStyles.CubeMap]: [
    'backgroundColor',
    'backgroundCubeMap',
    'backgroundCubeMapRotation',
    'backgroundCubeMapFlip',
  ],
  [constants.BackgroundStyles.Environement]: [],
};
