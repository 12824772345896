exports = module.exports = require("../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .text___2giKL {\n  flex: 1;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  text-align: right;\n  margin-right: 0.5em;\n}\n.threekit .picker___I1ILe {\n  margin-left: 0.25em;\n  margin-right: 0.25em;\n  margin-bottom: 0.25em;\n  margin-top: 0.65em;\n  user-select: none;\n}\n.threekit .container___3E5l6 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.threekit .swatch___2XMOZ {\n  cursor: pointer;\n  flex: 2;\n  padding-top: 0.35em;\n  padding-bottom: 0.35em;\n  text-align: center;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/Primitives/Color/styling.less"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,oBAAoB;EACpB,wBAAwB;EACxB,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB;CACrB;AACD;EACE,oBAAoB;EACpB,qBAAqB;EACrB,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;CACnB;AACD;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;CACrB;AACD;EACE,gBAAgB;EAChB,QAAQ;EACR,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;CACpB","file":"styling.less","sourcesContent":[":global(.threekit) .text {\n  flex: 1;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  text-align: right;\n  margin-right: 0.5em;\n}\n:global(.threekit) .picker {\n  margin-left: 0.25em;\n  margin-right: 0.25em;\n  margin-bottom: 0.25em;\n  margin-top: 0.65em;\n  user-select: none;\n}\n:global(.threekit) .container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n:global(.threekit) .swatch {\n  cursor: pointer;\n  flex: 2;\n  padding-top: 0.35em;\n  padding-bottom: 0.35em;\n  text-align: center;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"text": "text___2giKL",
	"picker": "picker___I1ILe",
	"container": "container___3E5l6",
	"swatch": "swatch___2XMOZ"
};