import React from 'react';
import IconBase, { SVGComponent } from './base';

const AddIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 32 32" {...props}>
    <circle cx="16" cy="16" r="11.5" fill="currentColor" stroke="white" />
    <path
      d="M14.648 11.5283C14.6953 11.6229 14.7426 11.7174 14.7899 11.7883C14.8371 11.8592 14.908 11.9302 14.9553 12.0011C15.0262 12.072 15.0972 12.1429 15.1917 12.2138C15.2626 12.2611 15.3572 12.332 15.4754 12.3556C15.5699 12.4029 15.6645 12.4266 15.7827 12.4502C15.9009 12.4738 15.9954 12.4738 16.09 12.4738C16.1845 12.4738 16.3027 12.4738 16.3973 12.4502C16.4918 12.4266 16.5864 12.4029 16.6573 12.3556H16.6809C16.7755 12.3084 16.8701 12.2611 16.9646 12.1902C17.0355 12.1429 17.1064 12.072 17.1774 12.0011L17.201 11.9774C17.2719 11.9065 17.3192 11.8356 17.3665 11.7647C17.4137 11.6938 17.461 11.6229 17.4847 11.5283C17.4847 11.5047 17.4847 11.5047 17.5083 11.481C17.5556 11.3865 17.5792 11.2683 17.6029 11.1737C17.6265 11.0792 17.6265 10.961 17.6265 10.8664C17.6265 10.7719 17.6265 10.6537 17.6029 10.5591C17.5792 10.4646 17.5556 10.37 17.5083 10.2518C17.4374 10.0863 17.3428 9.94451 17.2246 9.82631C17.201 9.80268 17.201 9.80268 17.1774 9.77904C17.1064 9.70812 17.0355 9.66084 16.941 9.58993C16.8464 9.54265 16.7755 9.49537 16.6809 9.4481C16.5864 9.40082 16.4918 9.37718 16.3973 9.35354C16.2791 9.3299 16.1845 9.3299 16.09 9.3299C15.9954 9.3299 15.8772 9.3299 15.7827 9.35354C15.6881 9.37718 15.5936 9.40082 15.499 9.4481H15.4754C15.3808 9.49537 15.2863 9.54265 15.2154 9.58993C15.1208 9.66084 15.0499 9.70812 14.979 9.77904C14.908 9.84995 14.8371 9.92087 14.7899 9.99178C14.7426 10.0627 14.6953 10.1573 14.648 10.2518C14.6007 10.3464 14.5771 10.4409 14.5535 10.5355C14.5298 10.6537 14.5298 10.7482 14.5298 10.8428C14.5298 10.9373 14.5298 11.0555 14.5535 11.1501C14.5771 11.3392 14.6244 11.4337 14.648 11.5283Z"
      fill="white"
      fillOpacity="0.85"
    />
    <path
      d="M17.9346 20.1806H17.391V14.3891C17.391 14.0582 17.1309 13.7745 16.7763 13.7745H14.2943C13.9634 13.7745 13.6797 14.0345 13.6797 14.3891V15.6656C13.6797 15.9965 13.9397 16.2802 14.2943 16.2802H14.7907V20.2042H14.2943C13.9634 20.2042 13.6797 20.4642 13.6797 20.8188V22.0953C13.6797 22.4262 13.9397 22.7099 14.2943 22.7099H17.9346C18.2656 22.7099 18.5492 22.4499 18.5492 22.0953V20.8188C18.5492 20.4642 18.2656 20.1806 17.9346 20.1806Z"
      fill="white"
      fillOpacity="0.85"
    />
  </IconBase>
);

export default AddIcon;
