import { connect } from '@threekit/react-redux';
import { ThreekitStore } from '@threekit/redux-store';
import { scene } from '@threekit/scene-graph';
import Select from 'components/Select';
import React from 'react';
import { SharedPropertyProps } from './';
import Asset from './Asset';
import styles from './Plug/styles.less';

export interface NodeProps extends SharedPropertyProps {
  assetId?: string;
  nodeType?: string;
  plug?: string;
}

const mapStateToProps = (store: ThreekitStore, ownProps: NodeProps) => {
  const { assetId, nodeType, plug } = ownProps;
  const query: { [key: string]: any } = { from: assetId, property: 'name' };
  if (nodeType) query.type = nodeType;
  if (plug) query.hasPlug = plug;
  const references = scene.getAll(store, query);
  const options = Object.keys(references).map((id: string) => {
    return { label: references[id], value: id };
  });

  return {
    options,
  };
};
const LocalNode = connect(mapStateToProps)(Select);

export default function NodeDispatch(props: any) {
  const { assetType, label, value } = props;
  if (assetType) {
    const nodeValue = typeof value === 'string' ? value : '';
    const assetValue = typeof value === 'string' ? {} : value;

    return (
      <div className={styles.root}>
        <Asset
          {...props}
          value={assetValue}
          showAssetConfiguration={true}
          label={`${label} Asset`}
        />
        <LocalNode {...props} value={nodeValue} label={`${label} Node`} />
      </div>
    );
  }
  return <LocalNode {...props} />;
}
