import { Player } from '@threekit/hub-player';
import React, { FunctionComponent } from 'react';
import Statistics from 'sections/editor/components/Statistics';
import PlayerToolbar from 'sections/player/containers/Toolbar';

export interface EditorLayerProps {
  player: Player;
}

const EditorLayer: FunctionComponent<EditorLayerProps> = ({ player }) => (
  <>
    <PlayerToolbar player={player} />
    <Statistics player={player} />
  </>
);

export default EditorLayer;
