exports = module.exports = require("../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .target___cpWgM * {\n  transition-duration: 0s !important;\n}\n.threekit .popout___3QAJI {\n  position: absolute;\n  pointer-events: none;\n}\n.threekit .hideChildren___1iTxk > * {\n  visibility: hidden;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/Draggable/styling.less"],"names":[],"mappings":"AAAA;EACE,mCAAmC;CACpC;AACD;EACE,mBAAmB;EACnB,qBAAqB;CACtB;AACD;EACE,mBAAmB;CACpB","file":"styling.less","sourcesContent":[":global(.threekit) .target * {\n  transition-duration: 0s !important;\n}\n:global(.threekit) .popout {\n  position: absolute;\n  pointer-events: none;\n}\n:global(.threekit) .hideChildren > * {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"target": "target___cpWgM",
	"popout": "popout___3QAJI",
	"hideChildren": "hideChildren___1iTxk"
};