exports = module.exports = require("../../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .annotation___kKPuQ {\n  position: absolute;\n  transform: translate(-50%, -50%);\n}\n.threekit .annotation___kKPuQ svg {\n  width: 100%;\n  height: 100%;\n  color: #393939;\n}\n.threekit .annotation___kKPuQ :hover {\n  color: #54af8b;\n}\n.threekit .overlay___2uZuH {\n  position: absolute;\n  background: rgba(0, 0, 0, 0.85);\n  color: white;\n  padding: 4px;\n  font-size: 12px;\n  word-wrap: break-word;\n  transform: translateY(-50%);\n}\n", "", {"version":3,"sources":["/threekit/app/src/sections/player/containers/Annotations/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iCAAiC;CAClC;AACD;EACE,YAAY;EACZ,aAAa;EACb,eAAe;CAChB;AACD;EACE,eAAe;CAChB;AACD;EACE,mBAAmB;EACnB,gCAAgC;EAChC,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,4BAA4B;CAC7B","file":"styles.less","sourcesContent":[":global(.threekit) .annotation {\n  position: absolute;\n  transform: translate(-50%, -50%);\n}\n:global(.threekit) .annotation svg {\n  width: 100%;\n  height: 100%;\n  color: #393939;\n}\n:global(.threekit) .annotation :hover {\n  color: #54af8b;\n}\n:global(.threekit) .overlay {\n  position: absolute;\n  background: rgba(0, 0, 0, 0.85);\n  color: white;\n  padding: 4px;\n  font-size: 12px;\n  word-wrap: break-word;\n  transform: translateY(-50%);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"annotation": "annotation___kKPuQ",
	"overlay": "overlay___2uZuH"
};