exports = module.exports = require("../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .modal___2BG7P {\n  position: absolute;\n  border: solid 1px lightgray;\n  top: var(--y);\n  left: var(--x);\n  border-radius: 5px;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/ContextModal/styling.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,4BAA4B;EAC5B,cAAc;EACd,eAAe;EACf,mBAAmB;CACpB","file":"styling.less","sourcesContent":[":global(.threekit) .modal {\n  position: absolute;\n  border: solid 1px lightgray;\n  top: var(--y);\n  left: var(--x);\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"modal": "modal___2BG7P"
};