import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Labelled from 'components/Labelled';
import React, {
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react';
import Select from '../Select';
import { SharedPropertyProps } from './';

export interface StringProps extends SharedPropertyProps {
  values?: string[];
  forceType?: boolean;
  onComplete?: () => void;
  placeholder?: string;
  className?: string;
  type?: string;
  focusOnMount?: boolean;
  onChange: (value: string) => void;
  value?: string;
  maxLength?: number;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  dataId?: string;
}

const StringProperty: FunctionComponent<StringProps> = props => {
  const {
    values,
    forceType,
    label,
    onComplete,
    placeholder,
    className,
    name,
    type = 'text',
    focusOnMount,
    onChange,
    onKeyPress,
    value,
    inline,
    maxLength,
    disabled,
    dataId,
  } = props;

  if (values && values.length && !forceType) {
    return <Select {...props} />;
  }

  let input: Input | TextArea | null = null;

  const [inputValue, setInputValue] = useState(value);

  const setRef = (node: Input | TextArea | null) => {
    input = node;
  };

  const handleChange = (ev: { target: { value: string } }) => {
    if (inputValue !== ev.target.value) {
      setInputValue(ev.target.value);
      onChange(ev.target.value);
    }
  };

  const preventDragging = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  };

  // Focus input if needed on first render only
  useEffect(() => {
    if (focusOnMount && input) {
      input.focus();
    }
  }, []);

  // Update when value prop changes
  useEffect(() => {
    if (inputValue !== value) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <Labelled inline={inline} value={label}>
      {type !== 'multiline' && (
        <Input
          className={className}
          onKeyPress={onKeyPress}
          draggable={false}
          onDragStart={preventDragging}
          onDragEnter={preventDragging}
          onDragOver={preventDragging}
          onDrop={preventDragging}
          ref={setRef}
          onChange={handleChange}
          onBlur={onComplete}
          onPressEnter={onComplete}
          name={name}
          type={type}
          placeholder={placeholder}
          value={inputValue}
          maxLength={maxLength}
          disabled={disabled}
          data-id={dataId}
        />
      )}

      {type === 'multiline' && (
        <Input.TextArea
          ref={setRef}
          className={className}
          onChange={handleChange}
          onBlur={onComplete}
          name={name}
          autoSize={{ minRows: 4, maxRows: 7 }}
          placeholder={placeholder}
          value={inputValue}
        />
      )}
    </Labelled>
  );
};

export default StringProperty;
