import { ImageBase } from './ImageBase';

export function Image(op: any) {
  return [
    'renderOption',
    'glOption',
    'optimizeImage',
    op.optimizeImage && 'optimizeFormat',
    op.resizeImage && ['resizeWidth', 'resizeHeight'],
    ...ImageBase(op),
    // imageIsAnisotropyOrNormalMap && // For when we have an 'imageType' property
    {
      label: 'Channels',
      keys: ['redGreenLabel', 'redFlip', 'greenFlip', 'swapRG'],
    },
  ];
}
