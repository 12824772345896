export function CanvasFill(op: any) {
  return [
    {
      label: 'Fill',
      keys: [
        'color',
        'opacity',
        'composition',
        {
          label: 'Position',
          keys: ['xOffset', 'yOffset', 'width', 'height'],
        },
      ],
    },
  ];
}
