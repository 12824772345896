import { constants } from '@threekit/scene-graph';

const { RenderCategories } = constants;

export default function(op: any) {
  return [
    {
      label: 'Base',
      keys: [
        ...getKeysFor(op, 'base'),
        op.renderCategory !== RenderCategories.OPAQUE && 'baseMapTransparent',
      ],
    },
    opacitySection(op),
    mapSection(op, 'metallic'),
    {
      label: 'Roughness',
      keys: [
        ...getKeysFor(op, 'roughness'),
        ...getKeysFor(op, 'anisotropy'),
        'anisotropyRotationFactor',
      ],
    },
    {
      label: 'Specular',
      keys: [
        ...getKeysFor(op, 'specular'),
        'ior',
        ...getKeysFor(op, 'specularIntensity'),
        'multiScatter',
      ],
    },
    {
      label: 'Sheen',
      keys: ['sheenFactor'],
    },
    {
      label: 'Clear Coat',
      keys: [
        'clearCoat',
        'clearCoatRoughness',
        ...getKeysFor(op, 'clearCoatNormal'),
      ],
    },
    mapSection(op, 'bump'),
    mapSection(op, 'normal'),
    {
      label: 'Emissive',
      keys: [...getKeysFor(op, 'emissive'), 'emissiveScale'],
    },
    mapSection(op, 'ao'),
    mapSection(op, 'light'),
  ];
}

function opacitySection(op: any) {
  const keys = [
    'renderCategory',
    'depthWrite',
    'depthTest',
    op.renderCategory === RenderCategories.CUTOUT && 'alphaTestMinimum',
  ];

  if (op.renderCategory !== RenderCategories.OPAQUE) {
    keys.push(
      ...getKeysFor(op, 'opacity'),
      ...getKeysFor(op, 'transparency'),
      'invertOpacity'
    );
  }

  return {
    label: 'Transparency / Opacity',
    keys,
  };
}

function getKeysFor(op: any, name: string) {
  const factorName = `${name}Factor`;
  const colorName = `${name}Color`;
  const mapName = `${name}Map`;
  const uvName = `${name}UV`;

  const hasMap =
    op[mapName] && (typeof op[mapName] === 'string' || !!op[mapName].assetId);

  const keys = [
    op[factorName] !== undefined && factorName,
    op[colorName] !== undefined && colorName,
    mapName,
    hasMap && uvName,
  ];

  return keys;
}

function mapSection(op: any, name: string) {
  const capitalizedName = name.charAt(0).toUpperCase() + name.substring(1);

  const section = {
    label: capitalizedName,
    keys: getKeysFor(op, name),
  };

  return section;
}
