import React, { FunctionComponent } from 'react';
import IconBase, { SVGComponent } from './base';

const PlaceholderIcon: SVGComponent = props => {
  return (
    <IconBase viewBox="0 0 51 57" {...props}>
      <path
        d="M25.4998 56L49.3155 42.25V14.75L25.4998 1L1.68408 14.75V42.25L25.4998 56ZM25.4998 56L25.2498 28M2.49978 15L25.4998 28.5L48.4998 15"
        stroke="currentColor"
        fill="transparent"
        strokeWidth="1.5"
        strokeDasharray="3 2"
      />
    </IconBase>
  );
};

export default PlaceholderIcon;
