import FrameIcon from 'icons/frame';
import React, { FunctionComponent } from 'react';
import Label from './Label';

export interface ImageProps {}

const ImageRenderer: FunctionComponent<ImageProps> = ({}) => (
  <>
    <FrameIcon style={{ width: '32px', height: '32px' }} />
    <header>Drag here or click to upload</header>
    <Label type="image" />
  </>
);

export default ImageRenderer;
