import { constants } from '@threekit/scene-graph';
const { FoV, FocalLength } = constants.ViewControl;
export default function(op) {
  const ui = [
    'projection',
    {
      label: 'Lens',
      keys: [
        'viewCtrl',
        ...getViewControls(op),
        // 'fStop', not implemented
      ],
    },
    {
      label: 'Viewport',
      keys: [
        'aspectRatio',
        'nearClip',
        'farClip',
        'autoFitClipPlanes',
        op.autoFitClipPlanes && 'autoFitClipPlanesLabel',
      ],
    },
    {
      label: 'Controls',
      keys: [
        'controlsMode',
        'targetNode',
        // 'focalDepthEnabled', // Not Implemented
        // 'focalDepth',        // Not Implemented
        'frameOnInit',
        'frameTargetOverride',
        'keepPlayerChanges',
      ],
    },
    {
      label: 'Display',
      keys: ['showFrustum', op.showFrustum && 'frustumColor'],
    },
    {
      label: 'Orbit Speed',
      keys: ['orbitSensitivity', 'orbitMomentum'],
    },
    getConstraints(op),
  ];

  return ui;
}

function getViewControls(op) {
  switch (op.viewCtrl) {
    case FoV:
      return ['fieldOfView'];
    case FocalLength:
      return [
        'filmSizeBox',
        op.filmSizeBox === constants.CUSTOM_FILM_SIZE && 'customFilmSize',
        'focalLength',
      ];
    default:
      return [];
  }
}

function getConstraints(op) {
  const constrainLong =
    op['constraintLongitudeMode'] != constants.LongitudeConstraintMode.OFF;
  const constrainLat =
    op['constraintLatitudeMode'] != constants.LatitudeConstraintMode.OFF;
  const constrainZoom =
    op['constraintZoomMode'] != constants.ZoomConstraintMode.OFF;

  return {
    label: 'Constraints',
    keys: [
      'constraintLongitudeMode',
      constrainLong && 'constraintLongitudeMin',
      constrainLong && 'constraintLongitudeMax',
      'constraintLatitudeMode',
      constrainLat && 'constraintLatitudeMin',
      constrainLat && 'constraintLatitudeMax',
      'constraintZoomMode',
      constrainZoom && 'constraintDistanceOffsetMin',
      constrainZoom && 'constraintDistanceOffsetMax',
    ],
  };
}
