import { ConfigurationType } from '@threekit/cas';
import { Player } from '@threekit/hub-player';
import { LayerTranslator } from '@threekit/layer-translator';
import { ConnectedComponent } from '@threekit/react-redux';
import { connect } from '@threekit/react-redux';
import { ThreekitStore, ThunkDispatch } from '@threekit/redux-store';
import { scene, sceneGraph, sceneIO } from '@threekit/scene-graph';
import { ThreeTranslator } from '@threekit/webgl-renderer';
import { ConfigProvider } from 'antd';
import loadingIcon from 'assets/loading.svg';
import cx from 'classnames';
import rootElement from 'lib/rootElement';
import React, {
  CSSProperties,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { fetchConfiguration } from 'sections/configurations/configurations';
import { ConfiguratorInspectorProps } from 'sections/editor/containers/Configurator/Inspector';
import { OrgPlayerSettings } from 'sections/orgs/orgs';
import { vars } from 'utils/styling';
import CanvasContainer from '../components/CanvasContainer';
import Annotations from './Annotations';
import Layers from './Layers';
import styles from './styling.less';

interface ImagePlaceholderProps {
  src?: string;
}

const ImagePlaceholder: FunctionComponent<ImagePlaceholderProps> = ({
  src,
}) => {
  if (!!src) {
    return <img src={src} className={styles.imagePlaceholder} />;
  }

  return (
    <div className={styles.defaultPlaceholder}>
      <span>Image unavailable for this configuration</span>
    </div>
  );
};

let ConfiguratorRenderer: ConnectedComponent<
  React.FunctionComponent<ConfiguratorInspectorProps>,
  Partial<ConfiguratorInspectorProps>
> | null = null;

interface StateProps {
  progress: number;
  rendered: boolean;
  v: number;
  compositeNode?: sceneGraph.SceneGraphNode;
}

export interface PlayerClassnames {
  player?: string;
  mobile?: string;
}

interface OwnProps {
  player: Player;
  style?: CSSProperties;
  mode?: 'player' | 'editor';
  showConfigurator?: boolean;
  initialConfiguration?: ConfigurationType;
  id?: string;
  classnames?: PlayerClassnames;
  showShare?: boolean;
  showAR?: boolean;
  productId?: string;
  show3DPlaceholder?: boolean;
  useCatalog?: boolean;
  orgId?: string;
  display?: 'webgl' | 'image';
  switchDisplayMode?: Function;
  placeholder?: string;
  authToken?: string;
  settings: OrgPlayerSettings;
}

interface DispatchProps {
  attachTranslator: (el: HTMLElement) => void;
  resumeConfiguration: () => Promise<any>;
}

type PlayerProps = StateProps & OwnProps & DispatchProps;

const PlayerComponent: FunctionComponent<PlayerProps> = ({
  attachTranslator,
  resumeConfiguration,
  player,
  progress,
  rendered,
  style,
  children,
  showConfigurator,
  id,
  classnames = {},
  mode = 'player',
  placeholder,
  orgId,
  show3DPlaceholder,
  useCatalog,
  settings,
  compositeNode,
  showShare,
  showAR,
  display,
  switchDisplayMode,
}) => {
  // #region hooks ------------------------------------------------------------
  const [firstPass, setFirstPass] = useState(false);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const layerContainer = useRef(null);

  // #endregion ------------------------------------------------------------

  useEffect(() => {
    if (progress === 1 && rendered) {
      setFirstPass(true);
    }
  }, [progress, rendered]);

  function onResize(el: HTMLElement | null) {
    if (!player || !el) {
      return;
    }

    if (!player.hasTranslator()) {
      const layerEl = layerContainer.current || el;
      attachTranslator(display === 'image' ? layerEl : el);
      return;
    }

    const rect = el.getBoundingClientRect();
    player.resize(rect);

    // TODO: player.resize should trigger a rerender (mutable api...)
    forceUpdate();
  }

  const configurator = player.getConfigurator();

  useEffect(() => {
    (async () => {
      if (!configurator) {
        return;
      }

      if (!ConfiguratorRenderer && showConfigurator) {
        await resumeConfiguration();
        import(
          /* webpackChunkName: "configurator" */ 'sections/editor/containers/Configurator/Inspector'
        ).then(result => {
          ConfiguratorRenderer = result.default;
          forceUpdate();
        });
      }
    })();
  }, [configurator]);

  const isLoading = progress <= 1 && !firstPass;

  const isChanging = display === 'image' && !rendered && firstPass;

  const LayerRenderer = Layers[mode];

  const { playerEl } = player;

  let isMobile = false;
  if (!!playerEl) {
    const { width } = playerEl.getBoundingClientRect();
    isMobile = width <= 736 && width > 0 && mode === 'player';
  }

  /**
   * TODO: we needed to add a key here that changes based on whether we are in fullscreen mode
   * so that antd does not use the cached element from `getPopupContainer` inside the `ConfigProvider`
   * (i.e when the key changes the component is re-mounted). This change has a breaking effect on
   * our `CanvasContainer` so we needed to wrap the `ConfigProvider` only around the configurator instead
   * of the entire player component (this), which means that we will fall back to the root config provider
   * for overlay elements outside of the configurator. So for example if we have a `Select` within the
   * `Annotations` component, the dropdown wouldn't be visible in fullscreen mode. Fortunately for now
   * this isn't an issue but easily could cause confusion in the future. To solve these issues properly
   * it will likely require a fork or PR into antd.
   *
   * references:
   * https://gitlab.com/threekit/app/-/merge_requests/652
   * https://ant.design/components/config-provider/
   *
   */
  const configuratorMarkup =
    !!configurator && showConfigurator && !!ConfiguratorRenderer ? (
      <ConfigProvider
        key={player.isFullscreen ? 0 : 1}
        getPopupContainer={() =>
          player.isFullscreen ? player.playerEl || rootElement : rootElement
        }
      >
        <ConfiguratorRenderer className={styles.configurator} player={player} />
      </ConfigProvider>
    ) : null;

  let insidePlayerMarkup = null;
  let outsidePlayerMarkup = null;

  if (isMobile) {
    if (player.isFullscreen) {
      insidePlayerMarkup = (
        <div className={styles.mobileFullscreenConfiguratorWrapper}>
          {configuratorMarkup}
        </div>
      );
    } else {
      outsidePlayerMarkup = configuratorMarkup;
    }
  } else {
    insidePlayerMarkup = configuratorMarkup;
  }

  const imagePlaceholderVisible =
    display === 'image' && player.isImagePlaceholderVisible();

  return (
    <>
      <div
        id={id}
        className={cx(classnames.player, styles.player, {
          [classnames.mobile || '']: isMobile,
          [styles.mobile]: isMobile,
        })}
        style={style}
        ref={el => {
          if (!el) {
            return;
          }

          player.setPlayerEl(el);
        }}
      >
        <CanvasContainer className={styles.canvas} onCanvasResize={onResize}>
          <div
            className={cx(styles.layerContainer, {
              [styles.hidden]: imagePlaceholderVisible,
            })}
            ref={layerContainer}
          />
          {imagePlaceholderVisible && <ImagePlaceholder src={placeholder} />}
          <LayerRenderer
            settings={settings}
            orgId={orgId}
            player={player}
            productId={player.assetId}
            isLoading={isLoading}
            show3DPlaceholder={show3DPlaceholder}
            showShare={showShare}
            showAR={showAR}
            useCatalog={useCatalog}
            display={display}
            switchDisplayMode={switchDisplayMode}
          />
          <Annotations player={player} />
          {isLoading && (
            <div className={styles.cover}>
              <div className={styles.loading}>Loading...</div>
              <div className={styles.progressbar}>
                <div
                  className={styles.progressbarInner}
                  style={vars({ width: `${progress * 100}%` })}
                />
              </div>
            </div>
          )}
          <div
            className={cx(styles.layersLoader, {
              [styles.loading]: isChanging && !imagePlaceholderVisible,
            })}
          >
            <img src={loadingIcon} />
          </div>
        </CanvasContainer>
        {insidePlayerMarkup}
        {children}
      </div>
      {outsidePlayerMarkup}
    </>
  );
};

const mapStateToProps = (
  store: ThreekitStore,
  { player }: OwnProps
): StateProps => {
  const compositeNode = scene.get(store, {
    type: 'Composite',
    from: player.assetId,
  });

  return {
    compositeNode,
    progress: player.getLoadingProgress(),
    rendered: sceneIO.isPhase(
      store,
      player.stageId,
      sceneGraph.SCENE_PHASES.RENDERED
    ),
    v: player.v,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: OwnProps
): DispatchProps => {
  const {
    player,
    initialConfiguration,
    display = 'webgl',
    authToken,
  } = ownProps;
  return {
    resumeConfiguration: async () => {
      // Check query param for shortId
      const configurator = player.getConfigurator();
      const params = new URLSearchParams(location.search);
      const shortId = params.get('tkcsid');

      if (configurator && shortId) {
        const config = await dispatch(fetchConfiguration(shortId));
        return configurator.setConfiguration(config.variant);
      } else if (configurator && initialConfiguration) {
        return configurator.setConfiguration(initialConfiguration);
      }
    },
    attachTranslator: (el: HTMLElement) => {
      dispatch(async (store: ThreekitStore) => {
        if (!player.assetId) {
          return;
        }
        let translator;
        if (display === 'image') {
          translator = new LayerTranslator(
            store,
            player.stageId,
            el,
            authToken
          );
        } else {
          translator = new ThreeTranslator(store, player.stageId, el);
        }
        player.setTranslator(translator);
        player.attach(el);
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerComponent);
