export default function(op) {
  return [
    'activated',
    'render_cam',
    '--- Output File',
    'filename',
    'fileFormat',
    '--- Resolution Override',
    'over_res',
    op.over_res && ['over_res_x', 'over_res_y'],
  ];
}
