import cx from 'classnames';

interface AnyMap {
  [x: string]: any;
}

export function vars(properties: AnyMap) {
  const style: AnyMap = {};
  const keys = Object.keys(properties);
  for (const key of keys) {
    style[`--${key}`] = properties[key];
  }
  return style;
}

export const units = (properties: AnyMap, unit = 'px') =>
  Object.entries(properties).reduce(
    (acc, [prop, val]) => {
      acc[prop] = `${val}${unit}`;
      return acc;
    },
    {} as AnyMap
  );

export const uvars = (properties: AnyMap, unit = 'px') =>
  vars(units(properties, unit));
