import { Radio } from 'antd';
import { Checkbox } from 'antd';
import Labelled from 'components/Labelled';
import React, { FunctionComponent } from 'react';
import { SharedPropertyProps } from './';

export interface BooleanProps extends SharedPropertyProps {
  value: boolean;
  display: 'radio' | 'normal';
  onChange: (value: boolean) => void;
}

const BooleanProperty: FunctionComponent<BooleanProps> = ({
  label,
  value,
  display,
  onChange,
  inline,
}) => {
  let contentMarkup = null as any;
  if (display === 'radio') {
    contentMarkup = (
      <Radio.Group onChange={ev => onChange(ev.target.value)} value={value}>
        <Radio value={true}>True</Radio>
        <Radio value={false}>False</Radio>
      </Radio.Group>
    );
  } else {
    contentMarkup = (
      <Checkbox checked={value} onChange={ev => onChange(ev.target.checked)} />
    );
  }
  return (
    <Labelled inline={inline} value={label}>
      {contentMarkup}
    </Labelled>
  );
};

export default BooleanProperty;
