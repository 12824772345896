import { supportedFiles, SupportedFileType } from '@threekit/cas';
import React, { FunctionComponent } from 'react';

export interface LabelProps {
  className?: string;
  type: SupportedFileType;
}

const Label: FunctionComponent<LabelProps> = ({ className, type }) => {
  const labels = supportedFiles[type].map(({ label }) => label);
  return (
    <section className={className}>
      Supported File Types:{' '}
      <span style={{ textTransform: 'uppercase' }}>{labels.join(', ')}</span>
    </section>
  );
};

export default Label;
