import React from 'react';
import IconBase, { SVGComponent } from './base';

const DeleteIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 20.168 21.166" {...props}>
    <path
      fill="currentColor"
      d="M10.084 21.165A10.354 10.354 0 0 1 0 10.583 10.354 10.354 0 0 1 10.084 0a10.354 10.354 0 0 1 10.084 10.583 10.354 10.354 0 0 1-10.084 10.582zm0-8.712l3.564 3.741 1.782-1.87-3.564-3.742 3.564-3.741-1.782-1.87-3.564 3.741-3.564-3.74-1.782 1.87L8.3 10.583l-3.562 3.741 1.782 1.87 3.564-3.741z"
    />
  </IconBase>
);

export default DeleteIcon;
