import cx from 'classnames';
import _ from 'lodash';
import React, {
  ComponentType,
  CSSProperties,
  MouseEventHandler,
  PureComponent,
  ReactElement,
  ReactNode,
} from 'react';
import ReactDOM from 'react-dom';
import styles from './styling.less';

export type DragRenderer = ComponentType<RendererProps>;

interface RendererProps extends DropOptions {
  data: any;
}

interface DraggableOptions {
  centered?: boolean;
  Renderer?: DragRenderer;
  className?: string;
}

export interface DropOptions {
  ctrlDown: boolean;
}

interface TargetProps {
  id?: string;
  className?: string;
  activeClassName?: string;
  acceptClassName?: string;
  draggableOptions?: DraggableOptions;
  onDrop?: (data: any, options: DropOptions) => void;
  doesAccept?: (data: any, options: DropOptions) => boolean;
  data?: any;
  type: any; // string | component
  props?: any; // props to component above (use generics?)
  // prettier-ignore
  // TODO: for some reason prettier removes the braces around the
  // function here, breaking the type definition.
  children?: ((params: Payload) => ReactElement) | ReactNode;
  el: HTMLElement;
}

interface Payload {
  active: boolean;
}

interface Point {
  x: number;
  y: number;
}

interface TargetState {
  position: Point;
  active: boolean;
}

/**
 * NOTE: by using a single data variable at the module level, means only
 * one draggable group can be enabled (dragging) at a time. I think this is
 * probably a reasonable assumption, although there may be issues on mobile,
 * with multi-touch.
 */
const transmitter = (() => {
  let _data: any = null;
  const _listeners: Array<() => void> = [];
  let _options: DropOptions = { ctrlDown: false };

  return {
    get: () => _data,
    getOptions: () => _options,
    set: (data: any, options?: DropOptions) => {
      _data = data;
      _listeners.forEach(cb => cb());
      if (options) {
        _options = options;
      }
    },
    addDataChangeListener: (callback: () => void) => {
      _listeners.push(callback);
    },
    removeDataChangeListener: (callback: () => void) => {
      const index = _listeners.indexOf(callback);
      _listeners.splice(index);
    },
  };
})();

class Dropper extends PureComponent<TargetProps, TargetState> {
  private element: HTMLDivElement | null = null;

  constructor(props: TargetProps) {
    super(props);
    this.state = { position: { x: 0, y: 0 }, active: false };
  }

  public componentDidMount() {
    document.addEventListener('mouseup', this.onPanEnd, true);
    document.addEventListener('mousemove', this.onMousePositionChange);
    transmitter.addDataChangeListener(this.update);
  }

  public componentWillUnmount() {
    document.removeEventListener('mouseup', this.onPanEnd, true);
    document.removeEventListener('mousemove', this.onMousePositionChange);
    transmitter.removeDataChangeListener(this.update);
  }

  public render() {
    const {
      className,
      id,
      type = 'li',
      props = {},
      children,
      activeClassName = '',
      acceptClassName = '',
    } = this.props;
    const { active } = this.state;

    const content =
      typeof children === 'function'
        ? // prettier-ignore
          // @ts-ignore
          children({ active })
        : children;

    const ElementType = type as any; // TODO: duh

    return (
      <>
        <ElementType
          {...props}
          draggable={true}
          onDragStart={this.onDragStart}
          ref={this.setRef}
          className={cx(styles.target, className, {
            [activeClassName]: active,
            [acceptClassName]: this.doesAccept(),
          })}
          data-id={id}
        >
          {content}
        </ElementType>
        {this.renderDraggable()}
      </>
    );
  }

  private renderDraggable = () => {
    const { draggableOptions = {}, el } = this.props;
    const { centered, Renderer, className } = draggableOptions;
    const { active } = this.state;

    if (!Renderer || !active) {
      return null;
    }

    const {
      position: { x, y },
    } = this.state;
    const { documentElement } = document;

    const style: CSSProperties = {
      top: `${y + (documentElement ? documentElement.scrollTop : 0)}px`,
      left: `${x}px`,
    };

    if (centered) {
      style.transform = 'translate(-50%, -50%)';
    }

    const data = transmitter.get();
    const { ctrlDown } = transmitter.getOptions();
    return ReactDOM.createPortal(
      <div
        className={cx(styles.target, styles.popout, className)}
        style={style}
      >
        <Renderer data={data} ctrlDown={ctrlDown} />
      </div>,
      el
    );
  };

  private update = () => this.forceUpdate();

  private setRef = (element: HTMLDivElement | null) => {
    this.element = element;
  };

  private onPanEnd = (ev: MouseEvent) => {
    const { onDrop } = this.props;
    const { active } = this.state;
    const { clientY, clientX } = ev;

    const data = transmitter.get();
    const options = transmitter.getOptions();

    if (active) {
      this.setState({ active: false });
    }

    if (!this.element || !data) {
      return;
    }

    const { top, height, left, width } = this.element.getBoundingClientRect();

    if (
      !(
        clientY > top &&
        clientY < top + height &&
        clientX > left &&
        clientX < left + width
      )
    ) {
      return;
    }

    if (!onDrop || !this.doesAccept()) {
      return;
    }

    onDrop(data, options);
    transmitter.set(null);
  };

  private doesAccept = () => {
    const { active } = this.state;
    const { doesAccept } = this.props;
    const data = transmitter.get();
    const options = transmitter.getOptions();
    return !!doesAccept && !!data && doesAccept(data, options) && !active;
  };

  private onMousePositionChange = (ev: MouseEvent) => {
    const { active } = this.state;

    if (!active) {
      return;
    }

    this.setState({ position: { x: ev.clientX, y: ev.clientY } });
  };

  private onDragStart: MouseEventHandler = ev => {
    ev.preventDefault();
    const { data } = this.props;

    if (!this.element) {
      return;
    }

    transmitter.set(data, { ctrlDown: ev.ctrlKey || ev.metaKey });
    this.setState({ active: true, position: { x: ev.clientX, y: ev.clientY } });
  };
}

export default Dropper;
