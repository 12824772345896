import { Attribute, ConfigurationType, Configurator } from '@threekit/cas';
import { connect } from '@threekit/react-redux';
import { ThreekitStore } from '@threekit/redux-store';
import { Button, Drawer } from 'antd';
import { getRenderer } from 'components/Primitives';
import { AssetValue } from 'components/Primitives/Asset';
import React, { Component } from 'react';
import styles from './styles.less';

export interface ConfiguratorInspectorAttributesProps {
  assetId: string;
  configurator?: Configurator;
  configuration?: ConfigurationType;
  showAssetConfiguration?: boolean;
  setValue: (id: string, configuration: any) => Promise<ConfigurationType>;
  addNested: (configurator: Configurator) => void;
  inline?: boolean;
}

interface StateProps {}

type ConfiguratorInspectorProps = ConfiguratorInspectorAttributesProps &
  StateProps;

class ConfiguratorInspectorAttributes extends Component<
  ConfiguratorInspectorProps
> {
  public setValue = (attributeName: string, value: any) => {
    const { configurator, assetId, configuration, setValue } = this.props;
    if (!configurator || !configuration) return;
    return setValue(assetId, {
      ...configuration,
      [attributeName]: value,
    });
  };

  public render() {
    const {
      assetId,
      configurator,
      configuration,
      inline,
      addNested,
      showAssetConfiguration,
    } = this.props;
    if (!configurator || !configuration) return null;
    const attributes = configurator.getVisibleAttributes();

    return attributes.map(attribute => {
      const {
        name,
        type,
        id,
        assetType,
        hiddenValues = [],
        values = [],
        ...rest
      } = attribute;

      const value = configuration[name];
      const onChange = (val: any) => this.setValue(name, val);
      const Property = getRenderer(type);
      const nestedConfigurator = configurator.getNestedConfigurator(attribute);

      return (
        <section key={name} className={styles.property}>
          <div className={styles.configurable}>
            <Property
              {...rest}
              searchDisabled={assetType === 'item'}
              assetType={assetType}
              name={name}
              label={name}
              type={type}
              data={configuration}
              values={values.filter(
                (value: AssetValue | string) =>
                  !hiddenValues.includes(
                    typeof value === 'string' ? value : value.assetId
                  )
              )}
              assetId={assetId}
              value={value}
              onChange={onChange}
              inline={inline}
              showAssetConfiguration={showAssetConfiguration}
            />
          </div>

          {nestedConfigurator && (
            <Button
              onClick={() => addNested(nestedConfigurator)}
              className={styles.configure}
            >
              Configure
            </Button>
          )}
        </section>
      );
    });
  }
}

const mapStateToProps = (store: ThreekitStore): StateProps => {
  return {};
};

const connected: React.ComponentType<
  ConfiguratorInspectorAttributesProps
> = connect(mapStateToProps)(ConfiguratorInspectorAttributes);

export default connected;
