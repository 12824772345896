import React, { FunctionComponent } from 'react';
import { SharedPropertyProps } from '.';

export interface ObjectProps extends SharedPropertyProps {
  value: Object;
}

const ObjectRender: FunctionComponent<ObjectProps> = ({ value }) => {
  return <span style={{ display: 'none' }}>{JSON.stringify(value)}</span>;
};

export default ObjectRender;
