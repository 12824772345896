exports = module.exports = require("../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .accordion___3dyRb {\n  width: 100%;\n}\n.threekit .icon___3K9V7 {\n  flex: 0 0 12px;\n  margin-right: 0.25em;\n  color: inherit;\n}\n.threekit .header___H08-0 {\n  color: rgba(0, 0, 0, 0.65);\n  border-bottom: solid 1px rgba(0, 0, 0, 0.65);\n  cursor: pointer;\n  font-size: 16px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  padding-bottom: 0.75em;\n  padding-top: 0.75em;\n}\n.threekit .header___H08-0:hover {\n  color: rgba(0, 0, 0, 0.45);\n}\n.threekit .header___H08-0 > * {\n  color: inherit;\n}\n.threekit .header___H08-0 h3 {\n  margin: 0;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/Accordion/styling.less"],"names":[],"mappings":"AAAA;EACE,YAAY;CACb;AACD;EACE,eAAe;EACf,qBAAqB;EACrB,eAAe;CAChB;AACD;EACE,2BAA2B;EAC3B,6CAA6C;EAC7C,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,YAAY;EACZ,uBAAuB;EACvB,oBAAoB;CACrB;AACD;EACE,2BAA2B;CAC5B;AACD;EACE,eAAe;CAChB;AACD;EACE,UAAU;CACX","file":"styling.less","sourcesContent":[":global(.threekit) .accordion {\n  width: 100%;\n}\n:global(.threekit) .icon {\n  flex: 0 0 12px;\n  margin-right: 0.25em;\n  color: inherit;\n}\n:global(.threekit) .header {\n  color: rgba(0, 0, 0, 0.65);\n  border-bottom: solid 1px rgba(0, 0, 0, 0.65);\n  cursor: pointer;\n  font-size: 16px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  width: 100%;\n  padding-bottom: 0.75em;\n  padding-top: 0.75em;\n}\n:global(.threekit) .header:hover {\n  color: rgba(0, 0, 0, 0.45);\n}\n:global(.threekit) .header > * {\n  color: inherit;\n}\n:global(.threekit) .header h3 {\n  margin: 0;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"accordion": "accordion___3dyRb",
	"icon": "icon___3K9V7",
	"header": "header___H08-0"
};