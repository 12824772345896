import { Alert } from 'antd';
import React, { Component } from 'react';
import { SharedPropertyProps } from '../';

export interface LabelProps extends SharedPropertyProps {
  defaultValue: string;
}

class Label extends Component<LabelProps> {
  public render() {
    const { defaultValue } = this.props;
    return <Alert message={defaultValue} type="info" showIcon />;
  }
}

export default Label;
