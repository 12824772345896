exports = module.exports = require("../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .root___1hdJP > *:not(:first-child) {\n  margin-top: 0.65em;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/Primitives/Plug/styles.less"],"names":[],"mappings":"AAAA;EACE,mBAAmB;CACpB","file":"styles.less","sourcesContent":[":global(.threekit) .root > *:not(:first-child) {\n  margin-top: 0.65em;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "root___1hdJP"
};