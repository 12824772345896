import { constants } from '@threekit/scene-graph';

const { UVMapType } = constants;

export function UVMap(op) {
  const useAlignmentAxis = op.uvProjectionMode !== UVMapType.Box;

  const useAutofit = op.uvProjectionMode === UVMapType.Box;

  const autoFitting = useAutofit && op.autoFit;

  const useScale = !autoFitting;
  const useCenter = !autoFitting;

  return [
    'uvProjectionMode',
    'uvMapChannel',
    'rotation',
    useAutofit && 'autoFit',
    useScale && 'mWidth',
    useScale && 'mHeight',
    useScale && 'mLength',
    useCenter && 'center',
    'uTile',
    'vTile',
    useAlignmentAxis && 'xAxis',

    'worldRelative',

    'indices',
    'faceDirection',
  ];
}
