import React from 'react';
import IconBase, { SVGComponent } from './base';

const DotsIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 14 14" {...props}>
    <circle cx="2" cy="7" r="1.5" />
    <circle cx="7" cy="7" r="1.5" />
    <circle cx="12" cy="7" r="1.5" />
  </IconBase>
);

export default DotsIcon;
