exports = module.exports = require("../../../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .property___gbM9M {\n  overflow: hidden;\n  display: flex;\n  align-items: flex-end;\n}\n.threekit .header___1h0_L {\n  font-weight: bold;\n}\n.threekit .configurable___XlqfB {\n  flex: 1;\n}\n.threekit .configurable___XlqfB .ant-select-selection {\n  border-radius: 4px 0px 0px 4px;\n}\n.threekit .configure___oQ7tl {\n  font-size: 11px;\n  padding: 0 6px;\n  border-radius: 0px 4px 4px 0px;\n}\n", "", {"version":3,"sources":["/threekit/app/src/sections/editor/containers/Configurator/Attributes/styles.less"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,sBAAsB;CACvB;AACD;EACE,kBAAkB;CACnB;AACD;EACE,QAAQ;CACT;AACD;EACE,+BAA+B;CAChC;AACD;EACE,gBAAgB;EAChB,eAAe;EACf,+BAA+B;CAChC","file":"styles.less","sourcesContent":[":global(.threekit) .property {\n  overflow: hidden;\n  display: flex;\n  align-items: flex-end;\n}\n:global(.threekit) .header {\n  font-weight: bold;\n}\n:global(.threekit) .configurable {\n  flex: 1;\n}\n:global(.threekit) .configurable :global(.ant-select-selection) {\n  border-radius: 4px 0px 0px 4px;\n}\n:global(.threekit) .configure {\n  font-size: 11px;\n  padding: 0 6px;\n  border-radius: 0px 4px 4px 0px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"property": "property___gbM9M",
	"header": "header___1h0_L",
	"configurable": "configurable___XlqfB",
	"configure": "configure___oQ7tl"
};