import React from 'react';
import IconBase, { SVGComponent } from './base';

const MouseLeftIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 30 40" fill="none" {...props}>
    <path
      d="M17.5774 11.8289V6.16778C17.5774 4.93919 16.5756 3.93945 15.3445 3.93945C14.1133 3.93945 13.1115 4.93919 13.1115 6.16778V11.8289C13.1115 13.0575 14.1133 14.0573 15.3445 14.0573C16.5756 14.0573 17.5774 13.0575 17.5774 11.8289Z"
      fill="#707070"
    />
    <path
      d="M15.948 2.79507C17.5533 3.08415 18.7845 4.48137 18.7845 6.17972V11.8409C18.7845 13.5272 17.5533 14.9364 15.948 15.2255V19.9954C20.2208 19.9713 24.1798 19.6942 27.1611 19.2124C28.006 19.0799 28.7061 18.9354 29.2854 18.7909C29.213 17.2491 29.1527 15.7194 29.0923 14.1776C28.9354 7.44444 23.3832 0.530604 16.3463 0.446289C16.2015 0.446289 16.0687 0.446289 15.948 0.446289V2.79507V2.79507Z"
      fill="#707070"
    />
    <path
      d="M29.3458 20.0078C28.7544 20.1524 28.0905 20.2728 27.3542 20.3933C24.1798 20.9112 19.9191 21.1882 15.3445 21.1882C10.7699 21.1882 6.5092 20.9112 3.33477 20.3933C2.69506 20.2848 2.10362 20.1764 1.57254 20.056C1.36735 24.6813 1.15009 29.3066 0.969036 33.9439C0.763844 41.3516 6.91958 47.7716 14.4272 47.6632C15.26 47.6632 15.6704 47.6632 16.4912 47.6632C23.8418 47.5909 30.3476 41.4841 29.9493 33.9439C29.7682 29.2945 29.551 24.6451 29.3458 20.0078Z"
      fill="#707070"
    />
    <path
      d="M3.53995 19.2006C6.50919 19.6824 10.4682 19.9594 14.7531 19.9835V15.2137C13.1477 14.9246 11.9166 13.5274 11.9166 11.8291V6.1679C11.9166 4.48159 13.1477 3.07233 14.7531 2.78324V0.446511C14.7048 0.446511 14.6444 0.446511 14.5841 0.446511C7.43858 0.338106 2.13982 7.60125 1.82599 14.1658C1.76564 15.7196 1.70529 17.2734 1.63287 18.8272C2.16396 18.9597 2.80367 19.0802 3.53995 19.2006Z"
      fill="#49D093"
    />
  </IconBase>
);

export default MouseLeftIcon;
