export function CanvasComposite(op: any) {
  const relative = op.offsetUnit === 'relative';
  const overrideRelative = op.overrideSizeUnit === 'relative';
  return [
    {
      label: 'Composite',
      keys: [
        'sourceImage',
        'opacity',
        'composition',
        'tint',
        op.tint && ['tintColor', 'tintOpacity', 'tintComposition'],
        {
          label: 'Position',
          keys: [
            'offsetUnit',
            relative
              ? ['xRelativeOffset', 'yRelativeOffset']
              : ['xOffset', 'yOffset'],
            'rotation',
            'windowed',
            op.windowed && [
              'windowBorder',
              relative
                ? ['windowRelativeWidth', 'windowRelativeHeight']
                : ['windowWidth', 'windowHeight'],
              'windowFitMode',
            ],
            'overrideSize',
            op.overrideSize && [
              'overrideSizeUnit',
              overrideRelative
                ? ['overrideWidthRelative', 'overrideHeightRelative']
                : ['overrideWidth', 'overrideHeight'],
            ],
            'horizontalAlign',
            'verticalAlign',
          ],
        },
      ],
    },
  ];
}
