import React from 'react';
import IconBase, { SVGComponent } from './base';

const CloseIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 18 19" {...props}>
    <path d="M1.91509 1.52184C1.56257 1.16932 0.991019 1.16932 0.638497 1.52184C0.285974 1.87436 0.285974 2.44592 0.638496 2.79844L16.0853 18.2452C16.4378 18.5978 17.0094 18.5978 17.3619 18.2452C17.7144 17.8927 17.7144 17.3212 17.3619 16.9687L1.91509 1.52184Z" />
    <path d="M16.0852 1.52184C16.4377 1.16932 17.0093 1.16932 17.3618 1.52184C17.7143 1.87436 17.7143 2.44592 17.3618 2.79844L1.915 18.2452C1.56248 18.5978 0.990925 18.5978 0.638403 18.2452C0.285881 17.8927 0.285881 17.3212 0.638403 16.9687L16.0852 1.52184Z" />
  </IconBase>
);

export default CloseIcon;
