import React from 'react';
import IconBase, { SVGComponent } from './base';

const TargetIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 10 10" {...props}>
    <circle cx="5" cy="5" r="4.5" stroke="currentColor" fill="none" />
    <circle cx="5" cy="5" r="3" fill="currentColor" stroke="none" />
  </IconBase>
);

export default TargetIcon;
