exports = module.exports = require("../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .label___NYipX {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  margin-right: 0.5em;\n  flex: 1;\n}\n.threekit .content___3Hwho {\n  flex: 2;\n  display: flex;\n  align-items: center;\n  margin-top: 0.5em;\n}\n.threekit .inline___3zlzM {\n  justify-content: flex-start;\n  align-items: center;\n  display: flex;\n  text-align: right;\n}\n.threekit .inline___3zlzM .content___3Hwho {\n  margin: 0;\n}\n.threekit .root___3f2vZ,\n.threekit .label___NYipX,\n.threekit .content___3Hwho {\n  width: 100%;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/Labelled/styles.less"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,wBAAwB;EACxB,iBAAiB;EACjB,oBAAoB;EACpB,QAAQ;CACT;AACD;EACE,QAAQ;EACR,cAAc;EACd,oBAAoB;EACpB,kBAAkB;CACnB;AACD;EACE,4BAA4B;EAC5B,oBAAoB;EACpB,cAAc;EACd,kBAAkB;CACnB;AACD;EACE,UAAU;CACX;AACD;;;EAGE,YAAY;CACb","file":"styles.less","sourcesContent":[":global(.threekit) .label {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  margin-right: 0.5em;\n  flex: 1;\n}\n:global(.threekit) .content {\n  flex: 2;\n  display: flex;\n  align-items: center;\n  margin-top: 0.5em;\n}\n:global(.threekit) .inline {\n  justify-content: flex-start;\n  align-items: center;\n  display: flex;\n  text-align: right;\n}\n:global(.threekit) .inline .content {\n  margin: 0;\n}\n:global(.threekit) .root,\n:global(.threekit) .label,\n:global(.threekit) .content {\n  width: 100%;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"label": "label___NYipX",
	"content": "content___3Hwho",
	"inline": "inline___3zlzM",
	"root": "root___3f2vZ"
};