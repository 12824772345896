import { Player } from '@threekit/hub-player';
import { connect } from '@threekit/react-redux';
import { ThreekitStore } from '@threekit/redux-store';
import { getPolyMeshStats } from '@threekit/scene-graph/dist/polyMesh/generic/utility/PolyMeshStats';
import React, { FunctionComponent } from 'react';
import { isStatisticsVisible } from 'sections/editor/editor';
import styles from './styling.less';

interface OwnProps {
  player: Player | null;
}

interface DispatchProps {}

interface Entry {
  value: number;
  label: string;
}

interface StateProps {
  visible: boolean;
  stats: Entry[];
}

type StatisticsProps = OwnProps & DispatchProps & StateProps;

function abbreviateNumber(num: any) {
  if (num === null) {
    return null;
  }

  if (num === 0) {
    return '0';
  }

  const b = num.toPrecision(2).split('e'); // get power
  const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions
  const c = k < 1 ? num.toFixed(0) : (num / Math.pow(10, k * 3)).toFixed(1); // divide by power
  const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
  return d + ['', 'K', 'M', 'B', 'T'][k]; // append power
}

const Statistics: FunctionComponent<StatisticsProps> = ({ visible, stats }) => {
  return (
    <ol className={styles.root} style={{ display: visible ? 'block' : 'none' }}>
      {stats.map(({ value, label }) => (
        <li key={label}>
          <span>{label}:</span>
          <span>{abbreviateNumber(value)}</span>
        </li>
      ))}
    </ol>
  );
};

const mapStateToProps = (
  state: ThreekitStore,
  { player }: OwnProps
): StateProps => {
  if (!player) {
    return { visible: false, stats: [] };
  }

  const { ids } = player.selectionSet;
  const { childrenIncluded } = getPolyMeshStats(
    state,
    ids.length === 0 ? [player.assetId] : ids
  );
  const { polygons, positions, threeTriangles, uvs } = childrenIncluded;

  return {
    visible: isStatisticsVisible(state),
    stats: [
      { value: positions, label: 'Vertices' },
      { value: threeTriangles, label: 'Triangles' },
      { value: polygons, label: 'Faces' },
      { value: uvs, label: 'UVs' },
    ],
  };
};

export default connect(mapStateToProps)(Statistics);
