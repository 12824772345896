import { connect } from '@threekit/react-redux';
import { ThreekitStore } from '@threekit/redux-store';
import { scene, sceneGraph } from '@threekit/scene-graph';
import { Cascader } from 'antd';
import React, { FunctionComponent } from 'react';
import { SharedPropertyProps } from '../';

const { getAll } = scene;

interface Option {
  value: string;
  label: string;
}

export interface PathProps extends SharedPropertyProps {
  options: Option[];
  onChange: (value: string[]) => void;
  value: string[];
  assetId: string;
  plug: string;
}

const Path: FunctionComponent<PathProps> = ({ options, onChange, value }) => (
  <Cascader
    value={value}
    options={options}
    onChange={onChange}
    placeholder="Select Node Path"
  />
);

const mapStateToProps = (store: ThreekitStore, ownProps: PathProps) => {
  const { assetId, plug, value } = ownProps;
  const query: { [key: string]: any } = {
    from: assetId,
    includeParent: true,
    property: 'name',
  };
  if (plug) query.hasPlug = plug;
  const references = getAll(store, query);
  const options = Object.keys(references).map((id: string) => {
    const { plugs } = sceneGraph.get(store, [id]);
    const label = references[id];

    const children = Object.entries(plugs).map(([key, plug]) => {
      const children = (plug as Array<{ name: string }>).map(
        ({ name }, index) => {
          const children = Object.keys((plug as any)[index]).map(key => ({
            value: key,
            label: key,
          }));

          return {
            value: String(index),
            label: name,
            children,
          };
        }
      );

      return {
        label: key,
        value: key,
        children,
      };
    });

    return {
      label: label.length > 20 ? `${label.substring(0, 17)}...` : label,
      value: id,
      children,
    };
  });

  const nodeId = value[0] as string;
  const node = nodeId ? scene.find(store, nodeId) : undefined;
  return {
    options,
    node,
  };
};

export default connect(mapStateToProps)(Path);
