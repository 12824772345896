exports = module.exports = require("../../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".threekit .number___36GCL {\n  width: 100%;\n  height: 100%;\n}\n.threekit .slider___ywuWr {\n  flex: 2;\n  margin: 0;\n  margin-left: 1em;\n}\n.threekit .inputLabel___q6KvC {\n  position: absolute;\n  right: 0.35em;\n  top: 50%;\n  transform: translateY(-50%);\n  pointer-events: none;\n  color: #29826b;\n}\n.threekit .numberWrapper___Svqat {\n  flex: 1;\n  position: relative;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/Primitives/Number/styles.less"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;CACd;AACD;EACE,QAAQ;EACR,UAAU;EACV,iBAAiB;CAClB;AACD;EACE,mBAAmB;EACnB,cAAc;EACd,SAAS;EACT,4BAA4B;EAC5B,qBAAqB;EACrB,eAAe;CAChB;AACD;EACE,QAAQ;EACR,mBAAmB;CACpB","file":"styles.less","sourcesContent":[":global(.threekit) .number {\n  width: 100%;\n  height: 100%;\n}\n:global(.threekit) .slider {\n  flex: 2;\n  margin: 0;\n  margin-left: 1em;\n}\n:global(.threekit) .inputLabel {\n  position: absolute;\n  right: 0.35em;\n  top: 50%;\n  transform: translateY(-50%);\n  pointer-events: none;\n  color: #29826b;\n}\n:global(.threekit) .numberWrapper {\n  flex: 1;\n  position: relative;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"number": "number___36GCL",
	"slider": "slider___ywuWr",
	"inputLabel": "inputLabel___q6KvC",
	"numberWrapper": "numberWrapper___Svqat"
};