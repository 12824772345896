import { connect } from '@threekit/react-redux';
import { ThreekitStore } from '@threekit/redux-store';
import { scene } from '@threekit/scene-graph';
import Select from 'components/Select';
import React from 'react';
import { SharedPropertyProps } from '../';
import Asset from '../Asset';
import styles from './styles.less';

export interface PlugProps extends SharedPropertyProps {
  plug?: string;
  assetId: string;
  assetType?: string;
}

const mapStateToProps = (
  store: ThreekitStore,
  { assetId, plug }: PlugProps
) => {
  const query =
    plug === 'Material'
      ? {
          from: assetId,
          type: 'Material',
          property: 'name',
        }
      : { from: assetId, hasPlug: plug, property: 'name' };
  const references = scene.getAll(store, query);
  const options = Object.keys(references).map((id: string) => {
    return { label: references[id], value: id };
  });

  return {
    options,
  };
};

const LocalPlug = connect(mapStateToProps)(Select);

export default function PlugDispatch(props: any) {
  const { assetType, label, value } = props;
  if (assetType) {
    const plugValue = typeof value === 'string' ? value : '';
    const assetValue = typeof value === 'string' ? {} : value;
    return (
      <div className={styles.root}>
        <Asset
          {...props}
          value={assetValue}
          showAssetConfiguration={true}
          label={`${label} Asset`}
        />
        <LocalPlug {...props} value={plugValue} label={`${label} Node`} />
      </div>
    );
  }
  return <LocalPlug {...props} />;
}
