exports = module.exports = require("../../../../.yarn/$$virtual/css-loader-virtual-d09af3d3b0/0/cache/css-loader-npm-1.0.1-f8acf2ca8e-2.zip/node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "@keyframes spin___2hs6P {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.threekit .loader___2rfTp {\n  position: absolute;\n  top: 5px;\n  right: 6px;\n  pointer-events: none;\n  user-select: none;\n  width: 22px;\n  height: 22px;\n  border: 2px solid #f3f3f3;\n  border-top: 2px solid #3498db;\n  border-radius: 50%;\n  animation: spin___2hs6P 0.5s linear infinite;\n}\n.threekit .root___2VM2j {\n  position: relative;\n  width: 100%;\n}\n", "", {"version":3,"sources":["/threekit/app/src/components/QuerySelect/styles.less"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB;GACzB;EACD;IACE,0BAA0B;GAC3B;CACF;AACD;EACE,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,0BAA0B;EAC1B,8BAA8B;EAC9B,mBAAmB;EACnB,6CAAqC;CACtC;AACD;EACE,mBAAmB;EACnB,YAAY;CACb","file":"styles.less","sourcesContent":["@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n:global(.threekit) .loader {\n  position: absolute;\n  top: 5px;\n  right: 6px;\n  pointer-events: none;\n  user-select: none;\n  width: 22px;\n  height: 22px;\n  border: 2px solid #f3f3f3;\n  border-top: 2px solid #3498db;\n  border-radius: 50%;\n  animation: spin 0.5s linear infinite;\n}\n:global(.threekit) .root {\n  position: relative;\n  width: 100%;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loader": "loader___2rfTp",
	"spin": "spin___2hs6P",
	"root": "root___2VM2j"
};