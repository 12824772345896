import { axes, helpers, Player, withPlayerVersion } from '@threekit/hub-player';
import { connect } from '@threekit/react-redux';
import { ThreekitStore, ThunkDispatch } from '@threekit/redux-store';
import { constants, scene } from '@threekit/scene-graph';
import { Vector3 } from '@threekit/three';
import Toolbar, {
  BaseTool,
  bindToolRenderer,
  BooleanTool,
} from 'components/Toolbar';
import DotsIcon from 'icons/dots';
import FilmIcon from 'icons/film';
import MaximiseIcon from 'icons/maximise';
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import {
  isStatisticsVisible,
  setStatisticsVisible,
} from 'sections/editor/editor';
import styles from './styles.less';

const { PERSPECTIVE, ORTHOGRAPHIC } = constants.Projection;

interface OwnProps {
  player: Player | null;
}

interface StateProps {
  forceRerender: any[];
  statisticsVisible: boolean;
  sceneCameras: Array<[string, string]>;
}

interface DispatchProps {
  exec: Function;
  setStatisticsVisible: (visible: boolean) => void;
}

type EditorToolbarProps = OwnProps & StateProps & DispatchProps;

interface EditorToolbarState {
  direction: Vector3;
}

const gridVisibilityLabels = {
  x: 'YZ-plane',
  y: 'XZ-plane (Ground)',
  z: 'XY-plane',
};

const helperVisibilityLabels = {
  nulls: 'Nulls',
  cameras: 'Cameras',
  lights: 'Lights',
};

const orthographicCameras = {
  Right: new Vector3(-1, 0, 0),
  Left: new Vector3(1, 0, 0),
  Bottom: new Vector3(0, 1, 0),
  Top: new Vector3(0, -1, 0),
  Back: new Vector3(0, 0, -1),
  Front: new Vector3(0, 0, 1),
};

class EditorToolbar extends Component<EditorToolbarProps, EditorToolbarState> {
  constructor(props: EditorToolbarProps) {
    super(props);
    this.state = {
      direction: orthographicCameras.Front,
    };
  }

  public render() {
    const { statisticsVisible, setStatisticsVisible, player } = this.props;

    if (!player) return <Fragment />;

    const { direction } = this.state;
    const { cameraController } = player;

    const camera = player.cameraController.getActiveCamera();
    const projection = player.cameraController.getActiveProjection();

    const selected =
      camera ||
      (projection === ORTHOGRAPHIC ? JSON.stringify(direction) : PERSPECTIVE);

    const camerasMarkup = this.renderCameras();

    return (
      <div className={styles.toolbar}>
        <Toolbar
          title="Helper Visibility"
          modalClassName={styles.toggles}
          TriggerRenderer={bindToolRenderer({ IconRenderer: DotsIcon })}
        >
          <BooleanTool
            outerOnClick={setStatisticsVisible}
            toggle={statisticsVisible}
            text="Statistics"
          />
          <hr className={styles.divider} />
          {this.renderGridVisibilityTools()}
          <hr className={styles.divider} />
          {this.renderHelperVisibilityTools()}
        </Toolbar>
        <Toolbar
          title="Framing"
          modalClassName={styles.framing}
          TriggerRenderer={bindToolRenderer({ IconRenderer: MaximiseIcon })}
        >
          <BaseTool
            outerOnClick={() => {
              cameraController.setPerspectiveFreelook();
              cameraController.frameBoundingSphere();
            }}
            text="Frame"
            subtext="f"
          />

          <BaseTool
            outerOnClick={() => {
              cameraController.setPerspectiveFreelook();
              cameraController.centerBoundingSphere();
            }}
            text="Center"
          />

          <BaseTool
            outerOnClick={() => {
              cameraController.setPerspectiveFreelook();
              cameraController.lookAtBoundingSphere();
            }}
            text="Look At"
          />
        </Toolbar>
        <Toolbar
          title="Selected Camera"
          modalClassName={styles.cameras}
          TriggerRenderer={bindToolRenderer({ IconRenderer: FilmIcon })}
          triggerClassName={styles.cameraTrigger}
          selected={selected}
          triggerShowText={true}
        >
          {camerasMarkup}
          {camerasMarkup.length > 0 && <hr className={styles.divider} />}
          <BaseTool
            outerOnClick={() => {
              cameraController.setPerspectiveFreelook();
              cameraController.frameBoundingSphere(
                undefined,
                new Vector3(-1, -1, -1)
              );
            }}
            id={PERSPECTIVE}
            text="Perspective Camera"
          />

          <hr className={styles.divider} />
          {this.renderOrthographicCameras()}
        </Toolbar>
      </div>
    );
  }

  private createOrthographicHandler = (dir: Vector3) => () => {
    const { player } = this.props;

    if (!player) {
      return;
    }

    this.setState({ direction: dir }, () => {
      const { cameraController } = player;
      cameraController.setOrthographicFreelook();
      cameraController.frameBoundingSphere(undefined, dir);
    });
  };

  private renderCameras = () =>
    this.props.sceneCameras.map(([id, name], idx) =>
      this.renderTool(
        {
          exec: (store: ThreekitStore, player: Player) =>
            player.cameraController.setActiveCamera(id),
          label: name,
          id,
        },
        idx
      )
    );

  private renderOrthographicCameras = () =>
    Object.entries(orthographicCameras).map(([text, vec]) => {
      const id = JSON.stringify(vec);
      return (
        <BaseTool
          key={id}
          id={id}
          outerOnClick={this.createOrthographicHandler(vec)}
          text={text}
        />
      );
    });

  private renderGridVisibilityTools = () => {
    const { player } = this.props;
    if (!player) {
      return null;
    }
    const { viewport } = player;
    const { gridVisibility } = viewport;
    return axes.map(axis => {
      const label = gridVisibilityLabels[axis];
      const visible = gridVisibility[axis];

      return (
        <BooleanTool
          key={axis}
          outerOnClick={(visible: boolean) =>
            viewport.setGridVisibility(axis, visible)
          }
          toggle={visible}
          text={label}
        />
      );
    });
  };

  private renderHelperVisibilityTools = () => {
    const { player } = this.props;
    if (!player) {
      return null;
    }

    const { viewport } = player;
    const { helpersVisibility } = viewport;
    return helpers.map(helper => {
      const label = helperVisibilityLabels[helper];
      const visible = helpersVisibility[helper];

      return (
        <BooleanTool
          key={helper}
          outerOnClick={(visible: boolean) =>
            viewport.setHelperVisibility(helper, visible)
          }
          toggle={visible}
          text={label}
        />
      );
    });
  };

  private renderTool = ({ label, exec, id }: any, idx: number) => (
    <BaseTool
      key={id}
      outerOnClick={() => this.props.exec(this.props.player, exec)}
      id={id}
      text={label}
    />
  );
}

const getSceneCameras = (state: ThreekitStore, player: Player | null) => {
  if (player === null) return [];

  const cameraIds = scene.filterNodes(state, {
    type: 'Camera',
    from: player.assetId,
  });

  return cameraIds.map(id => {
    const name = scene.get(state, { id, property: 'name' });
    return [id, name];
  }) as Array<[string, string]>;
};

const mapStateToProps = (
  state: ThreekitStore,
  { player }: OwnProps
): StateProps => ({
  forceRerender: [],
  statisticsVisible: isStatisticsVisible(state),
  sceneCameras: getSceneCameras(state, player),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): DispatchProps => ({
  exec: (player: Player, command: Function) =>
    dispatch((store: ThreekitStore) => command(store, player)),
  setStatisticsVisible: (visible: boolean) =>
    dispatch(setStatisticsVisible(visible)),
});

export default connect(
  withPlayerVersion(mapStateToProps),
  mapDispatchToProps
)(EditorToolbar);
