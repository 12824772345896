import React from 'react';
import IconBase, { SVGComponent } from './base';

const CheckIcon: SVGComponent = props => {
  return (
    <IconBase viewBox="0 0 12 8" {...props}>
      <path
        fill="currentColor"
        d="M2.10939 2.06693L0.666687 3.55019L4.99481 8L11.3334 1.48326L9.89065 0L4.99481 5.03347L2.10939 2.06693Z"
      />
    </IconBase>
  );
};

export default CheckIcon;
