export { default as AnnotationIcon } from './annotation';
export { default as LightBulbIcon } from './lightbulb';
export { default as DirectionalLightIcon } from './directional_light';
export { default as SphereIcon } from './sphere';
export { default as EyeDropperIcon } from './eye_dropper';
export { default as SpotLightIcon } from './spot_light';
export { default as AreaLightIcon } from './area_light';
export { default as HemisphereLightIcon } from './hemisphere_light';
export { default as CameraIcon } from './camera';
export { default as AddIcon } from './add';
export { default as CubeIcon } from './cube';
export { default as SourceIcon } from './source';
export { default as EyeIcon } from './eye';
export { default as EyeSlashIcon } from './eye_slash';
export { default as ConfigureIcon } from './configure';
export { default as FilterIcon } from './filter';
export { default as FullscreenIcon } from './fullscreen';
export { default as HelpIcon } from './help';
export { default as ShareIcon } from './share';
export { default as SceneIcon } from './scene';
export { default as ModelIcon } from './model';
export { default as EditIcon } from './edit';
export { default as EditActionIcon } from './edit_action';
export { default as UploadIcon } from './upload';
export { default as MouseLeftIcon } from './mouse_left';
export { default as MouseRightIcon } from './mouse_right';
export { default as MouseMiddleIcon } from './mouse_middle';
export { default as CloseIcon } from './close';
export { default as TouchDragIcon } from './touch_drag';
export { default as TouchPanIcon } from './touch_pan';
export { default as TouchZoomIcon } from './touch_zoom';
export { default as DotsIcon } from './dots';

import AnnotationIcon from './annotation';
import AreaLightIcon from './area_light';
import { SVGComponent } from './base';
import CubeIcon from './cube';
import DeleteIcon from './delete';
import DiamondIcon from './diamond';
import DirectionalLightIcon from './directional_light';
import EyeIcon from './eye';
import EyeDropperIcon from './eye_dropper';
import HideIcon from './eye_slash';
import GroupIcon from './group';
import HemisphereLightIcon from './hemisphere_light';
import ImageIcon from './image';
import LightBulbIcon from './lightbulb';
import NullIcon from './null';
import PolygonIcon from './polygon';
import PropertiesIcon from './properties';
import SourceIcon from './source';
import SphereIcon from './sphere';
import SpotLightIcon from './spot_light';
import TimesIcon from './times';

export const icons: { [key: string]: SVGComponent } = {
  delete: DeleteIcon,
  hide: HideIcon,
  polygon: PolygonIcon,
  source: SourceIcon,
  null: NullIcon,
  image: ImageIcon,
  eye: EyeIcon,
  times: TimesIcon,
  cube: CubeIcon,
  sphere: SphereIcon,
  dropper: EyeDropperIcon,
  properties: PropertiesIcon,
  group: GroupIcon,
  diamond: DiamondIcon,
  hemisphere_light: HemisphereLightIcon,
  spot_light: SpotLightIcon,
  directional_light: DirectionalLightIcon,
  lightbulb: LightBulbIcon,
  area_light: AreaLightIcon,
  annotation: AnnotationIcon,
};
