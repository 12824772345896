export default function (op) {
  return [
    'camera',
    {
      label: 'Virtual Reality',
      keys: ['vrMode', 'vrCamera'],
    },
    {
      label: 'Auto Rotation',
      keys: [
        'autoRotate',
        op.autoRotate && [
          'autoRotateNode',
          'autoRotateDirection',
          'autoRotateSpeed',
          'autoRotateResumeTime',
        ],
      ],
    },
    {
      label: 'SAO Pass',
      keys: [
        'saoPass',
        'saoPassIntensity',
        'saoPassRadius',
        'saoPassThreshold',
      ],
    },
    {
      label: 'Options',
      keys: ['singleAnnotationOpen', 'forceDoubleSided'],
    },
    {
      label: 'Physics',
      keys: ['gravity'],
    },
    {
      label: 'WebGL Post Effects',
      keys: ['toneMapStyle', 'toneMapExposureGain', 'toneMapWhitePoint'],
    },
    {
      label: 'V-Ray Post Effects',
      keys: ['vrayPreset'],
    },
    {
      label: 'Reflective Floor',
      keys: [
        'reflectiveFloor',
        'reflectiveFloorOpacity',
        'reflectiveFloorFresnel',
        'reflectiveFloorHeight',
      ],
    },
  ];
}
