import React from 'react';
import IconBase, { SVGComponent } from './base';

const FilmIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 14 14" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4375 5.125C9.57659 5.125 10.5 4.20159 10.5 3.0625C10.5 1.92341 9.57659 1 8.4375 1C7.29841 1 6.375 1.92341 6.375 3.0625C6.375 4.20159 7.29841 5.125 8.4375 5.125ZM10.2183 5.55429C10.9943 4.99872 11.5 4.08966 11.5 3.0625C11.5 1.37113 10.1289 0 8.4375 0C6.74613 0 5.375 1.37113 5.375 3.0625C5.375 3.91943 5.72695 4.69415 6.2942 5.25H4.28536C4.72742 4.79906 5 4.18136 5 3.5C5 2.11929 3.88071 1 2.5 1C1.11929 1 0 2.11929 0 3.5C0 4.19778 0.285875 4.8288 0.746895 5.28231C0.317165 5.3944 0 5.78516 0 6.25V13C0 13.5523 0.447715 14 1 14H9.5C9.87213 14 10.1968 13.7967 10.369 13.4952C10.4524 13.3492 10.5 13.1802 10.5 13V12.5028L11.5 12.6806L13 12.9472L14 13.125V12.1093V7.14068V6.125L13 6.30278L11.5 6.56944L10.5 6.74722V6.25C10.5 6.06985 10.4524 5.90082 10.369 5.75483C10.3272 5.68171 10.2765 5.61436 10.2183 5.55429ZM2.5 5C3.32843 5 4 4.32843 4 3.5C4 2.67157 3.32843 2 2.5 2C1.67157 2 1 2.67157 1 3.5C1 4.32843 1.67157 5 2.5 5ZM10.675 11.5182L9.5 11.3093V12.5028V13H1V6.25H9.5V6.74722V7.94068L10.675 7.73178L13 7.31846V11.9315L10.675 11.5182Z"
      fill="currentColor"
    />
  </IconBase>
);

export default FilmIcon;
