import classnames from 'classnames';
import MinusIcon from 'icons/minus';
import PlusIcon from 'icons/plus';
import React, { Component } from 'react';
import uuid from 'uuid';
import styles from './styling.less';

export interface AccordionState {
  open: boolean;
  id: string;
}

export interface AccordionProps {
  headerContent: JSX.Element;
  id: string;
  className?: string;
  theme?: 'dark' | 'light';
}

class Accordion extends Component<AccordionProps, AccordionState> {
  constructor(props: AccordionProps) {
    super(props);
    this.state = { open: true, id: uuid() };
  }

  public render() {
    const { headerContent, children, className } = this.props;
    const { open } = this.state;

    return (
      <section className={classnames([styles.accordion, className])}>
        <button className={styles.header} onClick={this.onOpenClick}>
          {this.renderIcon()}
          <h3>{headerContent}</h3>
        </button>
        <section style={{ display: open ? 'block' : 'none' }}>
          {children}
        </section>
      </section>
    );
  }

  private renderIcon = () => {
    const { open } = this.state;

    if (open) {
      return <MinusIcon className={styles.icon} />;
    }
    return <PlusIcon className={styles.icon} />;
  };

  private onOpenClick = () => this.setState(({ open }) => ({ open: !open }));
}

export default Accordion;
