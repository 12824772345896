import React from 'react';
import IconBase, { SVGComponent } from './base';

const ChevronRightIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 5.732 9.693" {...props}>
    <path
      fill="currentColor"
      d="M5.732 4.846a.793.793 0 0 0-.232-.593L.724.1A.375.375 0 0 0 .478 0a.369.369 0 0 0-.236.09A.788.788 0 0 0 0 .692V9a.788.788 0 0 0 .242.6.353.353 0 0 0 .481-.009L5.5 5.44a.793.793 0 0 0 .232-.593"
    />
  </IconBase>
);

export default ChevronRightIcon;
