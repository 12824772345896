import { ComponentType } from 'react';
import { hasKey } from 'utils/types';
import Select, { SelectProps } from '../Select';
import Array, { ArrayProps } from './Array';
import Asset, { AssetProps } from './Asset';
import AttributeRenderer, { AttributeProps } from './Attribute';
import AttributeProperty, { AttributePropertyProps } from './AttributeProperty';
import AttributeValues, { AttributeValuesProps } from './AttributeValues';
import BlurInput from './BlurInput';
import Boolean, { BooleanProps } from './Boolean';
import Color, { ColorProps } from './Color';
import Custom, { CustomProps } from './Custom';
// import File, { FileProps } from './File';
// import Image, { ImageProps } from './Image';
import Integer from './Integer';
import Label, { LabelProps } from './Label';
import Node, { NodeProps } from './Node';
import Number, { NumberProps } from './Number';
import Object, { ObjectProps } from './Object';
// import Part, { PartProps } from './Part';
import Path, { PathProps } from './Path';
import PathProperty, { PathPropertyProps } from './PathProperty';
import Plug, { PlugProps } from './Plug';
import String, { StringProps } from './String';
import Strings, { StringsProps } from './Strings';
import Vec2, { Vec2Props } from './Vec2';
import Vec3, { Vec3Props } from './Vec3';

export type PropertyType =
  | 'Array'
  | 'Asset'
  | 'Attribute'
  | 'PathProperty'
  | 'AttributeProperty'
  | 'AttributeValues'
  | 'Binary'
  | 'Vec2'
  | 'Vec3'
  | 'Plug'
  | 'Node'
  | 'Object'
  | 'Options'
  | 'Number'
  | 'Path'
  | 'String'
  | 'Strings'
  | 'Integer'
  | 'Color'
  | 'Boolean'
  | 'BlurInput'
  // | 'Part'
  | 'File'
  | 'Label'
  | 'Image'
  | 'Custom';

export interface SharedPropertyProps {
  label?: string;
  inline?: boolean;
  popout?: boolean;
  from?: string;
  data?: any;
  name?: string;
  type?: string;
  assetId?: string;
  for?: string;
}

export type PropertyProps =
  | SelectProps
  | ArrayProps
  | AssetProps
  | AttributeProps
  | AttributePropertyProps
  | AttributeValuesProps
  | BooleanProps
  | ColorProps
  | CustomProps
  | NodeProps
  | NumberProps
  | ObjectProps
  // | PartProps
  | PathProps
  | PathPropertyProps
  | PlugProps
  | StringProps
  | StringsProps
  | Vec2Props
  | Vec3Props;
// | ImageProps;
// | FileProps

const EmptyRenderer = () => null;

const properties: {
  [x in PropertyType]: ComponentType<PropertyProps>;
} = {
  Array,
  Asset,
  Attribute: AttributeRenderer,
  Binary: EmptyRenderer,
  BlurInput,
  Boolean,
  Color,
  PathProperty,
  AttributeProperty,
  AttributeValues,
  Integer,
  Node,
  Number,
  Object,
  Path,
  Plug,
  String,
  Strings,
  Vec2,
  Vec3,
  // Part,
  File: EmptyRenderer,
  Image: EmptyRenderer,
  Options: Select,
  Label,
  Custom,
};

export const getRenderer = (type: string) => {
  if (!hasKey(properties, type)) {
    throw new Error(`no primitive for type: ${type}`);
  }
  return properties[type];
};
