import { ConfigurationType } from '@threekit/cas';
import { connect } from '@threekit/react-redux';
import { ThreekitStore } from '@threekit/redux-store';
import cx from 'classnames';
import DataDropzone, { DropOptions } from 'components/DataDropzone';
import Labelled from 'components/Labelled';
import QuerySelect, { Options } from 'components/QuerySelect';
import CaretRightIcon from 'icons/caret_right';
import rootElement from 'lib/rootElement';
import React, { FunctionComponent, useState } from 'react';
import {
  Asset,
  fetchAssets,
  fetchAssetsById,
  getTags,
} from 'sections/assets/assets';
import { getActiveOrg } from 'sections/orgs/orgs';
import { SharedPropertyProps } from '../';
import String from '../String';
import Upload from '../Upload';
import styling from './styling.less';

const assetsToIds = (obj?: AssetValue | AssetValue[]) =>
  (obj ? (Array.isArray(obj) ? obj : [obj]) : []).map(value =>
    typeof value === 'string' ? value : value.assetId
  );

export interface AssetValue {
  assetId: string;
  configuration?: string;
  type?: string;
}

// TODO: type ValueType = AssetValue | string | string[];

// TODO: derive most of this interface elsewhere (duplication)
export interface AssetProps extends SharedPropertyProps {
  value?: any;
  showAssetConfiguration?: boolean;
  onChange: (value: any) => Promise<ConfigurationType | undefined | void>;
  assetType?: string;
  showFollow?: boolean;
  values?: any[];
  excludes?: string[];
  disabled?: boolean;
  mode?: 'multiple' | 'tags' | 'default';
  placeholder?: string;
  queryForTags?: boolean;
  renderTagFromInput?: boolean;
  searchDisabled?: boolean;
  id?: string;
  editing?: boolean;
  name: string;
  className?: string;
  onFetch?: (options: Options) => void;
}

interface StateProps {
  assetType?: string;
  baseUrl: string;
  doesAccept: (data: any, options: DropOptions) => boolean;
  values: any;
  fetchOptions: (query?: {
    [key: string]: any;
  }) => (store: ThreekitStore) => Promise<Options>;
  options?: Options;
}

type AssetRendererProps = AssetProps & StateProps;

const AssetRenderer: FunctionComponent<AssetRendererProps> = props => {
  const {
    label,
    value,
    inline,
    onChange,
    baseUrl,
    showFollow,
    doesAccept,
    showAssetConfiguration = false,
    assetType,
    options,
    fetchOptions,
    editing,
    mode,
    placeholder,
    id,
    values,
    name,
    className,
  } = props;

  const { assetId } = value || { assetId: '' };
  const configuration = (value && value.configuration) || '';
  const [conf, setConf] = useState(configuration);
  const [type, setType] = useState((value && value.type) || '');

  if (assetType === 'upload') {
    return (
      <Upload
        onChange={(assetId: string) => onChange({ assetId })}
        type="image"
        editing={editing}
        className={styling.image}
        value={assetId}
        label={label}
        name={name}
      />
    );
  }

  if (mode === 'multiple' || mode === 'tags') {
    return (
      <QuerySelect
        {...props}
        dirt={{ assetType, values }}
        values={[]}
        id={id}
      />
    );
  }

  return (
    <div className={cx(styling.root, className)} data-id={id}>
      <DataDropzone
        type="div"
        el={rootElement}
        onDrop={({ id }: Asset) => onChange({ assetId: id })}
        doesAccept={doesAccept}
        acceptClassName={styling.accepted}
        className={styling.target}
      >
        <Labelled value={label} inline={inline}>
          <QuerySelect
            dirt={{ assetType, values }}
            value={assetId}
            onChange={(assetId: string, { type }: { type?: string } = {}) => {
              if (type) setType(type);
              return onChange({ assetId, configuration, type });
            }}
            fetchOptions={fetchOptions}
            options={options}
            placeholder={placeholder}
            id={id}
          />

          {assetId && showFollow && (
            <a
              href={`${baseUrl}/${assetId}/edit`}
              target="_blank"
              className={styling.follow}
            >
              <CaretRightIcon />
            </a>
          )}
        </Labelled>
      </DataDropzone>

      {assetId && showAssetConfiguration && (
        <String
          value={
            typeof configuration === 'string'
              ? configuration
              : JSON.stringify(configuration)
          }
          onChange={setConf}
          placeholder="Asset JSON configuration"
          onComplete={() => {
            onChange({
              assetId,
              configuration: conf,
              type,
            });
          }}
        />
      )}
    </div>
  );
};

const tagsToOptions = (tags: string[]) =>
  tags.reduce<Options>((acc, tag) => {
    acc[tag] = { label: tag, group: 'Tags', className: styling.tag };
    return acc;
  }, {});

const mapStateToProps = (
  store: ThreekitStore,
  props: AssetProps
): StateProps => {
  const {
    onFetch,
    searchDisabled,
    value,
    mode,
    values,
    queryForTags,
    renderTagFromInput,
  } = props;
  let { assetType } = props;
  const { slug } = getActiveOrg(store);
  const baseUrl = `/o/${slug}/assets`;
  const tags = getTags(store).toJS();

  const doesAccept = ({ type }: Asset) => type === assetType;

  assetType = props.for
    ? props.for.toLowerCase()
    : assetType && assetType.toLowerCase();

  const resolveAssets = (assets: Asset[]): Options => {
    const filtered = assetType
      ? assets.filter(({ type }: any) => type === assetType)
      : assets;

    let initialState = {};

    if (queryForTags) {
      initialState = tagsToOptions(tags.map(tag => `#${tag}`));
    }

    return filtered.reduce<Options>((acc, { id, name, type }) => {
      acc[id] = {
        label: name,
        group: 'Assets',
        className: styling.asset,
        data: { type },
      };
      return acc;
    }, initialState);
  };

  return {
    assetType,
    doesAccept,
    baseUrl,
    values,
    fetchOptions: (query: any) => async (store: ThreekitStore) => {
      let results: Asset[] = [];

      if ((!values || !values.length) && !searchDisabled) {
        const resources = await store.dispatch(
          fetchAssets({
            ...query,
            type: assetType || ['model', 'material', 'scene', 'texture'],
          })
        );

        results = [...results, ...resources.results];
      }

      let ids = [...assetsToIds(values), ...assetsToIds(value)];

      const tags = tagsToOptions(ids.filter((id = '') => id.startsWith('#')));

      for (const [tag] of Object.entries(tags)) {
        const results = await store.dispatch(fetchAssetsById([tag]));
        tags[tag].children = results.map(({ id }) => id);
      }

      if (mode === 'multiple') {
        ids = ids.filter(id => !id.startsWith('#'));
      }

      const idResults = await store.dispatch(fetchAssetsById(ids));

      let options = { ...resolveAssets([...results, ...idResults]) };

      if (renderTagFromInput) {
        options = { ...tags, ...options };
      }

      if (onFetch) onFetch(options);

      return options;
    },
  };
};

export default connect(mapStateToProps)(AssetRenderer);
