import classnames from 'classnames';
import rootElement from 'lib/rootElement';
import React, { Component, ComponentType, Fragment, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import styles from './styling.less';
import { BaseTool, BaseToolProps, ToolProps } from './tools';

export { bindToolRenderer, BooleanTool, BaseTool } from './tools';

interface Dimensions {
  width: number;
  height: number;
  top: number;
  left: number;
}

type Orientation = 'top-right' | 'bottom-left';

interface PortalProps {
  className?: string;
  parentDimensions: Dimensions;
  measureRef?: (node: HTMLElement | null) => void;
  orientation?: Orientation;
  onMouseEnter?: (ev: any) => void;
  onMouseLeave?: (ev: any) => void;
}

const borderWidth = 1;

class Portal extends Component<PortalProps> {
  private el = document.createElement('div');

  public componentDidMount() {
    rootElement.appendChild(this.el);
  }

  public componentWillUnmount() {
    rootElement.removeChild(this.el);
  }

  public render() {
    const {
      className,
      children,
      measureRef,
      onMouseEnter,
      onMouseLeave,
    } = this.props;
    return ReactDOM.createPortal(
      <div
        onMouseDown={this.onMouseDown}
        ref={measureRef}
        onClick={ev => ev.stopPropagation()}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classnames([styles.modal, className])}
        style={this.getStyle()}
      >
        {children}
      </div>,
      rootElement
    );
  }

  private onMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  private getStyle = () => {
    const { parentDimensions, orientation = 'bottom-left' } = this.props;
    const { top, left, height, width } = parentDimensions;

    if (orientation === 'bottom-left') {
      return {
        left,
        top: top + height,
      };
    }

    if (orientation === 'top-right') {
      return { left: left + width + 3, top: top - borderWidth };
    }

    return {};
  };
}

interface ToolbarProps {
  TriggerRenderer?: ComponentType<BaseToolProps>;
  selected?: string;
  horizontal?: boolean;
  triggerClassName?: string;
  modalClassName?: string;
  orientation?: Orientation;
  onClick?: (ev: React.MouseEvent, keepOpen?: boolean) => void;
  onTriggerClick?: (ev: React.MouseEvent) => void;
  id?: string;
  active?: boolean;
  nested?: boolean;
  triggerShowText?: boolean;
  setOpen?: (open: boolean) => void;
  title?: string;
  disabled?: boolean;
  open?: boolean;
  position?: [number, number];
}

interface ToolbarState {
  selected: string | null;
  open: boolean;
}

function getDimensions(element: HTMLElement | [number, number] | null) {
  if (!element) {
    return { width: 0, height: 0, top: 0, left: 0 };
  }

  if (Array.isArray(element)) {
    const [left, top] = element;
    return { width: 0, height: 0, left, top };
  }

  return element.getBoundingClientRect();
}

class Toolbar extends Component<ToolbarProps, ToolbarState> {
  private trigger: HTMLElement | null;
  private modal: HTMLElement | null;
  private closeTimeout: number | null;

  constructor(props: ToolbarProps) {
    super(props);
    this.state = { selected: null, open: false };
    this.trigger = null;
    this.modal = null;
    this.closeTimeout = null;
  }

  public render() {
    const {
      orientation,
      modalClassName,
      triggerClassName,
      position,
      nested,
      horizontal,
    } = this.props;
    const triggerDimensions = getDimensions(position || this.trigger);
    const trigger = this.getTrigger();
    const open = this.isOpen();
    return (
      <Fragment>
        {!horizontal && trigger}
        {open && !horizontal && (
          <Portal
            measureRef={this.measureModal}
            className={modalClassName}
            onMouseEnter={nested ? this.onMouseEnter : undefined}
            onMouseLeave={nested ? this.onMouseLeave : undefined}
            orientation={orientation}
            parentDimensions={triggerDimensions}
          >
            {this.cloneChildren()}
          </Portal>
        )}
        {horizontal &&
          this.cloneChildren({ className: triggerClassName, horizontal: true })}
      </Fragment>
    );
  }

  public componentDidMount = () => {
    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('resize', this.onClose);
  };

  public componentWillUnmount = () => {
    window.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('resize', this.onClose);
  };

  private onClose = () => this.setOpen();

  private setOpen = (open: boolean = false) => {
    const { children, setOpen } = this.props;
    if (React.Children.toArray(children).length === 0) {
      return;
    }

    this.setState({ open }, () => setOpen && setOpen(open));
  };

  private onTriggerClick = (ev: React.MouseEvent) => {
    const { onTriggerClick, disabled } = this.props;

    if (disabled) {
      return;
    }

    const { open } = this.state;
    this.setOpen(!open);

    if (!!onTriggerClick) {
      onTriggerClick(ev);
    }
  };

  private onMouseDown = (event: MouseEvent) => {
    event.stopPropagation();
    const { nested } = this.props;

    if (
      nested ||
      !this.modal ||
      !event.target ||
      this.modal.contains(event.target as Node)
    ) {
      return;
    }

    this.setOpen(false);
  };

  private measureTrigger = (node: HTMLElement | null) => {
    this.trigger = node;
  };

  private measureModal = (node: HTMLElement | null) => {
    this.modal = node;
  };

  private isOpen = () => this.props.open || this.state.open;

  private cloneChildren = (injectedProps: any = {}) => {
    const selected = this.getSelected();
    const open = this.isOpen();
    const children = React.Children.toArray(this.props.children);
    return children.map((child, index) => {
      const { props, type } = child as { props: ToolProps; type: string }; // TODO: see comment about "magic" in tools.tsx
      const { id } = props;

      if (typeof type === 'string') {
        return child;
      } else if (type === Toolbar) {
        return React.cloneElement(child as ReactElement<ToolbarProps>, {
          orientation: 'top-right',
          nested: true,
          setOpen: this.props.setOpen,
        });
      }

      return React.cloneElement(child as ReactElement<ToolProps>, {
        active: selected === id,
        onClick: this.createSelectedHandler(id),
        open,
        selectable: typeof selected === 'string',
        ...injectedProps,
      });
    });
  };

  private getSelected = () => this.props.selected || this.state.selected;

  private createSelectedHandler = (selected?: string) => (
    ev: React.MouseEvent,
    keepOpen?: boolean
  ) => {
    const open = this.isOpen();

    this.setState({ selected: selected || null });
    this.setOpen(keepOpen === undefined ? !open : keepOpen);

    const { onClick } = this.props;
    if (onClick) {
      onClick(ev, keepOpen);
    }
  };

  private onMouseEnter = (ev: React.MouseEvent) => {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
    this.setState({ open: true });
  };

  private onMouseLeave = (ev: React.MouseEvent) => {
    this.closeTimeout = window.setTimeout(
      () => this.setState({ open: false }),
      500
    ); // TODO: why does setTimeout typed to return NodeJS.Timeout?
  };

  private getTrigger = () => {
    const {
      TriggerRenderer = BaseTool,
      triggerShowText,
      triggerClassName,
      disabled,
      active,
      nested,
      title,
    } = this.props;
    const open = this.isOpen();
    const selected = this.getSelected();
    const children = React.Children.toArray(this.props.children);

    const triggerProps = nested
      ? {
          onMouseEnter: this.onMouseEnter,
          onMouseLeave: this.onMouseLeave,
          measureRef: this.measureTrigger,
          nested: true,
        }
      : {
          measureRef: this.measureTrigger,
          onClick: this.onTriggerClick,
          isTrigger: true,
          open,
          className: triggerClassName,
          active,
          triggerShowText,
        };

    const child = children.find(child => {
      const { props } = child as { props: ToolProps }; // TODO: see comment about "magic" above
      return props.id === selected;
    });

    const content = child ? (child as any).props.text : null;
    return (
      <TriggerRenderer
        disabled={disabled}
        title={title}
        {...triggerProps}
        text={content || ''}
      />
    );
  };
}

export default Toolbar;
