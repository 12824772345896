import { Typography } from 'antd';
import cx from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './styles.less';

export interface LabelledProps {
  value?: string;
  className?: string;
  inline?: boolean;
}

const Labelled: FunctionComponent<LabelledProps> = ({
  children,
  value,
  className,
  inline,
}) => {
  if (!value) {
    return <>{children}</>;
  }

  return (
    <label className={cx(styles.root, className, { [styles.inline]: inline })}>
      <Typography.Text className={styles.label}>{value}</Typography.Text>
      <div className={styles.content}>{children}</div>
    </label>
  );
};

export default Labelled;
