import Reference from './Reference';
import Physical from './Physical';
import { TilingOverride } from './TilingOverride';
import Triplanar from './Triplanar';
import VraySubdiv from './VraySubdiv';
import VrayTemplate from './VrayTemplate';
import VrayMtlWrapper from './VrayMtlWrapper';
export default {
  Reference,
  Physical,
  TilingOverride,
  Triplanar,
  'vray/SubdivDispl': VraySubdiv,
  'vray/Wrapper': VrayMtlWrapper,
  // SEE: https://threekit.slack.com/archives/CCZPEQH6K/p1595945367400900?thread_ts=1595886200.394700&cid=CCZPEQH6K
  // 'vray/Template': VrayTemplate,
};
