import { constants } from '@threekit/scene-graph';

const { ScaleMode } = constants;

export function UVTransform(op) {
  return [
    'uvMapChannel',
    'translation',
    'rotation',
    'scaleMode',
    op.scaleMode === ScaleMode.Tiling ? 'tiling' : 'scale',
    'pivotCenter',
  ];
}
