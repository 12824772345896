import React, { FunctionComponent, MouseEvent, useRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';

interface CanvasContainerProps {
  className?: string;
  onMouseDown?: (ev: any) => void;
  onCanvasResize: (el: HTMLElement | null) => void;
}

function ignoreRightClick(ev: any) {
  ev.preventDefault();
}

const CanvasContainer: FunctionComponent<CanvasContainerProps> = ({
  onCanvasResize,
  children,
  className,
  onMouseDown,
}) => {
  const ref = useRef(null);
  return (
    <div
      onMouseDown={onMouseDown}
      className={className}
      ref={ref}
      onContextMenu={ignoreRightClick}
    >
      <ReactResizeDetector
        refreshMode="debounce"
        refreshRate={50}
        handleWidth={true}
        handleHeight={true}
        onResize={() => onCanvasResize(ref.current)}
      />
      {children}
    </div>
  );
};

export default CanvasContainer;
