import React from 'react';
import IconBase, { SVGComponent } from './base';

const MaximiseIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 14 14" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.625 1H12C12.5523 1 13 1.44772 13 2V4.375H14V2C14 0.895431 13.1046 0 12 0H9.625V1ZM4.375 1V0H2C0.895431 0 0 0.89543 0 2V4.375H1V2C1 1.44772 1.44772 1 2 1H4.375ZM1 9.625H0V12C0 13.1046 0.89543 14 2 14H4.375V13H2C1.44772 13 1 12.5523 1 12V9.625ZM9.625 13V14H12C13.1046 14 14 13.1046 14 12V9.625H13V12C13 12.5523 12.5523 13 12 13H9.625Z"
      fill="currentColor"
    />
  </IconBase>
);

export default MaximiseIcon;
