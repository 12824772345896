import {
  ConfigurationType,
  supportedFiles,
  SupportedFileType,
} from '@threekit/cas';
import { connect } from '@threekit/react-redux';
import { ThunkDispatch } from '@threekit/redux-store';
import { filesApiRoot } from 'conf';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { uploadAsset } from 'sections/assets/assets';
import { Omit } from 'utils/types';
import FileRenderer, { FileValue } from '../File';
import Image, { ImageProps } from './Image';
import Label, { LabelProps } from './Label';

export type TypeProps = ImageProps;

interface OwnProps extends TypeProps, Omit<LabelProps, 'type'> {
  editing?: boolean;
  type: SupportedFileType;
  onChange: (assetId: string) => Promise<ConfigurationType | undefined | void>;
  value: string;
  label?: string;
  name: string;
}

interface DispatchProps {
  upload: (files: File[]) => Promise<string>;
  resolveFile: (assetId: string | null) => Promise<FileValue | null>;
}

interface StateProps {}

type UploadProps = OwnProps & DispatchProps & StateProps;

const renderers = {
  image: Image,
};

const Upload: FunctionComponent<UploadProps> = props => {
  const { editing, upload, className, type, label, value } = props;

  if (editing) {
    return <Label type={type} className={className} />;
  }

  const [file, setFile] = useState<FileValue | null>(null);

  const onChange = async (assetId: string | null) => {
    const file = await props.resolveFile(assetId);
    setFile(file);
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  const Renderer = renderers[type];
  const acceptedTypes = supportedFiles[type].map(({ type }) => type);

  return (
    <FileRenderer
      file={file}
      uploadFiles={upload}
      acceptedTypes={acceptedTypes}
      onChange={onChange}
      label={label}
    >
      <Renderer {...props} />
    </FileRenderer>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { onChange, name }: OwnProps
): DispatchProps => ({
  upload: async (files: File[]) => dispatch(uploadAsset(files)),

  resolveFile: async (assetId: string | null) => {
    const config = (await onChange(assetId || '')) || {};
    const value = config[name];

    return dispatch(async store => {
      const { nodes } = store.get('sceneGraph');
      const node = nodes[value.assetId];

      if (!node) {
        return null;
      }

      const file = node.plugs.Image[0].originalBitmapFile;
      return {
        src: `${filesApiRoot}/files/hash/${file.hash}`,
        name: file.filename,
      };
    });
  },
});

const ConnectedUpload = connect(
  null,
  mapDispatchToProps
)(Upload);

export default ConnectedUpload;
