import { Color } from '@threekit/three-math';
import ContextModal, { TriggerProps } from 'components/ContextModal';
import Labelled from 'components/Labelled';
import rootElement from 'lib/rootElement';
import React, { Component } from 'react';
import { MouseEvent } from 'react';
import { ColorState, PhotoshopPicker } from 'react-color';
import { SharedPropertyProps } from '../';
import styles from './styling.less';

interface ColorPickerProps {
  onClose: (event: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onChange: (color: string) => void;
  color: string;
}

const PickerComponent = PhotoshopPicker as any;

class ColorPicker extends Component<ColorPickerProps> {
  private initialColor: string;

  constructor(props: ColorPickerProps) {
    super(props);
    this.initialColor = props.color;
  }

  public render() {
    const { onClose, color } = this.props;

    return (
      <div className={styles.picker}>
        <PickerComponent
          color={color}
          onChangeComplete={this.onChange}
          onCancel={this.onCancel}
          onAccept={onClose}
        />
      </div>
    );
  }

  private onChange = (color: ColorState) => {
    const { onChange } = this.props;
    onChange(color.hex);
  };

  private onCancel = (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { onClose, onChange } = this.props;
    onChange(this.initialColor);
    onClose(event);
  };
}

export interface ColorProps extends SharedPropertyProps {
  onChange: (color: string) => void;
  value?: string | Color;
}

class ColorComponent extends Component<ColorProps> {
  public render() {
    const { label, onChange, inline, popout } = this.props;

    const value = this.getHexValue();

    const Trigger = ({ setRef, onToggleModal }: TriggerProps) => (
      <Labelled value={label} inline={inline}>
        <div
          ref={setRef}
          onClick={onToggleModal}
          className={styles.swatch}
          style={{
            backgroundColor: value,
            color: this.makeContrastingColour(value),
          }}
        >
          {value}
        </div>
      </Labelled>
    );

    return (
      <ContextModal el={rootElement} Trigger={Trigger} withoutModal={!popout}>
        {({ onClose }) => (
          <ColorPicker color={value} onClose={onClose} onChange={onChange} />
        )}
      </ContextModal>
    );
  }

  private getHexValue = () => {
    const { value } = this.props;

    if (!value) {
      return '#ffffff';
    }

    if (typeof value === 'string') {
      return value;
    }

    return `#${value.getHexString()}`;
  };

  private makeContrastingColour(col: string) {
    // Convert from '#rrggbb' to decimal
    const r = parseInt(col.substr(1, 2), 16) / 255;
    const g = parseInt(col.substr(3, 2), 16) / 255;
    const b = parseInt(col.substr(5, 2), 16) / 255;

    // Return black or white, depending on average
    const avg = (r + g + b) / 3;
    return avg > 0.5 ? '#000000' : '#ffffff';
  }
}

export default ColorComponent;
