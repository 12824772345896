export default function(op) {
  return [
    'radiusTop',
    'radiusBottom',
    'height',
    'radiusSegments',
    'heightSegments',
    'openEnded',
  ];
}
