import { constants } from '@threekit/scene-graph';
export function CanvasText(op: any) {
  const isRelative = op.positionUnit === 'relative';
  return [
    {
      label: 'Text',
      keys: [
        'text',
        'textNumber',
        'fontType',
        op.fontType === constants.FontTypes.custom && 'fontCSSSpecifier',
        op.fontType === constants.FontTypes.fontFile && 'fontFile',
        'fontSize',
        'autoDownsize',
        {
          label: 'Positioning',
          keys: [
            'multiLine',
            'justify',
            'headSpace',
            'endSpace',
            'positionUnit',
            isRelative
              ? ['positionXRelative', 'positionYRelative']
              : ['positionX', 'positionY'],
            'windowed',
            op.windowed && [
              'windowBorder',
              isRelative
                ? ['windowRelativeWidth', 'windowRelativeHeight']
                : ['windowWidth', 'windowHeight'],
            ],
            'rotation',
            'horizontalAlign',
            'verticalAlign',
            'curve',
            op.curve && [
              'svgPath',
              'svgWidth',
              'svgHeight',
              'svgAlign',
              'pathStartOffset',
            ],
          ],
        },
        {
          label: 'Style',
          keys: [
            'color',
            'opacity',
            'italic',
            'bold',
            'style',
            op.style == 'outline' && 'outlineWidth',
            'shadow',
            op.shadow && [
              'shadowThickness',
              'shadowColor',
              'shadowOffsetX',
              'shadowOffsetY',
            ],
          ],
        },
      ],
    },
  ];
}
