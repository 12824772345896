import React, { FunctionComponent } from 'react';
import { SharedPropertyProps } from '../';
import Select from '../../Select';

export interface StringsProps extends SharedPropertyProps {
  value: string[];
  onChange: (value: string[]) => void;
}

const Strings: FunctionComponent<any> = props => (
  <Select {...props} mode="tags" />
);

export default Strings;
