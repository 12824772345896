export default function (op) {
  return [
    'visible',
    'arExportable',
    'selectable',
    'discardable',
    'doubleSided',
    'interactive',
    '--- Shadow',
    'castShadow',
    'receiveShadow',
    '--- VR',
    'walkableInVR',
    {
      label: 'VRay Properties',
      keys: ['vrayObjectId'],
    },
    {
      label: 'Mesh Display',
      keys: ['prefetch', 'layer'],
    },
  ];
}
