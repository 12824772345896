import { Attribute } from '@threekit/cas';
import { placeholder } from 'components/Markup';
import Select from 'components/Select';
import React, { FunctionComponent } from 'react';
import { SharedPropertyProps } from './';
import Asset from './Asset';

export interface AttributeValuesProps extends SharedPropertyProps {
  onChange: any;
  value: any;
  attributes: Attribute[];
  name: string;
}

const AttributeValues: FunctionComponent<AttributeValuesProps> = ({
  onChange,
  value,
  attributes,
  from,
  data,
  name,
}) => {
  if (!data || !from || !attributes) {
    return null;
  }

  const attributeId = data[from];
  const attribute = attributes.find(({ id }) => attributeId === id);

  if (!attribute) {
    return placeholder;
  }

  const { values, type, assetType } = attribute;
  if (!values || values.length === 0) {
    return placeholder;
  }

  if (type === 'Asset') {
    return (
      <Asset
        mode="multiple"
        onChange={onChange}
        value={value}
        values={values}
        name={name}
        assetType={assetType}
        renderTagFromInput={true}
      />
    );
  }

  return (
    <Select mode="tags" values={values} onChange={onChange} value={value} />
  );
};

export default AttributeValues;
