import React from 'react';
import IconBase, { SVGComponent } from './base';

const FrameIcon: SVGComponent = props => (
  <IconBase viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 0H0V32H32V0ZM29.8182 2.18182H2.18182V29.8182H29.8182V2.18182Z"
      fill="currentColor"
    />
    <path d="M3 23.1818V29H29L13.1111 13L3 23.1818Z" fill="currentColor" />
    <path
      d="M21.0556 20.2727L29 28.2727V20.2727L25.0278 16.2727L21.0556 20.2727Z"
      fill="currentColor"
    />
    <circle cx="20.7273" cy="8.36363" r="2.54545" fill="currentColor" />
  </IconBase>
);

export default FrameIcon;
