import React from 'react';
import IconBase, { SVGComponent } from './base';

const CaretRight: SVGComponent = props => (
  <IconBase viewBox="0 0 31.25 31.248" {...props}>
    <path
      d="M31.25 15.624a15.625 15.625 0 1 0-15.624 15.624A15.642 15.642 0 0 0 31.25 15.624zm-16.769 0l6.431 6.43-2.762 2.762-9.193-9.192 9.194-9.193 2.762 2.762-6.431 6.429z"
      fill="currentcolor"
      transform="rotate(180 15.625 15.624)"
    />
  </IconBase>
);

export default CaretRight;
