import { getConfigurator, Operator } from '@threekit/cas';
import { Player } from '@threekit/hub-player';
import { connect } from '@threekit/react-redux';
import { ThreekitStore, ThunkDispatch } from '@threekit/redux-store';
import {
  operators as allOperators,
  scene,
  sceneGraph,
} from '@threekit/scene-graph';
import {
  getAssetInstance,
  SceneGraphOperator,
} from '@threekit/scene-graph/dist/sceneGraph';
import { Button, Dropdown, Menu, Radio } from 'antd';
import Accordion from 'components/Accordion';
import Draggable from 'components/Draggable';
import { getRenderer } from 'components/Primitives';
import StringProperty from 'components/Primitives/String';
import DeleteIcon from 'icons/delete';
import DragIcon from 'icons/drag';
import rootElement from 'lib/rootElement';
import _ from 'lodash';
import uiOperators from 'operators';
import React, { Component, Fragment, FunctionComponent, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import move from 'utils/move';
import styles from './styling.less';

function hasKey<O>(obj: O, key: string | number | symbol): key is keyof O {
  return key in obj;
}

interface PropertyProps extends RouteComponentProps {
  schema: any;
  onChange: (val: any) => void;
  op: any;
  propertyKey: string;
  path: Array<string | number>;
}

interface PropertyStateProps {
  value: any;
}

interface PropertyDispatchProps {}

const { MetaData, Tags } = allOperators.Properties;

const operatorModifications: { [x: string]: any } = {
  Properties: {
    MetaData,
    Tags,
  },
};

const Property: FunctionComponent<
  PropertyProps & PropertyStateProps & PropertyDispatchProps
> = ({ schema, value, onChange, match, path, op }) => {
  if (!schema || schema.hidden) {
    return null;
  }

  const Prop = getRenderer(schema.type);
  const { assetId } = match.params as { assetId: string };
  return (
    <section className={styles.property}>
      <Prop
        {...schema}
        showFollow={true}
        showSlider={true}
        value={value}
        onChange={onChange}
        assetId={assetId}
        path={path}
        op={op}
        inline={true}
      />
    </section>
  );
};

const mapPropertyStateToProps = (
  state: ThreekitStore,
  ownProps: PropertyProps,
  store: ThreekitStore
): PropertyStateProps => {
  /**
   * TODO: so this is obviously a bit silly but the general idea is that we want to derive state for this propery as low as possible
   * so that only the component that needs this state change re-renders.
   */
  const { op, propertyKey } = ownProps;
  return { value: op[propertyKey] };
};

const ConnectedProperty = withRouter<PropertyProps, any>(
  connect(
    mapPropertyStateToProps,
  )(Property)
);

interface PropertiesOwnProps {
  operator: any;
  plugName: string;
  op: any;
  path: Array<string | number>;
  onChange: (key: string, value: any) => void;
}

interface PropertiesStateProps {
  keys: PropertiesType[];
}

type PropertiesProps = PropertiesOwnProps & PropertiesStateProps;

type PropertiesType =
  | string
  | { label: string; keys: string[] }
  | string[]
  | undefined;

class Properties extends Component<PropertiesProps> {
  public shouldComponentUpdate(nextProps: PropertiesProps) {
    const nextKeys = this.flattenKeys(nextProps.keys);
    const prevKeys = this.flattenKeys(this.props.keys);

    return !_.isEqual(prevKeys, nextKeys) || this.props.op !== nextProps.op;
  }

  public render() {
    const { keys } = this.props;
    return <>{keys.map(this.renderProp)}</>;
  }

  private flattenKeys(keys: PropertiesType[]) {
    return keys.reduce<string[]>((acc, key) => {
      if (!key) {
        return acc;
      }

      if (typeof key === 'string') {
        acc.push(key);
        return acc;
      }

      if (Array.isArray(key)) {
        return [...acc, ...key];
      }

      return [...acc, ...key.keys];
    }, []);
  }

  private renderProp = (opkey: PropertiesType, idx: number) => {
    const { op, operator, path, onChange } = this.props;
    if (!opkey) return null;

    if (typeof opkey === 'string') {
      return (
        <ConnectedProperty
          key={`opkey-${opkey}-${idx}`}
          propertyKey={opkey}
          op={op}
          schema={operator.getSchema(op, opkey)}
          onChange={(val: any) => onChange(opkey, val)}
          path={path.concat([opkey])}
        />
      );
    }

    if (Array.isArray(opkey)) {
      return (
        <Fragment key={`array-${idx}`}>{opkey.map(this.renderProp)}</Fragment>
      );
    }

    const { label, keys } = opkey;
    return (
      <Accordion
        key={`accordion-${idx}`}
        id={'' + idx}
        headerContent={<Fragment>{label}</Fragment>}
      >
        {keys.map(this.renderProp)}
      </Accordion>
    );
  };
}

// TODO: so this connect is pointless, we are not actually accessing redux state, we just do this because any state change will result
// in the mapStateToProps being evaluated, so we can compare the keys prop within shouldComponentUpdate.

const ConnectedProperties = connect(
  (state: ThreekitStore, { plugName, op, operator }: PropertiesOwnProps) => {
    const uiPlug = hasKey(uiOperators, plugName) && uiOperators[plugName];
    const name = op.type || op.name;
    const uiOp = (uiPlug && hasKey(uiPlug, name) ? uiPlug[name] : false) as any;
    const uiResult = uiOp && uiOp(op);

    const keys = (
      uiResult ||
      operator.getKeys(op) ||
      []
    ).filter((key: string | string[]) =>
      typeof key === 'string' ? !!operator.props[key] : true
    );

    return {
      keys,
    };
  }
)(Properties);

interface OperatorProps extends RouteComponentProps {
  op: SceneGraphOperator;
  nodeId: string;
  index: number;
  plugName: string;
  player: Player;
  playerV: number;
}

interface OperatorStateProps {
  operator: any;
}

interface OperatorDispatchProps {
  onPropertyChange: (path: sceneGraph.Path, value: any) => void;
}

export const OperatorRenderer: FunctionComponent<
  OperatorProps & OperatorStateProps & OperatorDispatchProps
> = ({ onPropertyChange, op, plugName, operator, nodeId, index }) => {
  const path = [nodeId, 'plugs', plugName, index];

  const onChange = (key: string, value: any) => {
    onPropertyChange(path.concat([key]), value);
  };

  return (
    <div className={styles.operator}>
      <ConnectedProperties
        plugName={plugName}
        operator={operator}
        op={op}
        path={path}
        onChange={onChange}
      />
    </div>
  );
};

const mapOperatorStateToProps = (
  state: ThreekitStore,
  { plugName, nodeId, index, player, op }: OperatorProps
): OperatorStateProps => {
  const [
    data,
    i,
    schema,
    operator,
    pname,
  ] = sceneGraph.findOp(player.sceneGraph, [nodeId, 'plugs', plugName, index]);

  return { operator };
};

export const mapOperatorDispatchToProps = (
  dispatch: ThunkDispatch
): OperatorDispatchProps => ({
  onPropertyChange: (path: sceneGraph.Path, value: any) => {
    dispatch(store => {
      if (value && value.configuration) {
        const { configuration } = value;
        const config =
          typeof configuration === 'string'
            ? JSON.parse(configuration)
            : configuration;
        const assetRef = getAssetInstance(store, path);
        const configurator = getConfigurator(store, assetRef);
        if (configurator) {
          configurator.setConfiguration(config);
        }
      }
      return store.dispatch(sceneGraph.set(path, value));
    });
  },
});

const ConnectedOperator = withRouter<OperatorProps, any>(
  connect(
    mapOperatorStateToProps,
    mapOperatorDispatchToProps
  )(OperatorRenderer)
);

interface OwnPlugProps {
  plugName: string;
  nodeId: string;
  player: Player;
  playerV: number;
}

interface PlugStateProps {
  operators: SceneGraphOperator[];
  forceRerender: any[];
}

interface PlugDispatchProps {
  addOperator: (
    type: string,
    operators: SceneGraphOperator[]
  ) => Promise<SceneGraphOperator>;
  removeOperator: (index: number) => void;
  swapOperators: (
    path: [number, number],
    operators: SceneGraphOperator[]
  ) => void;
}

type PlugProps = OwnPlugProps & PlugStateProps & PlugDispatchProps;

const Plug: FunctionComponent<PlugProps> = ({
  operators,
  plugName,
  nodeId,
  player,
  addOperator,
  removeOperator,
  swapOperators,
}) => {
  const availableOperators = Object.entries({
    ...(operatorModifications[plugName] || allOperators[plugName]),
  })
    .filter(
      ([name, operator]: [string, Operator]) =>
        name !== plugName && !operator.selfProperties
    )
    .map(([name, { def: { label } }]: [string, Operator]) => ({
      name,
      label: label || name,
    }));

  let [selectedOperator, setSelectedOperator] = useState(operators[0]);

  if (!operators.includes(selectedOperator)) {
    selectedOperator = operators[0];
  }

  const selectedOperatorIndex = operators.indexOf(selectedOperator);

  return (
    <Accordion
      className={styles.plug}
      key={`accordion-${plugName}`}
      id={plugName}
      headerContent={<Fragment>{plugName}</Fragment>}
    >
      {availableOperators.length > 0 && (
        <Dropdown
          overlay={
            <Menu
              className={styles.operatorMenu}
              onClick={async ({
                item: {
                  props: { title },
                },
              }) => {
                await addOperator(title, operators);
                setSelectedOperator(operators[operators.length - 1]);
              }}
            >
              {availableOperators.map(({ name, label }, index) => (
                <Menu.Item key={`${name}-${index}`} title={name}>
                  {label}
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={['click']}
        >
          <Button className={styles.addOperator}>Add Operator</Button>
        </Dropdown>
      )}
      {operators.length > 1 && (
        <Radio.Group
          className={styles.operators}
          onChange={({ target: { value } }) => setSelectedOperator(value)}
          value={selectedOperator}
        >
          <Draggable.Group
            mode="reorder"
            type="div"
            onComplete={([source, destination]) =>
              swapOperators([Number(source), Number(destination)], operators)
            }
            restrictions={{ '0': { accept: true, reparent: true } }}
          >
            {operators.map((operator, index) => {
              const { type, name } = operator;
              const op = sceneGraph.lookupOperator(plugName, type);
              const label = (op && op.def.label) || name;

              return (
                <Draggable.Target
                  className={styles.target}
                  id={String(index)}
                  el={rootElement}
                  type="div"
                  key={index}
                >
                  {({ eventHandlers: { onPanStart } }) => (
                    <Radio.Button
                      className={styles.operatorRadio}
                      value={operator}
                    >
                      <span>
                        {index !== 0 && operators.length > 2 && (
                          <span
                            className={styles.handle}
                            draggable={true}
                            onDragStart={onPanStart}
                          >
                            <DragIcon />
                          </span>
                        )}
                        <span>{label}</span>
                      </span>
                      {index === 0 ? (
                        <span />
                      ) : (
                        <button
                          className={styles.deleteOperator}
                          onClick={async (ev) => {
                            ev.preventDefault();
                            removeOperator(index);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                    </Radio.Button>
                  )}
                </Draggable.Target>
              );
            })}
          </Draggable.Group>
        </Radio.Group>
      )}

      <ConnectedOperator
        key={`${nodeId}-${plugName}-${selectedOperatorIndex}`}
        nodeId={nodeId}
        op={selectedOperator as SceneGraphOperator}
        index={selectedOperatorIndex}
        plugName={plugName}
        player={player}
        playerV={player.v}
      />
    </Accordion>
  );
};

const mapPlugStateToProps = (
  state: ThreekitStore,
  { nodeId, plugName }: OwnPlugProps
): PlugStateProps => {
  const { plugs } = scene.get(state, [nodeId]);
  const operators = plugs[plugName];
  return { operators, forceRerender: [] };
};

const mapPlugDispatchToProps = (
  dispatch: ThunkDispatch,
  { nodeId, plugName }: OwnPlugProps
): PlugDispatchProps => {
  const basePath = [nodeId, 'plugs', plugName];
  return {
    addOperator: (type, operators) =>
      dispatch(
        sceneGraph.add({
          path: [...basePath, operators.length],
          value: { name: type, type },
          type: 'Operator',
        })
      ),
    removeOperator: (operatorIndex) =>
      dispatch(sceneGraph.deletePath([...basePath, operatorIndex])),
    swapOperators: ([source, destination], operators) =>
      dispatch(scene.set(basePath, move([...operators], source, destination))),
  };
};

const ConnectedPlug = connect(
  mapPlugStateToProps,
  mapPlugDispatchToProps
)(Plug);

interface NodeOwnProps {
  nodeId: string;
  player: Player;
  playerV: number;
}

interface NodeStateProps {
  name: string;
  plugs: any;
}

interface NodeDispatchProps {
  onNameChange: (s: string) => void;
}

type NodeProps = NodeOwnProps & NodeStateProps & NodeDispatchProps;

const Node: FunctionComponent<NodeProps> = ({
  nodeId,
  name,
  plugs,
  onNameChange,
  player,
}) => {
  return (
    <li className={styles.node}>
      <StringProperty
        label="Name"
        value={name}
        onChange={onNameChange}
        inline={true}
      />
      {Object.keys(plugs).map((plugName, idx) => (
        <ConnectedPlug
          key={`${nodeId}-${plugName}-${idx}`}
          plugName={plugName}
          nodeId={nodeId}
          player={player}
          playerV={player.v}
        />
      ))}
    </li>
  );
};

const mapNodeStateToProps = (
  state: ThreekitStore,
  ownProps: NodeOwnProps
): NodeStateProps => {
  const { nodeId } = ownProps;
  const { name, plugs } = scene.get(state, [nodeId]);
  return {
    name,
    plugs,
  };
};

const mapNodeDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: NodeOwnProps
): NodeDispatchProps => {
  const { nodeId } = ownProps;
  return {
    onNameChange: (value: string) => {
      dispatch(sceneGraph.set([nodeId, 'name'], value));
    },
  };
};

const ConnectedNode = connect(
  mapNodeStateToProps,
  mapNodeDispatchToProps
)(Node);

export interface EditorProps {
  player: Player;
}

interface EditorDispatchProps {}

interface EditorStateProps {
  selectedIds: string[];
}

interface OwnState {}

const EditorInspector: FunctionComponent<
  EditorProps & EditorDispatchProps & EditorStateProps
> = ({ player, selectedIds }) => {
  if (selectedIds.length > 1) {
    return (
      <span className={styles.message}>Select a single node to edit...</span>
    );
  }

  return (
    <ol className={styles.root}>
      {selectedIds.map((id) => (
        <ConnectedNode
          key={id}
          nodeId={id}
          player={player}
          playerV={player.v}
        />
      ))}
    </ol>
  );
};

const mapStateToProps = (
  state: ThreekitStore,
  ownProps: EditorProps
): EditorStateProps => {
  const { player } = ownProps;
  const selectedIds = player.selectionSet.ids;
  return {
    selectedIds,
  };
};

export default connect(mapStateToProps)(EditorInspector);
