import { Attribute } from '@threekit/cas';
import { placeholder } from 'components/Markup';
import { getRenderer } from 'components/Primitives';
import React, { FunctionComponent } from 'react';
import { SharedPropertyProps } from './';

export interface AttributePropertyProps extends SharedPropertyProps {
  attributes: Attribute[];
}

const AttributeProperty: FunctionComponent<AttributePropertyProps> = props => {
  const { attributes, from, data } = props;
  if (!from || !data || !attributes) {
    return null;
  }

  const attributeId = data[from];
  const attribute = attributes.find(({ id }) => attributeId === id);

  if (!attribute) {
    return placeholder;
  }

  const { type, assetType } = attribute;
  const Prop = getRenderer(type);

  return (
    <Prop
      {...props}
      {...attribute}
      name={'value'}
      searchDisabled={assetType === 'item'}
    />
  );
};

export default AttributeProperty;
