import { Select } from 'antd';
import cx from 'classnames';
import Labelled from 'components/Labelled';
import React, { FunctionComponent, useState } from 'react';
import { SharedPropertyProps } from '../Primitives';
import styles from './styles.less';

const { Option, OptGroup } = Select;

export interface IOption {
  value: string;
  label: string;
  disabled?: boolean;
  group?: string;
  className?: string;
}

export type Mode = 'tags' | 'default' | 'multiple' | 'combobox';

export interface SelectProps extends SharedPropertyProps {
  options?: IOption[];
  values?: string[];
  labels?: string[];
  disabledValues?: string[];
  value?: string | string[];
  onChange: (value: string) => void;
  placeholder?: string;
  mode?: Mode;
  disabled?: boolean;
  onBlur?: () => void;
  onSearch?: (value: string) => void;
  id?: string;
}

const ThreekitSelect: FunctionComponent<SelectProps> = ({
  options = [],
  values = [],
  labels = [],
  disabledValues = [],
  label,
  value,
  onChange,
  placeholder,
  disabled,
  inline,
  onBlur,
  onSearch,
  mode,
  id,
}) => {
  const [filterBy, setFilterBy] = useState<string | null>(null);

  const optionValues = options.map(({ value }) => value);
  const valueOptions: IOption[] = values
    .filter(value => !optionValues.includes(value))
    .map((value, index) => ({ value, label: labels[index] || value }));

  const allOptions = [...options, ...valueOptions];

  const visibleValues = allOptions.map(({ value }) => value);

  const filteredOptions = allOptions.filter(({ label }) => {
    if (!filterBy) {
      return true;
    }
    return label.toLowerCase().includes(filterBy.toLowerCase());
  });

  const opts = filteredOptions.reduce<{ [x: string]: IOption[] }>(
    (acc, opt) => {
      const { group = 0 } = opt;
      acc[group] = acc[group] || [];
      acc[group].push({
        ...opt,
        disabled: disabledValues.includes(opt.value),
      });

      return acc;
    },
    {}
  );

  if (mode !== 'tags') {
    if (typeof value === 'string' && !visibleValues.includes(value)) {
      value = '';
    }

    if (Array.isArray(value)) {
      value = value.filter(value => visibleValues.includes(value));
    }
  }

  const mappableOpts = Object.entries(opts);

  return (
    <Labelled value={label} inline={inline}>
      <Select
        disabled={disabled}
        mode={mode}
        allowClear={true}
        showSearch={true}
        showArrow={true}
        placeholder={placeholder}
        value={value}
        className={cx(styles.select, { [styles.disabled]: disabled })}
        onChange={onChange}
        onSearch={value => {
          setFilterBy(value);
          onSearch && onSearch(value);
        }}
        onBlur={() => {
          setFilterBy(null);
          onBlur && onBlur();
        }}
        filterOption={false}
        data-id={id}
      >
        {mappableOpts.map(([group, options]) => {
          const children = options.map(
            ({ value, label, disabled, className }) => (
              <Option
                title={label}
                className={cx([styles.option], {
                  [styles.disabled]: disabled,
                })}
                key={value}
                value={value}
              >
                <span
                  className={className}
                  data-id={id && `${id}:${label}:${value}`}
                >
                  {label}
                </span>
              </Option>
            )
          );

          if (mappableOpts.length === 1) {
            return children;
          }

          return (
            <OptGroup key={group} label={group}>
              {children}
            </OptGroup>
          );
        })}
      </Select>
    </Labelled>
  );
};

export default ThreekitSelect;
